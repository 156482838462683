import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

// Easing functions for smooth animations
const easing = {
  easeOutCubic: t => 1 - Math.pow(1 - t, 3),
  easeOutQuint: t => 1 - Math.pow(1 - t, 5),
  easeOutQuart: t => 1 - Math.pow(1 - t, 4)
};

export class PanTool extends BaseTool {
  constructor(board) {
    super(board);
    this.lastPosX = 0;
    this.lastPosY = 0;
    this.isDragging = false;
    this.isTouching = false;
    
    // Mark this as the pan tool to ensure proper touch event handling
    this.isPanTool = true;
    
    // Inertia and momentum variables
    this.velocityX = 0;
    this.velocityY = 0;
    this.lastTime = 0;
    this.momentumAnimationId = null;
    this.pointerPositions = [];
    this.maxPositionRecords = 5; // Number of positions to track for momentum
    
    // Animation properties
    this.animationDuration = 1000; // Duration in ms (longer for more noticeable effect)
    this.minVelocityThreshold = 0.01; // Lower threshold to make inertia trigger more easily
    this.velocityMultiplier = 3.0; // Amplify the velocity for more dramatic effect
    
    // Bind event handlers once to preserve references for removal
    this._onMouseDown = this.onMouseDown.bind(this);
    this._onMouseMove = this.onMouseMove.bind(this);
    this._onMouseUp = this.onMouseUp.bind(this);
    this._onTouchStart = this.onTouchStart.bind(this);
    this._onTouchMove = this.onTouchMove.bind(this);
    this._onTouchEnd = this.onTouchEnd.bind(this);
    this._applyMomentum = this._applyMomentum.bind(this);
  }

  activate() {
    // Set cursor to indicate pan tool
    this.canvas.defaultCursor = 'grab';
    this.canvas.hoverCursor = 'grab';
    
    // Activate pan mode
    this.activatePanMode();
  }

  deactivate() {
    // Reset cursor
    this.canvas.defaultCursor = 'default';
    this.canvas.hoverCursor = 'default';
    
    // Reset state
    this.isDragging = false;
    this.isTouching = false;
    
    // Cancel any ongoing momentum animations
    this._cancelMomentumAnimation();
    
    // Explicitly remove all event listeners using the same references
    this.canvas.off('mouse:down', this._onMouseDown);
    this.canvas.off('mouse:move', this._onMouseMove);
    this.canvas.off('mouse:up', this._onMouseUp);
    this.canvas.off('touchstart', this._onTouchStart);
    this.canvas.off('touchmove', this._onTouchMove);
    this.canvas.off('touchend', this._onTouchEnd);
    
    // Call parent deactivate
    super.deactivate();
  }
  
  _cancelMomentumAnimation() {
    if (this.momentumAnimationId) {
      cancelAnimationFrame(this.momentumAnimationId);
      this.momentumAnimationId = null;
    }
    this.pointerPositions = [];
  }

  activatePanMode() {
    // Add pan mode event listeners for mouse
    this.canvas.on('mouse:down', this._onMouseDown);
    this.canvas.on('mouse:move', this._onMouseMove);
    this.canvas.on('mouse:up', this._onMouseUp);
    
    // Add touch-specific event listeners
    this.canvas.upperCanvasEl.addEventListener('touchstart', this._onTouchStart, { passive: false });
    this.canvas.upperCanvasEl.addEventListener('touchmove', this._onTouchMove, { passive: false });
    this.canvas.upperCanvasEl.addEventListener('touchend', this._onTouchEnd, { passive: true });
    this.canvas.upperCanvasEl.addEventListener('touchcancel', this._onTouchEnd, { passive: true });
  }

  onMouseDown(opt) {
    // First, check if we're actually the current active tool
    // This ensures we only handle mouse events when the PanTool is active
    if (this.board && this.board.currentTool !== this) {
      return;
    }
    
    const evt = opt.e;
    this.isDragging = true;
    this.canvas.defaultCursor = 'grabbing';
    this.canvas.hoverCursor = 'grabbing';
    
    // Cancel any ongoing momentum animation when user starts dragging
    this._cancelMomentumAnimation();
    
    // Reset velocity tracking
    this.pointerPositions = [];
    this.lastTime = Date.now();
    
    // Save current pointer position
    this.lastPosX = evt.clientX;
    this.lastPosY = evt.clientY;
    
    // Prevent default behavior to avoid selection when panning
    this.canvas.selection = false;
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll();
  }
  
  onTouchStart(evt) {
    // First, check if we're actually the current active tool
    // This ensures we only handle touch events when the PanTool is active
    if (this.board && this.board.currentTool !== this) {
      return;
    }
    
    // Direct DOM event, not a fabric event
    evt.preventDefault();
    
    // Set flags
    this.isTouching = true;
    
    // Cancel any ongoing momentum animation when user starts touching
    this._cancelMomentumAnimation();
    
    // Reset velocity tracking
    this.pointerPositions = [];
    this.lastTime = Date.now();
    
    if (!evt.touches || evt.touches.length === 0) {
      return;
    }
    
    const touch = evt.touches[0];
    
    // Store rounded coordinates to avoid fabric.js errors
    this.lastPosX = Math.round(touch.clientX);
    this.lastPosY = Math.round(touch.clientY);
    
    // Prevent default behavior
    this.canvas.selection = false;
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll();
  }

  // Helper function to handle the common panning logic
  _handlePan(currentX, currentY) {
    // Double-check that we're actually the current active tool
    if (this.board && this.board.currentTool !== this) {
      return;
    }

    // Ensure coordinates are valid numbers
    if (typeof currentX !== 'number' || typeof currentY !== 'number' || 
        isNaN(currentX) || isNaN(currentY) || 
        !isFinite(currentX) || !isFinite(currentY)) {
      return;
    }
    
    // Calculate delta from last position
    let deltaX = currentX - this.lastPosX;
    let deltaY = currentY - this.lastPosY;
    
    // Prevent extreme movements by capping delta values
    const maxDelta = 100; // Maximum pixels to move in a single pan
    if (Math.abs(deltaX) > maxDelta) {
      deltaX = Math.sign(deltaX) * maxDelta;
    }
    if (Math.abs(deltaY) > maxDelta) {
      deltaY = Math.sign(deltaY) * maxDelta;
    }
    
    // For touch events, apply a damping factor for smoother control
    if (this._isTouch) {
      // Apply a more conservative factor for touch movements
      deltaX = deltaX * 0.5;
      deltaY = deltaY * 0.5;
    }
    
    // Only track velocity if we're actually the PanTool and it's active
    if (this.board && this.board.currentTool === this) {
      // Track pointer positions for momentum
      const now = Date.now();
      const timeDelta = now - this.lastTime || 16; // Ensure we don't divide by zero
      
      // Always set this.lastTime even if we don't add a position
      // This prevents accumulating many positions within a single frame
      this.lastTime = now;
      
      // Ensure we don't track positions too frequently (can cause inaccurate velocity)
      if (timeDelta > 5) { // Minimum 5ms between position records
        // Calculate instantaneous velocity
        // Higher multiplier for more dramatic effect
        const velocityX = deltaX / timeDelta * 30; // Amplified for more noticeable inertia
        const velocityY = deltaY / timeDelta * 30;
        
        // Add to position history for momentum calculations
        this.pointerPositions.push({
          x: currentX,
          y: currentY,
          time: now,
          velocityX,
          velocityY
        });
        
        // Keep only the last N positions
        if (this.pointerPositions.length > this.maxPositionRecords) {
          this.pointerPositions.shift();
        }
        
        // Console log for debugging
        console.log('Tracking velocity:', { velocityX, velocityY, deltaX, deltaY, timeDelta });
      }
    }
    
    // Get current viewport transform
    const vpt = this.canvas.viewportTransform;
    
    // Apply translation to viewport with subtle easing for direct manipulation
    vpt[4] += deltaX;
    vpt[5] += deltaY;
    
    // Enforce limits to keep within boundaries (using board's axisLimit method)
    if (this.board && typeof this.board.axisLimit === 'function') {
      const scale = this.canvas.getZoom();
      vpt[4] = this.board.axisLimit({ scale, vpt: vpt[4], axis: 'x' });
      vpt[5] = this.board.axisLimit({ scale, vpt: vpt[5], axis: 'y' });
    }
    
    // Update last position to the actual current position
    this.lastPosX = currentX;
    this.lastPosY = currentY;
    
    // Render the changes
    this.canvas.requestRenderAll();
    
    // Fire the zoom:change event to update controls
    this.canvas.fire('zoom:change', {
      scale: this.canvas.getZoom()
    });
  }
  
  _calculateFinalVelocity() {
    // If no positions tracked, return zero velocity
    if (this.pointerPositions.length === 0) {
      return { velocityX: 0, velocityY: 0 };
    }
    
    // Calculate weighted average of recent velocities
    // More recent velocities have higher weights
    let totalWeight = 0;
    let weightedVelocityX = 0;
    let weightedVelocityY = 0;
    
    const positions = [...this.pointerPositions];
    
    for (let i = 0; i < positions.length; i++) {
      const weight = i + 1; // Weight increases with recency
      totalWeight += weight;
      weightedVelocityX += positions[i].velocityX * weight;
      weightedVelocityY += positions[i].velocityY * weight;
    }
    
    // Apply scaling factor to get a nice feel - amplified for more noticeable effect
    const velocityX = totalWeight > 0 ? (weightedVelocityX / totalWeight) * this.velocityMultiplier : 0;
    const velocityY = totalWeight > 0 ? (weightedVelocityY / totalWeight) * this.velocityMultiplier : 0;
    
    return { velocityX, velocityY };
  }
  
  _applyMomentum() {
    // Check if we're still the active tool; if not, stop momentum animation
    if (this.board && this.board.currentTool !== this) {
      this._cancelMomentumAnimation();
      return;
    }
    
    const now = Date.now();
    const startTime = this.momentumStartTime;
    const elapsed = now - startTime;
    
    // If animation is complete, stop
    if (elapsed >= this.animationDuration) {
      this.momentumAnimationId = null;
      return;
    }
    
    // Calculate progress using easing function (ease-out)
    const progress = elapsed / this.animationDuration;
    const easeValue = easing.easeOutQuint(progress);
    
    // Calculate the decreasing factor - modified for a more pronounced effect
    // This makes the momentum retain its velocity longer before slowing down
    const decreasingFactor = Math.pow(1 - easeValue, 0.8);
    
    // Calculate the delta for this frame
    // Multiply by a frame-time factor to ensure consistent speed
    const frameFactor = 1; // Can be adjusted if animation feels too fast/slow
    const deltaX = this.initialVelocityX * decreasingFactor * frameFactor;
    const deltaY = this.initialVelocityY * decreasingFactor * frameFactor;
    
    // Debug logging - remove in production
    console.log('Momentum:', { 
      progress, 
      decreasingFactor, 
      deltaX, 
      deltaY, 
      elapsed 
    });
    
    // Get current viewport transform
    const vpt = this.canvas.viewportTransform;
    
    // Apply movement with easing
    vpt[4] += deltaX;
    vpt[5] += deltaY;
    
    // Enforce limits
    if (this.board && typeof this.board.axisLimit === 'function') {
      const scale = this.canvas.getZoom();
      vpt[4] = this.board.axisLimit({ scale, vpt: vpt[4], axis: 'x' });
      vpt[5] = this.board.axisLimit({ scale, vpt: vpt[5], axis: 'y' });
    }
    
    // Render the changes
    this.canvas.requestRenderAll();
    
    // Fire the zoom:change event to update controls
    this.canvas.fire('zoom:change', {
      scale: this.canvas.getZoom()
    });
    
    // Continue the animation only if we're still the active tool
    if (this.board && this.board.currentTool === this) {
      this.momentumAnimationId = requestAnimationFrame(this._applyMomentum);
    } else {
      this._cancelMomentumAnimation();
    }
  }
  
  _startMomentumAnimation() {
    // Double-check that we're actually the current active tool
    if (this.board && this.board.currentTool !== this) {
      return;
    }
    
    // Cancel any existing momentum animation
    this._cancelMomentumAnimation();
    
    // Calculate the final velocity
    const { velocityX, velocityY } = this._calculateFinalVelocity();
    
    // Debug log the velocity calculation
    console.log('Final velocity calculation:', { velocityX, velocityY, positions: this.pointerPositions });
    
    // Only start momentum if velocity is above threshold
    const velocity = Math.sqrt(velocityX * velocityX + velocityY * velocityY);
    console.log('Velocity magnitude:', velocity, 'Threshold:', this.minVelocityThreshold);
    
    if (velocity < this.minVelocityThreshold) {
      console.log('Velocity below threshold, not starting momentum');
      return;
    }
    
    // Store initial values for the animation
    this.initialVelocityX = velocityX;
    this.initialVelocityY = velocityY;
    this.momentumStartTime = Date.now();
    
    console.log('Starting momentum animation with velocity:', { velocityX, velocityY });
    
    // Start the animation
    this.momentumAnimationId = requestAnimationFrame(this._applyMomentum);
  }
  
  onMouseMove(opt) {
    // First, check if we're actually the current active tool
    // This ensures we only handle mouse events when the PanTool is active
    if (this.board && this.board.currentTool !== this) {
      return;
    }
    
    if (this.isDragging) {
      const evt = opt.e;
      this._isTouch = false;
      
      // Extract coordinates and ensure they are integers
      const clientX = Math.round(evt.clientX);
      const clientY = Math.round(evt.clientY);
      
      // Only process the pan if the mouse has moved a reasonable distance
      const dx = Math.abs(clientX - this.lastPosX);
      const dy = Math.abs(clientY - this.lastPosY);
      
      // Only pan if movement is intentional (to avoid jitter)
      if (dx > 0 || dy > 0) {
        this._handlePan(clientX, clientY);
      }
    }
  }
  
  onTouchMove(evt) {
    // First, check if we're actually the current active tool
    // This ensures we only handle touch events when the PanTool is active
    if (this.board && this.board.currentTool !== this) {
      return;
    }
    
    if (this.isTouching) {
      // Prevent scrolling
      evt.preventDefault();
      
      // For touch events, get the first touch point
      if (!evt.touches || evt.touches.length === 0) {
        return;
      }
      
      const touch = evt.touches[0];
      this._isTouch = true;
      
      // Extract coordinates and ensure they are integers to avoid fabric.js errors
      const clientX = Math.round(touch.clientX);
      const clientY = Math.round(touch.clientY);
      
      // Only process the pan if the touch has moved a reasonable distance
      const dx = Math.abs(clientX - this.lastPosX);
      const dy = Math.abs(clientY - this.lastPosY);
      
      // Only pan if movement is intentional (to avoid jitter)
      if (dx > 1 || dy > 1) {
        this._handlePan(clientX, clientY);
      }
    }
  }

  onMouseUp() {
    if (this.isDragging) {
      this._startMomentumAnimation();
    }
    this.isDragging = false;
    this.canvas.defaultCursor = 'grab';
    this.canvas.hoverCursor = 'grab';
    this.canvas.selection = true;
  }
  
  onTouchEnd() {
    // First, check if we're actually the current active tool
    // This ensures we only handle touch events when the PanTool is active
    if (this.board && this.board.currentTool !== this) {
      return;
    }
    
    if (this.isTouching) {
      this._startMomentumAnimation();
    }
    this.isTouching = false;
    this.canvas.selection = true;
  }
}
