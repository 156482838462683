import { BaseTool } from './BaseTool';

export class SelectTool extends BaseTool {
  constructor(board) {
    super(board);
  }

  activate() {
    const canvas = this.canvas;

    canvas.selection = true;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'move';
    canvas.isDrawingMode = false;
    
    const objects = canvas.getObjects();
    objects.forEach(obj => {
      obj.set({ selectable: true });
      
      // Remove existing mousedown event listeners to avoid conflicts
      obj.off('mousedown');
    });
    
    canvas.requestRenderAll();
  }
}
