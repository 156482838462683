import React, { useState } from 'react';
import styled from 'styled-components';

const ControlsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 1000;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: ${props => props.primary ? '#4a90e2' : '#6c757d'};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    background-color: ${props => props.primary ? '#357abd' : '#5a6268'};
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const StatusMessage = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  padding: 8px 16px;
  background-color: ${props => props.error ? '#f8d7da' : '#d4edda'};
  color: ${props => props.error ? '#721c24' : '#155724'};
  border-radius: 4px;
  font-size: 14px;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1000;
`;

const SaveIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.94 1H2.06C1.48 1 1 1.48 1 2.06v11.88C1 14.52 1.48 15 2.06 15h11.88c.58 0 1.06-.48 1.06-1.06V2.06C15 1.48 14.52 1 13.94 1zM4.5 3h7c.28 0 .5.22.5.5s-.22.5-.5.5h-7c-.28 0-.5-.22-.5-.5s.22-.5.5-.5zm3.5 10c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"/>
  </svg>
);

const LoadIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 1.5A.5.5 0 0 1 9 2v5.5h2.5a.5.5 0 0 1 .354.854l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5A.5.5 0 0 1 4.5 7.5H7V2a.5.5 0 0 1 .5-.5zM2 14a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"/>
  </svg>
);

const SaveLoadControls = ({ boardRef, isAuthenticated, isModified = false }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({ message: '', error: false, visible: false });

  const showStatus = (message, isError = false) => {
    setStatus({ message, error: isError, visible: true });
    
    // Auto-hide after 3 seconds
    setTimeout(() => {
      setStatus(prev => ({ ...prev, visible: false }));
    }, 3000);
  };

  const handleSave = async () => {
    if (!boardRef?.current?.crdt) {
      showStatus('Board not initialized', true);
      return;
    }

    if (!isAuthenticated) {
      showStatus('Please log in to save', true);
      return;
    }

    setIsSaving(true);
    
    try {
      const success = await boardRef.current.crdt.saveWhiteboardData(false);
      
      if (success) {
        showStatus('Whiteboard saved successfully!');
      } else {
        showStatus('Failed to save whiteboard', true);
      }
    } catch (error) {
      console.error('Error saving whiteboard:', error);
      showStatus(`Error: ${error.message}`, true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleLoad = async () => {
    if (!boardRef?.current?.crdt) {
      showStatus('Board not initialized', true);
      return;
    }

    if (!isAuthenticated) {
      showStatus('Please log in to load', true);
      return;
    }

    setIsLoading(true);
    
    try {
      const success = await boardRef.current.crdt.loadWhiteboardData(true);
      
      if (success) {
        showStatus('Whiteboard loaded successfully!');
      } else {
        showStatus('No whiteboard data found or loading cancelled', true);
      }
    } catch (error) {
      console.error('Error loading whiteboard:', error);
      showStatus(`Error: ${error.message}`, true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ControlsContainer>
        <Button 
          primary 
          onClick={handleSave} 
          disabled={isSaving || !isAuthenticated}
          title={isAuthenticated ? "Save whiteboard" : "Please log in to save"}
        >
          <SaveIcon /> {isSaving ? 'Saving...' : 'Save'}
        </Button>
        
        <Button 
          onClick={handleLoad} 
          disabled={isLoading || !isAuthenticated}
          title={isAuthenticated ? "Load whiteboard" : "Please log in to load"}
        >
          <LoadIcon /> {isLoading ? 'Loading...' : 'Load'}
        </Button>
      </ControlsContainer>
      
      <StatusMessage 
        visible={status.visible} 
        error={status.error}
      >
        {status.message}
      </StatusMessage>
    </>
  );
};

export default SaveLoadControls; 