import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class RectangleTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('pencil');
    this.origX = 0;
    this.origY = 0;
  }

  activate() {
    const canvas = this.canvas;

    canvas.on('mouse:down', this.startAddRect.bind(this));
    canvas.on('mouse:move', this.startDrawingRect.bind(this));
    canvas.on('mouse:up', this.stopDrawing.bind(this));

    canvas.selection = false;
    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
  }

  startAddRect(e) {
    this.mouseDown = true;

    const pointer = this.canvas.getPointer(e.e);
    this.origX = pointer.x;
    this.origY = pointer.y;

    this.drawInstance = new fabric.Rect({
      stroke: this.drawingSettings.currentColor,
      strokeWidth: this.drawingSettings.brushWidth,
      fill: this.drawingSettings.fill ? this.drawingSettings.currentColor : 'transparent',
      left: this.origX,
      top: this.origY,
      width: 0,
      height: 0,
      selectable: false,
    });

    this.canvas.add(this.drawInstance);

    this.drawInstance.on(
      'mousedown',
      ((e) => {
        if (this.drawingSettings.currentMode === 'ERASER') {
          this.canvas.remove(e.target);
        }
      }).bind(this),
    );
  }

  startDrawingRect(e) {
    if (this.mouseDown) {
      const pointer = this.canvas.getPointer(e.e);

      if (pointer.x < this.origX) {
        this.drawInstance.set('left', pointer.x);
      }
      if (pointer.y < this.origY) {
        this.drawInstance.set('top', pointer.y);
      }
      this.drawInstance.set({
        width: Math.abs(pointer.x - this.origX),
        height: Math.abs(pointer.y - this.origY),
      });
      this.drawInstance.setCoords();
      this.canvas.renderAll();
    }
  }
  
  /**
   * Create a feet and inch box rectangle
   * @param {number} x - X position on canvas (top-left corner position)
   * @param {number} y - Y position on canvas (top-left corner position)
   * @param {number} width - Width of the box
   * @param {number} height - Height of the box
   * @returns {fabric.Rect} - Rectangle object
   */
  createFeetInchBox(x, y, width, height) {
    // Create the rectangle with light gray fill and black outline
    const rect = new fabric.Rect({
      left: x,
      top: y,
      width: width,
      height: height,
      fill: '#E0E0E0', // Light gray fill
      stroke: '#000000', // Black outline
      strokeWidth: 1,
      originX: 'left',
      originY: 'top',
      selectable: true,
      // Critical properties for Object Calculator recognition
      objectType: 'box',
      boxObject: true,
      metadata: {
        type: 'box',
        objectType: 'box',
        // Calculate dimensions in feet and inches (assuming 1px = 10cm at this point)
        dimensions: {
          cm: {
            width: width * 10,  // Convert back to cm (scaleFactor is 0.1)
            height: height * 10
          },
          feet: {
            width: Math.floor((width * 10) / 30.48), // 1 foot = 30.48 cm
            height: Math.floor((height * 10) / 30.48)
          },
          inches: {
            width: Math.round(((width * 10) % 30.48) / 2.54), // 1 inch = 2.54 cm
            height: Math.round(((height * 10) % 30.48) / 2.54)
          }
        }
      }
    });

    return rect;
  }
}
