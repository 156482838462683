import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class TextTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('text');
  }

  activate() {
    const canvas = this.canvas;

    canvas.on('mouse:down', this.addText.bind(this));

    canvas.selection = false;
    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
  }

  addText(e) {
    const canvas = this.canvas;
    const drawingSettings = this.drawingSettings;
    const pointer = canvas.getPointer(e.e);

    const text = new fabric.Textbox('', {
      fontFamily: 'Arial',
      left: pointer.x,
      top: pointer.y,
      fill: drawingSettings.currentColor,
      fontSize: drawingSettings.brushWidth * 10,
      selectable: true,
      padding: 7,
    });

    canvas.add(text);
    canvas.setActiveObject(text);
    text.enterEditing();
    text.hiddenTextarea.focus();

    text.on(
      'mousedown',
      ((e) => {
        if (drawingSettings.currentMode === 'ERASER') {
          canvas.remove(e.target);
        }
      }).bind(this),
    );

    this.board.history.snapshot();
  }
}
