import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class EllipseTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('pencil');
    this.origX = 0;
    this.origY = 0;
  }

  activate() {
    const canvas = this.canvas;

    canvas.on('mouse:down', this.startAddEllipse.bind(this));
    canvas.on('mouse:move', this.startDrawingEllipse.bind(this));
    canvas.on('mouse:up', this.stopDrawing.bind(this));

    canvas.selection = false;
    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
  }

  startAddEllipse(e) {
    this.mouseDown = true;

    const pointer = this.canvas.getPointer(e.e);
    this.origX = pointer.x;
    this.origY = pointer.y;

    this.drawInstance = new fabric.Ellipse({
      stroke: this.drawingSettings.currentColor,
      strokeWidth: this.drawingSettings.brushWidth,
      fill: this.drawingSettings.fill ? this.drawingSettings.currentColor : 'transparent',
      left: this.origX,
      top: this.origY,
      originX: 'left',
      originY: 'top',
      rx: 0,
      ry: 0,
      selectable: false,
    });

    this.canvas.add(this.drawInstance);

    this.drawInstance.on(
      'mousedown',
      ((e) => {
        if (this.drawingSettings.currentMode === 'ERASER') {
          this.canvas.remove(e.target);
        }
      }).bind(this),
    );
  }

  startDrawingEllipse(e) {
    if (this.mouseDown) {
      const pointer = this.canvas.getPointer(e.e);

      if (pointer.x < this.origX) {
        this.drawInstance.set('left', pointer.x);
      }
      if (pointer.y < this.origY) {
        this.drawInstance.set('top', pointer.y);
      }

      this.drawInstance.set({
        rx: Math.abs(pointer.x - this.origX) / 2,
        ry: Math.abs(pointer.y - this.origY) / 2,
      });

      this.drawInstance.setCoords();
      this.canvas.renderAll();
    }
  }
}
