import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaterialDialog from './MaterialDialog';

const PanelContainer = styled.div`
  position: absolute;
  bottom: 60px;
  right: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 12px;
  min-width: 240px;
  z-index: 1000;
  user-select: none;
  pointer-events: auto;
  &.context-panel-selector {
    /* No specific styles needed, just the class */
  }
`;

const FloatingCloseButton = styled.button`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #000000;
  color: white;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  transition: all 0.2s ease;
  z-index: 1001;

  &:hover {
    background-color: #ff3333;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
`;

const PanelHeader = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
`;

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 8px;
  color: #555;
`;

const Section = styled.div`
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
  
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 13px;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

const ActionButton = styled.button`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  flex: 1;
  
  &:hover {
    background-color: #e5e5e5;
  }
`;

const ColorInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ColorInput = styled.input`
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
  &::-webkit-color-swatch {
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`;

const ToggleButton = styled.button`
  background-color: ${props => props.active ? '#4CAF50' : '#f0f0f0'};
  color: ${props => props.active ? 'white' : 'inherit'};
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 11px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: ${props => props.active ? '#3d8b40' : '#e5e5e5'};
  }
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Slider = styled.input`
  flex-grow: 1;
`;

const SliderValue = styled.span`
  min-width: 24px;
  text-align: right;
  font-size: 12px;
`;

const MATERIAL_COLORS = {
  'Mulch': '#8B4513',
  'Pebbles': '#A9A9A9',
  'Concrete': '#C0C0C0',
  'Turf': '#228B22',
  'Pavers': '#D2691E'
};

const SelectInput = styled.select`
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 12px;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 140px;
  cursor: pointer;
  
  &:hover {
    background-color: #e5e5e5;
  }
`;

const ContextPanel = ({
  visible,
  selectedObject,
  onClose,
  onPropertyChange,
  onLayerAction,
  board
}) => {
  if (!visible || !selectedObject) return null;

  const [color, setColor] = useState(selectedObject.stroke?.substring(0, 7) || '#000000');
  const [strokeWidth, setStrokeWidth] = useState(selectedObject.strokeWidth || 1);
  const [opacity, setOpacity] = useState(selectedObject.opacity !== undefined ? Math.round(selectedObject.opacity * 100) : 100);
  const [material, setMaterial] = useState(selectedObject.material || 'Mulch');
  const [showMaterialDialog, setShowMaterialDialog] = useState(false);
  const [availableMaterials, setAvailableMaterials] = useState(board ? Object.keys(board.getMaterials()) : Object.keys(MATERIAL_COLORS));

  useEffect(() => {
    if (selectedObject) {
      // Ensure proper color format by removing alpha channel if present
      setColor(selectedObject.stroke?.substring(0, 7) || '#000000');
      setStrokeWidth(selectedObject.strokeWidth || 1);
      setOpacity(selectedObject.opacity !== undefined ? Math.round(selectedObject.opacity * 100) : 100);
      setMaterial(selectedObject.material || 'Mulch');
      if (board) {
        setAvailableMaterials(Object.keys(board.getMaterials()));
      }
    }
  }, [selectedObject, board]);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const objectType = selectedObject.type || 'object';
  const objectTool = selectedObject.tool || 
    (selectedObject.polyline ? 'polyline' : '') || 
    (objectType === 'line' && selectedObject.scaleData ? 'scale' : '') ||
    (objectType === 'line' && selectedObject.measurementData ? 'dimension' : '') || 
    objectType;

  const getToolDisplayName = (tool) => {
    const toolNames = {
      'polyline': 'Polyline',
      'scale': 'Scale',
      'dimension': 'Dimension',
      'selection': 'Selection',
      'line': 'Line',
      'path': 'Freehand',
      'rect': 'Rectangle',
      'circle': 'Circle',
      'textbox': 'Text',
      'i-text': 'Text',
      'text': 'Text'
    };
    return toolNames[tool] || (tool.charAt(0).toUpperCase() + tool.slice(1));
  };

  const displayName = getToolDisplayName(objectTool);
  const canEdit = selectedObject.canEdit || {};

  const handleColorChange = (e) => {
    const newColor = e.target.value.substring(0, 7); // Ensure 6-digit hex format
    setColor(newColor);
    
    // Special handling for plant stamps
    if (selectedObject.objectType === 'plant' || selectedObject.isPlant) {
      // For plant stamps, we need to update the color property too
      // This will be handled by the Board.handleObjectPropertyChange method
      onPropertyChange?.('color', newColor);
    } else {
      // For regular objects, just update the stroke
      onPropertyChange?.('stroke', newColor);
    }
  };

  const handleFillToggle = () => {
    // Special handling for plant stamps
    if (selectedObject.objectType === 'plant' || selectedObject.isPlant) {
      // For plant stamps, update the color property directly
      onPropertyChange?.('color', color);
    } else {
      // Normal fill toggle for other objects
      onPropertyChange?.('fill', selectedObject.fill ? null : color);
    }
  };

  const handleStrokeWidthChange = (e) => {
    const newWidth = parseInt(e.target.value, 10);
    setStrokeWidth(newWidth);
    onPropertyChange?.('strokeWidth', newWidth);
  };

  const handleOpacityChange = (e) => {
    // Get the raw value from the slider (between 5-100)
    const newOpacity = parseInt(e.target.value, 10);
    
    console.log('ContextPanel slider value:', newOpacity);
    
    // Update the UI to show the percentage
    setOpacity(newOpacity);
    
    // Pass the value in 0-1 range to the property change handler
    // This is what will be sent to the Board class
    onPropertyChange?.('opacity', newOpacity / 100);
  };

  const handleMaterialChange = (e) => {
    const newMaterial = e.target.value;
    if (newMaterial === 'add_custom') {
      setShowMaterialDialog(true);
      return;
    }
    
    // Update the material state
    setMaterial(newMaterial);
    
    // Get the material color
    const materialColor = MATERIAL_COLORS[newMaterial];
    
    // Notify parent of the material change with the color as additional data
    onPropertyChange?.('material', newMaterial, materialColor);
  };

  const handleAddMaterial = (name, color) => {
    if (board) {
      // Add the material to the board
      board.addMaterial(name, color);
      setAvailableMaterials(Object.keys(board.getMaterials()));
    }
    
    // Update local state
    setMaterial(name);
    
    // Notify parent of the material change with the color as additional data
    onPropertyChange?.('material', name, color);
    
    setShowMaterialDialog(false);
  };

  const formatMeasurement = (value, unit = 'ft') => {
    if (value === undefined || value === null) return 'N/A';
    
    if (unit.includes('²')) {
      return `${parseFloat(value.toFixed(2))} ${unit}`;
    }
    
    const feet = Math.floor(value);
    const inches = Math.round((value - feet) * 12);
    return inches === 12 ? `${feet + 1}'0"` : `${feet}'${inches}"`;
  };

  const getMeasurementData = () => {
    const measurements = {};
    const unit = selectedObject.unit || 'ft';

    if (objectTool === 'polyline') {
      if (selectedObject.area !== undefined) {
        measurements.area = formatMeasurement(selectedObject.area, `${unit}²`);
      }
      if (selectedObject.perimeter !== undefined) {
        measurements.perimeter = formatMeasurement(selectedObject.perimeter, unit);
      }
    } else if (objectTool === 'dimension' && selectedObject.measurementData) {
      measurements.distance = formatMeasurement(selectedObject.measurementData.distance, unit);
    } else if (objectTool === 'scale' && selectedObject.scaleData) {
      measurements.scale = formatMeasurement(selectedObject.scaleData.realDistance, unit);
      measurements.pixelDistance = `${Math.round(selectedObject.scaleData.pixelDistance)} px`;
    }

    return Object.keys(measurements).length > 0 ? measurements : null;
  };

  const renderMeasurementsSection = () => {
    const measurements = getMeasurementData();
    if (!measurements) return null;

    return (
      <Section>
        <SectionTitle>Measurements</SectionTitle>
        {Object.entries(measurements).map(([key, value]) => (
          <InfoRow key={key}>
            <span>{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
            <span>{value}</span>
          </InfoRow>
        ))}
      </Section>
    );
  };

  const renderStyleSection = () => {
    const shouldShowColor = canEdit.stroke !== false;
    const shouldShowFill = canEdit.fill !== false && objectTool !== 'dimension' && objectTool !== 'scale';
    const shouldShowWidth = canEdit.strokeWidth !== false;
    const shouldShowOpacity = canEdit.opacity !== false;

    // Special handling for plant objects
    const isPlantObject = selectedObject && (selectedObject.objectType === 'plant' || selectedObject.isPlant);

    if (!shouldShowColor && !shouldShowFill && !shouldShowWidth && !shouldShowOpacity) {
      return null;
    }

    return (
      <Section>
        <SectionTitle>Style</SectionTitle>
        {shouldShowColor && (
          <InfoRow>
            <span>{isPlantObject ? 'Plant Color:' : 'Color:'}</span>
            <ColorInputContainer>
              <ColorInput
                type="color"
                value={isPlantObject ? (selectedObject.color || selectedObject.fill || color) : color}
                onChange={handleColorChange}
              />
              {shouldShowFill && !isPlantObject && (
                <ToggleButton
                  active={!!selectedObject.fill}
                  onClick={handleFillToggle}
                >
                  {selectedObject.fill ? 'Fill' : 'Outline'}
                </ToggleButton>
              )}
            </ColorInputContainer>
          </InfoRow>
        )}
        {shouldShowWidth && (
          <InfoRow>
            <span>Width:</span>
            <SliderContainer>
              <Slider
                type="range"
                min="1"
                max="20"
                value={strokeWidth}
                onChange={handleStrokeWidthChange}
              />
              <SliderValue>{strokeWidth}px</SliderValue>
            </SliderContainer>
          </InfoRow>
        )}
        {shouldShowOpacity && (
          <InfoRow>
            <span>Opacity:</span>
            <SliderContainer>
              <Slider
                type="range"
                min="5"
                max="100"
                value={opacity || 100}
                onChange={handleOpacityChange}
              />
              <SliderValue>{opacity}%</SliderValue>
            </SliderContainer>
          </InfoRow>
        )}
      </Section>
    );
  };

  const renderPolylineSection = () => {
    if (objectTool !== 'polyline') return null;

    return (
      <Section>
        <SectionTitle>Polyline Properties</SectionTitle>
        <InfoRow>
          <span>Material:</span>
          <SelectInput value={material} onChange={handleMaterialChange}>
            {availableMaterials.map(name => (
              <option key={name} value={name}>{name}</option>
            ))}
            <option value="add_custom">+ Add material...</option>
          </SelectInput>
        </InfoRow>
      </Section>
    );
  };

  return (
    <>
      <PanelContainer className="context-panel-selector">
        <FloatingCloseButton onClick={handleClose}>×</FloatingCloseButton>
        <PanelHeader>{displayName} Properties</PanelHeader>
        
        <Section>
          <InfoRow>
            <span>Width:</span>
            <span>{Math.round(selectedObject.width || 0)} px</span>
          </InfoRow>
          <InfoRow>
            <span>Height:</span>
            <span>{Math.round(selectedObject.height || 0)} px</span>
          </InfoRow>
          <InfoRow>
            <span>Rotation:</span>
            <span>{Math.round(selectedObject.angle || 0)}°</span>
          </InfoRow>
        </Section>

        {renderStyleSection()}
        {renderMeasurementsSection()}
        {renderPolylineSection()}

        <Section>
          <SectionTitle>Layer Position</SectionTitle>
          <ButtonGroup>
            <ActionButton onClick={() => onLayerAction('bringToFront')}>
              Bring to Front
            </ActionButton>
            <ActionButton onClick={() => onLayerAction('sendToBack')}>
              Send to Back
            </ActionButton>
          </ButtonGroup>
          <ButtonGroup>
            <ActionButton onClick={() => onLayerAction('bringForward')}>
              Bring Forward
            </ActionButton>
            <ActionButton onClick={() => onLayerAction('sendBackward')}>
              Send Backward
            </ActionButton>
          </ButtonGroup>
        </Section>

        <Section>
          <ButtonGroup>
            <ActionButton
              onClick={() => onLayerAction('delete')}
              style={{ backgroundColor: '#ff4d4d', color: 'white' }}
            >
              Delete
            </ActionButton>
          </ButtonGroup>
        </Section>
      </PanelContainer>

      {showMaterialDialog && (
        <MaterialDialog
          open={showMaterialDialog}
          onClose={() => setShowMaterialDialog(false)}
          onAdd={handleAddMaterial}
        />
      )}
    </>
  );
};

ContextPanel.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedObject: PropTypes.object,
  onClose: PropTypes.func,
  onPropertyChange: PropTypes.func,
  onLayerAction: PropTypes.func.isRequired,
  board: PropTypes.object
};

export default ContextPanel;