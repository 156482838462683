import { firestore } from './config';
import { 
  collection, 
  query, 
  where, 
  orderBy, 
  getDocs,
  doc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  getDoc,
  writeBatch,
  updateDoc
} from 'firebase/firestore';
import { getCurrentUser, isAuthenticated } from './auth';

const WHITEBOARDS_COLLECTION = 'whiteboards';

/**
 * Get all whiteboards for the current user
 * @returns {Promise<Array>} Array of whiteboard objects
 */
export const getUserWhiteboards = async () => {
  if (!isAuthenticated()) {
    throw new Error('User must be authenticated to get whiteboards');
  }
  
  try {
    const user = getCurrentUser();
    
    // Query whiteboards created by the user
    const createdByQuery = query(
      collection(firestore, 'whiteboards'),
      where('createdBy', '==', user.uid),
      orderBy('createdAt', 'desc')
    );
    
    // Query whiteboards shared with the user
    const sharedWithQuery = query(
      collection(firestore, 'whiteboards'),
      where('collaborators', 'array-contains', user.uid),
      orderBy('createdAt', 'desc')
    );
    
    const [createdBySnapshot, sharedWithSnapshot] = await Promise.all([
      getDocs(createdByQuery),
      getDocs(sharedWithQuery)
    ]);
    
    // Combine results, making sure we don't have duplicates
    const whiteboards = [];
    const whiteboardIds = new Set();
    
    createdBySnapshot.forEach(doc => {
      const data = doc.data();
      whiteboardIds.add(doc.id);
      whiteboards.push({
        id: doc.id,
        ...data,
        isOwner: true
      });
    });
    
    sharedWithSnapshot.forEach(doc => {
      // Avoid duplicates
      if (!whiteboardIds.has(doc.id)) {
        const data = doc.data();
        whiteboards.push({
          id: doc.id,
          ...data,
          isOwner: false
        });
      }
    });
    
    return whiteboards;
  } catch (error) {
    console.error('Error getting user whiteboards:', error);
    throw new Error('Failed to get whiteboards: ' + error.message);
  }
};

/**
 * Delete a whiteboard
 * @param {string} whiteboardId - ID of the whiteboard to delete
 * @returns {Promise<void>}
 */
export const deleteWhiteboard = async (whiteboardId) => {
  if (!isAuthenticated()) {
    throw new Error('User must be authenticated to delete a whiteboard');
  }
  
  try {
    const user = getCurrentUser();
    
    // Get the whiteboard document to check permissions
    const whiteboardDoc = await getDoc(doc(firestore, 'whiteboards', whiteboardId));
    
    if (!whiteboardDoc.exists()) {
      throw new Error('Whiteboard not found');
    }
    
    const whiteboardData = whiteboardDoc.data();
    
    // Check if the user is the owner
    if (whiteboardData.createdBy !== user.uid) {
      throw new Error('You do not have permission to delete this whiteboard');
    }
    
    // Delete the whiteboard document
    await deleteDoc(doc(firestore, 'whiteboards', whiteboardId));
    
    // Delete operations subcollection
    const operationsQuery = query(
      collection(firestore, `whiteboards/${whiteboardId}/operations`)
    );
    
    const operationsSnapshot = await getDocs(operationsQuery);
    
    const batch = writeBatch(firestore);
    operationsSnapshot.forEach(doc => {
      batch.delete(doc.ref);
    });
    
    await batch.commit();
    
    return true;
  } catch (error) {
    console.error('Error deleting whiteboard:', error);
    throw new Error('Failed to delete whiteboard: ' + error.message);
  }
};

/**
 * Create a new whiteboard
 * @param {Object} whiteboardData - Initial whiteboard data
 * @returns {Promise<Object>} Created whiteboard data with ID
 */
export const createWhiteboard = async (whiteboardData = {}) => {
  if (!isAuthenticated()) {
    throw new Error('User must be authenticated to create whiteboard');
  }
  
  try {
    const user = getCurrentUser();
    
    const whiteboardRef = collection(firestore, 'whiteboards');
    
    const newWhiteboard = {
      name: whiteboardData.name || 'Untitled Whiteboard',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      createdBy: user.uid,
      collaborators: [], // Start with no collaborators
      settings: whiteboardData.settings || {}
    };
    
    const docRef = await addDoc(whiteboardRef, newWhiteboard);
    
    return {
      id: docRef.id,
      ...newWhiteboard,
      createdAt: new Date(),
      updatedAt: new Date()
    };
  } catch (error) {
    console.error('Error creating whiteboard:', error);
    throw new Error('Failed to create whiteboard: ' + error.message);
  }
};

/**
 * Get a whiteboard by ID
 * @param {string} whiteboardId - ID of the whiteboard to get
 * @returns {Promise<Object>} - The whiteboard data
 */
export const getWhiteboard = async (whiteboardId) => {
  if (!isAuthenticated()) {
    throw new Error('User must be authenticated');
  }
  
  try {
    const docRef = doc(firestore, 'whiteboards', whiteboardId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      throw new Error('Whiteboard not found');
    }
    
    return {
      id: docSnap.id,
      ...docSnap.data()
    };
  } catch (error) {
    console.error('Error getting whiteboard:', error);
    throw new Error('Failed to get whiteboard: ' + error.message);
  }
};

/**
 * Share a whiteboard with another user
 * @param {string} whiteboardId - ID of the whiteboard to share
 * @param {string} userId - ID of the user to share with
 * @returns {Promise<boolean>} - Success status
 */
export const shareWhiteboard = async (whiteboardId, userId) => {
  if (!isAuthenticated()) {
    throw new Error('User must be authenticated');
  }
  
  try {
    const user = getCurrentUser();
    
    // Get the whiteboard
    const whiteboardDoc = await getDoc(doc(firestore, 'whiteboards', whiteboardId));
    
    if (!whiteboardDoc.exists()) {
      throw new Error('Whiteboard not found');
    }
    
    const whiteboardData = whiteboardDoc.data();
    
    // Check if the current user is the owner
    if (whiteboardData.createdBy !== user.uid) {
      throw new Error('Only the creator can share this whiteboard');
    }
    
    // Check if user is already a collaborator
    if (whiteboardData.collaborators && whiteboardData.collaborators.includes(userId)) {
      console.log('User is already a collaborator');
      return true;
    }
    
    // Add the user to collaborators array
    const collaborators = whiteboardData.collaborators || [];
    collaborators.push(userId);
    
    // Update the whiteboard document
    await updateDoc(doc(firestore, 'whiteboards', whiteboardId), {
      collaborators,
      updatedAt: serverTimestamp()
    });
    
    return true;
  } catch (error) {
    console.error('Error sharing whiteboard:', error);
    throw new Error('Failed to share whiteboard: ' + error.message);
  }
}; 