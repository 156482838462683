import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Whiteboard from './lib/components/Whiteboard/Whiteboard';
import WhiteboardList from './lib/components/WhiteboardList/WhiteboardList';
import { createWhiteboard, getWhiteboard } from './lib/firebase/whiteboardManager';
import { getCurrentUser, isAuthenticated } from './lib/firebase/auth';
import ShareIcon from './lib/components/images/share.svg';

// Styled components
const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f5f5;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #4a90e2;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #357abd;
  }
`;

const WhiteboardContainer = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
`;

const ButtonS = styled.button`
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background-color: #357abd;
  }
`;

function App() {
  const [whiteboardId, setWhiteboardId] = useState(null);
  const [showWhiteboardList, setShowWhiteboardList] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return !urlParams.get('board');
  });
  
  // Check for whiteboard ID in URL on initial load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const boardId = urlParams.get('board');
    
    // Store only the ID string
    setWhiteboardId(boardId); 

    if (boardId) {
      setShowWhiteboardList(false);
    } else {
      setShowWhiteboardList(true);
    }
  }, []);
  
  // Update URL when whiteboard changes and control list visibility
  useEffect(() => {
    const url = new URL(window.location);
    if (whiteboardId) { // Now whiteboardId is guaranteed to be string or null
      url.searchParams.set('board', whiteboardId);
      window.history.pushState({}, '', url);
      setShowWhiteboardList(false);
    } else {
       url.searchParams.delete('board');
       window.history.pushState({}, '', url);
       // Decide if list should open when ID becomes null
       // setShowWhiteboardList(true);
    }
  }, [whiteboardId]);
  
  // Stabilize the callback passed to Whiteboard (even if commented out usage)
  const handleCanvasRender = useCallback((data) => {
    // Original logic was commented out, potentially causing loops
    // if (data?.whiteboardId && data.whiteboardId !== whiteboardId) {
    //   console.log('App onCanvasRender setting whiteboardId:', data.whiteboardId);
    //   // Be VERY careful setting state based on child callback - potential loop!
    //   // setWhiteboardId(data.whiteboardId); 
    // }
    // Add any other logic needed when canvas renders
  }, [whiteboardId]); // Dependency on whiteboardId if it uses it
  
  // Memoize the Whiteboard component instance based on its ID
  // This prevents recreation unless the ID actually changes.
  const memoizedWhiteboard = useMemo(() => {
      console.log(`App: Re-rendering Whiteboard component for ID: ${whiteboardId}`);
      return (
          <Whiteboard
            key="main-whiteboard" // Keep the static key as well
            whiteboardId={whiteboardId}
            controls={{
              TABS: true,
              ZOOM: true,
              FILES: true,
              COLOR_PICKER: true,
              DEFAULT_COLORS: true,
              BRUSH: true,
              FILL: true,
              CLEAR: true,
              SAVE_AS_IMAGE: true,
              GO_TO_START: true,
              SAVE_AND_LOAD: true,
              UNDO_REDO: true
            }}
            onCanvasRender={handleCanvasRender}
          />
      );
  }, [whiteboardId]); // Dependencies: ONLY whiteboardId (removed handleCanvasRender)
  
  // Function to handle whiteboard selection
  const handleWhiteboardSelect = async (selectedBoard) => {
    // Extract the ID from the selected board object
    const selectedId = selectedBoard?.id;
    if (!selectedId) {
        console.error("❌ Invalid board selected, missing ID:", selectedBoard);
        alert("Could not open the selected whiteboard.");
        return;
    }
    
    console.log('📝 App - Whiteboard selected ID:', selectedId);
    setShowWhiteboardList(false);
    
    try {
      // Optional: Confirm board exists - getWhiteboard might not be necessary here
      // if you trust the list source, but good for robustness.
      // await getWhiteboard(selectedId); 
      
      // Update URL first (using the ID string)
      const newUrl = new URL(window.location);
      newUrl.searchParams.set('board', selectedId);
      window.history.pushState({}, '', newUrl);
      
      // Set the whiteboard ID state with ONLY the ID string
      setWhiteboardId(selectedId);
      console.log('✅ App - Whiteboard ID state updated to:', selectedId);
      
      // Remove the custom event dispatch, rely on prop changes
      // window.dispatchEvent(new CustomEvent('whiteboardIdChanged', { 
      //   detail: { whiteboardId: selectedId } 
      // }));
    } catch (error) {
      console.error('❌ Error selecting whiteboard:', error);
      setWhiteboardId(null); // Reset ID on error
      alert('Error opening whiteboard: ' + error.message);
    }
  };
  
  // Handle creating a new whiteboard
  const handleCreateWhiteboard = async () => {
    try {
      // Get a name from the user
      const whiteboardName = prompt('Enter a name for your new whiteboard:', 
        `Whiteboard ${new Date().toLocaleString('en-US', { 
          month: 'short', 
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })}`
      );
      
      // If user cancels, abort creation
      if (!whiteboardName) {
        return;
      }
      
      // Create a new whiteboard document
      const newWhiteboard = await createWhiteboard({
        name: whiteboardName,
        settings: {}
      });
      
      console.log('🔨 Created new whiteboard:', newWhiteboard.id);
      
      // Select the new whiteboard using the same flow as normal selection
      await handleWhiteboardSelect(newWhiteboard);
    } catch (error) {
      console.error('Error creating whiteboard:', error);
      alert('Failed to create a new whiteboard: ' + error.message);
    }
  };
  
  return (
    <AppContainer>
      <Header>
        <Logo>Teempla Whiteboard</Logo>
        <ButtonContainer>
          <Button onClick={() => setShowWhiteboardList(true)}>
            Open Whiteboard
          </Button>
          <ButtonS
            onClick={async () => {
              // Get reference to the Whiteboard component/board
              const whiteboard = document.querySelector('canvas')?.fabricCanvas;
              
              if (!whiteboard) {
                alert('Whiteboard not initialized. Please try again.');
                return;
              }
              
              // Check if user is authenticated
              if (!isAuthenticated()) {
                const confirmLogin = window.confirm(
                  "You need to be signed in to enable real-time collaboration. Would you like to sign in now?"
                );
                
                if (confirmLogin) {
                  try {
                    // Redirect to login or show login dialog
                    // This depends on your auth implementation
                    window.location.href = '/login?redirect=' + encodeURIComponent(window.location.href);
                  } catch (e) {
                    console.error('Failed to redirect to login:', e);
                    alert('Please sign in to use the collaboration features.');
                  }
                  return;
                } else {
                  return; // User canceled login prompt
                }
              }
              
              try {
                // Get whiteboard ID from URL or create new one
                const urlParams = new URLSearchParams(window.location.search);
                let currentWhiteboardId = urlParams.get('board');
                
                if (!currentWhiteboardId) {
                  // Create a new whiteboard with a default name
                  const whiteboardName = `Whiteboard ${new Date().toLocaleString('en-US', { 
                    month: 'short', 
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}`;
                  
                  const newWhiteboard = await createWhiteboard({
                    name: whiteboardName,
                    settings: {}
                  });
                  
                  currentWhiteboardId = newWhiteboard.id;
                  
                  // Update URL with whiteboard ID
                  const url = new URL(window.location);
                  url.searchParams.set('board', currentWhiteboardId);
                  window.history.pushState({}, '', url);
                  
                  // Update the whiteboardId state
                  setWhiteboardId(currentWhiteboardId);
                } else {
                  // Get existing whiteboard data
                  try {
                    await getWhiteboard(currentWhiteboardId);
                  } catch (error) {
                    console.error('Error fetching whiteboard:', error);
                    alert('Could not find whiteboard. Creating a new one.');
                    
                    // Create a new whiteboard with a default name
                    const whiteboardName = `Whiteboard ${new Date().toLocaleString('en-US', { 
                      month: 'short', 
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })}`;
                    
                    const newWhiteboard = await createWhiteboard({
                      name: whiteboardName,
                      settings: {}
                    });
                    
                    currentWhiteboardId = newWhiteboard.id;
                    
                    // Update URL with new whiteboard ID
                    const url = new URL(window.location);
                    url.searchParams.set('board', currentWhiteboardId);
                    window.history.pushState({}, '', url);
                    
                    // Update the whiteboardId state
                    setWhiteboardId(currentWhiteboardId);
                  }
                }
                
                // At this point, dispatch a message to the whiteboard to reload
                // This tells the component to refresh with the new whiteboard ID
                window.dispatchEvent(new CustomEvent('whiteboardIdChanged', { 
                  detail: { whiteboardId: currentWhiteboardId } 
                }));
                
                // Show confirmation
                alert('Collaboration enabled! Share this URL to collaborate in real-time.');
                  
              } catch (error) {
                console.error('Error enabling collaboration:', error);
                alert('Error enabling collaboration: ' + error.message);
              }
            }}
            title="Enable Real-time Collaboration"
          >
            <img src={ShareIcon} alt="Share" />
          </ButtonS>
        </ButtonContainer>
      </Header>
      
      <WhiteboardContainer>
        {/* Render the memoized whiteboard element */}
        {memoizedWhiteboard}
      </WhiteboardContainer>
      
      {showWhiteboardList && (
        <WhiteboardList
          isOpen={showWhiteboardList}
          onClose={() => setShowWhiteboardList(false)}
          onSelect={handleWhiteboardSelect}
          onCreateNew={handleCreateWhiteboard}
        />
      )}
    </AppContainer>
  );
}

export default App;
