import { fabric } from 'fabric'
import { modes } from './toolFactory'

// Define stamp objects with their SVG paths and default settings
export const stampObjects = {
  'Small Tree': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z',
    fill: '#2E7D32',
    scale: 2
  },
  'Large Tree': {
    path: 'M12,2L4,12H6V19H18V12H20L12,2M12,4.5L16.5,10H15V17H9V10H7.5L12,4.5Z',
    fill: '#1B5E20',
    scale: 3
  },
  'Palm Tree': {
    path: 'M12,15L7,20H11V24H13V20H17L12,15M12,2A9,9 0 0,0 3,11H21A9,9 0 0,0 12,2M12,4A7,7 0 0,1 19,11H5A7,7 0 0,1 12,4M11,13V19H13V13H11Z',
    fill: '#33691E',
    scale: 2.5
  },
  'Bush': {
    path: 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
    fill: '#388E3C',
    scale: 1.5
  },
  'Shrub': {
    path: 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4Z',
    fill: '#558B2F',
    scale: 1.8
  },
  'Bench': {
    path: 'M4,2H20V8H4V2M6,4V6H18V4H6M4,10H20V16H4V10M6,12V14H18V12H6M4,18H20V24H4V18M6,20V22H18V20H6Z',
    fill: '#795548',
    scale: 2
  },
  'Table': {
    path: 'M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z',
    fill: '#8D6E63',
    scale: 2.5
  },
  'Chair': {
    path: 'M15,12V16H9V12H15M19,8H5V20H19V8M19,4H5A2,2 0 0,0 3,6V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V6A2,2 0 0,0 19,4M7,10H17V6H7V10Z',
    fill: '#8D6E63',
    scale: 1.8
  },
  'Umbrella': {
    path: 'M12,2A9,9 0 0,0 3,11H21A9,9 0 0,0 12,2M12,4A7,7 0 0,1 19,11H5A7,7 0 0,1 12,4M11,13V19H13V13H11Z',
    fill: '#F57C00',
    scale: 2.2
  },
  'Planter': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M12,6A6,6 0 0,1 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6Z',
    fill: '#A1887F',
    scale: 2
  },
  // Add threejs_scene.json plant types
  'Lomandra Grass': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z',
    fill: '#7CB342',
    scale: 2
  },
  'Emerald Carpet': {
    path: 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
    fill: '#33691E',
    scale: 1.6
  },
  'Mexican Daisy': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M12,6A6,6 0 0,1 18,12A6,6 0 0,1 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6Z',
    fill: '#FFEB3B',
    scale: 1.4
  },
  'Boxwood': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z',
    fill: '#4CAF50',
    scale: 2.2
  },
  'Red Canna': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z',
    fill: '#D32F2F',
    scale: 2.3
  },
  // Add the generic plant types used by SceneLoader
  'plant_1': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z',
    fill: '#4CAF50',
    scale: 2
  },
  'tree_1': {
    path: 'M12,2L4,12H6V19H18V12H20L12,2M12,4.5L16.5,10H15V17H9V10H7.5L12,4.5Z',
    fill: '#2E7D32',
    scale: 2.5
  },
  'shrub_1': {
    path: 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4Z',
    fill: '#558B2F',
    scale: 1.8
  },
  'grass_1': {
    path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z',
    fill: '#8BC34A',
    scale: 1.5
  }
}

export class StampTool {
  constructor(board) {
    this.board = board
    this.canvas = board.canvas
    this.currentStamp = null
    this.customStamp = null
    this.isActive = false
    this.mouseDown = false
    this.lastClickTime = 0
    this.lastStampTime = 0 // Track last stamp placement time
    this.doubleClickDelay = 300 // ms
    this.stampPositions = new Set() // Track recent positions to prevent duplicates
    this.placementDelay = 200 // Reasonable delay between placements (lowered from 500)
  }

  activate() {
    this.isActive = true
    this.canvas.defaultCursor = 'crosshair'
    this.canvas.selection = false
    this.attachEvents()
  }

  deactivate() {
    this.isActive = false
    this.detachEvents()
    this.currentStamp = null
  }

  /**
   * Set the current stamp
   * @param {string} stampName - The name of the stamp
   * @param {object} customStamp - Custom stamp data, for plants from scene loader
   */
  setCurrentStamp(stampName, customStamp = null) {
    console.log(`Setting current stamp to ${stampName}`, customStamp);
    
    if (customStamp) {
      // For custom stamps from SceneLoader
      this.currentStamp = stampName;
      this.customStamp = customStamp;
      
      // Register the custom stamp in our stamps collection if it's not already there
      if (!stampObjects[stampName]) {
        stampObjects[stampName] = {
          path: 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z',
          fill: '#4CAF50',
          scale: 1.0,
          isPlant: true,
          isCustom: customStamp.isCustom || false,
          isCustomStamp: true,
          name: stampName,
          customStampData: customStamp // Store the full custom stamp data
        };
        console.log(`Added ${stampName} to available stamps`);
      }
    } else if (stampObjects[stampName]) {
      this.currentStamp = stampName;
      this.customStamp = null;
    } else {
      console.warn(`Stamp ${stampName} not found in stamp objects`);
      // Still set the current stamp name to avoid null references
      this.currentStamp = stampName;
    }
  }
  
  getCurrentStamp() {
    return this.currentStamp
  }
  
  // Helper to get all available stamp objects
  getStampObjects() {
    return stampObjects
  }

  /**
   * Check if a stamp name is a plant
   * @param {string} stampName - Name of the stamp
   * @returns {boolean} True if the stamp is a plant
   */
  isPlantStamp(stampName) {
    if (!stampName) return false;
    
    const stampDef = stampObjects[stampName];
    return stampDef && (
      stampDef.isPlant === true || 
      stampName.toLowerCase().includes('plant') || 
      stampName.toLowerCase().includes('tree') ||
      stampName.toLowerCase().includes('shrub') ||
      stampName.toLowerCase().includes('grass')
    );
  }

  attachEvents() {
    this.canvas.on('mouse:down', this.onMouseDown)
    this.canvas.on('mouse:move', this.onMouseMove)
    this.canvas.on('mouse:up', this.onMouseUp)
  }

  detachEvents() {
    this.canvas.off('mouse:down', this.onMouseDown)
    this.canvas.off('mouse:move', this.onMouseMove)
    this.canvas.off('mouse:up', this.onMouseUp)
  }

  // Track when stamps are placed to prevent duplicates
  stampPlacedAt = { x: null, y: null, time: 0 }
  
  // Registry for recent stamp placements to properly prevent duplicates
  recentPlacements = []
  deduplicationTimeWindow = 2000 // ms - longer window to catch all duplicates
  deduplicationDistance = 5 // pixels - threshold for considering positions as duplicates
  
  onMouseDown = (opt) => {
    if (!this.isActive || !this.currentStamp) return
    
    const now = Date.now()
    // Only retain double-click prevention
    if (now - this.lastClickTime < this.doubleClickDelay) {
      // Double click detected, ignore
      console.log('Double click detected, ignoring')
      return
    }
    
    this.lastClickTime = now
    
    // Set mouseDown flag for drag operations
    this.mouseDown = true
    
    // Get current pointer position
    const pointer = this.canvas.getPointer(opt.e)
    
    // Important: Check for duplicates before placement
    if (this.isDuplicatePlacement(pointer)) {
      console.log('Duplicate detected in onMouseDown, skipping placement')
      return
    }
    
    // Place the stamp at the current position
    this.placeStamp(pointer)
    
    // Record where we just placed a stamp to prevent duplicates
    this.stampPlacedAt = {
      x: pointer.x,
      y: pointer.y,
      time: now
    }
  }

  onMouseMove = (opt) => {
    if (!this.isActive || !this.mouseDown || !this.currentStamp) return
    
    // Get the pointer position
    const pointer = this.canvas.getPointer(opt.e)
    
    // Calculate distance moved from last placement
    const dx = pointer.x - this.stampPlacedAt.x
    const dy = pointer.y - this.stampPlacedAt.y
    const distance = Math.sqrt(dx * dx + dy * dy)
    
    // Only place a new stamp if we've moved at least 20 pixels
    // AND it's been at least 100ms since the last placement
    const now = Date.now()
    const timeSinceLastPlacement = now - this.stampPlacedAt.time
    
    if (distance > 20 && timeSinceLastPlacement > 100) {
      this.placeStamp(pointer)
      
      // Update the last placement position and time
      this.stampPlacedAt = {
        x: pointer.x,
        y: pointer.y,
        time: now
      }
    }
  }

  onMouseUp = () => {
    this.mouseDown = false
  }

  // Older duplicate detection method, now replaced by isDuplicatePlacement
  // (Keeping the method name for compatibility with any existing calls, but it now calls the new method)
  isDuplicate(pointer) {
    return this.isDuplicatePlacement(pointer)
  }

  // Check if a placement would be a duplicate
  isDuplicatePlacement(pointer) {
    const now = Date.now()
    
    // Clean up old placements beyond our deduplication window
    this.recentPlacements = this.recentPlacements.filter(placement => {
      return now - placement.time < this.deduplicationTimeWindow
    })
    
    // Check if we have a recent placement at almost the same position
    for (const placement of this.recentPlacements) {
      const dx = pointer.x - placement.x
      const dy = pointer.y - placement.y
      const distance = Math.sqrt(dx * dx + dy * dy)
      
      if (distance < this.deduplicationDistance) {
        console.log(`Preventing duplicate stamp at (${Math.round(pointer.x)}, ${Math.round(pointer.y)})`, 
                    `near existing stamp at (${Math.round(placement.x)}, ${Math.round(placement.y)})`)
        return true
      }
    }
    
    return false
  }

  placeStamp(pointer) {
    if (!this.currentStamp) return;
    
    // Avoid double-placing stamps at the same position
    if (this.isDuplicatePlacement(pointer)) {
      console.log('Duplicate placement detected, ignoring');
      return;
    }
    
    console.log(`Placing stamp at position (${Math.round(pointer.x)}, ${Math.round(pointer.y)})`)
    
    // For debugging
    console.log('Placing stamp:', this.currentStamp)
    
    // Register this position to prevent duplicates
    this.recentPlacements.push({
      x: pointer.x,
      y: pointer.y,
      time: Date.now()
    });
    
    // Implement different stamp sizes based on user mode option
    const size = this.board.drawingSettings.stampSize || 'medium';
    const sizeMap = {
      small: { scale: 0.75, radius: 15, text: 10 },
      medium: { scale: 1.0, radius: 20, text: 12 },
      large: { scale: 1.5, radius: 30, text: 14 }
    };
    
    // Size-specific dimensions for the stamp
    const sizeValues = sizeMap[size] || sizeMap.medium
    
    // Check if this is a plant stamp - if so use specialized plant stamp creation
    if (this.isPlantStamp(this.currentStamp)) {
      const plantStamp = this.createPlantStamp(
        this.currentStamp,
        pointer.x,
        pointer.y,
        1.0  // Fixed scale
      );
      
      // Ensure all required properties for object calculator
      plantStamp.set({
        objectType: 'plant',
        stamp: true,
        isPlant: true,
        plantName: this.currentStamp,
        metadata: {
          type: 'plant',
          name: this.currentStamp
        }
      });
      
      // Add to canvas
      this.canvas.add(plantStamp);
      this.canvas.setActiveObject(plantStamp);
      this.canvas.renderAll();
      
      // Notify that a plant was added - helps with object calculator
      this.canvas.fire('plant:added', { plant: plantStamp });
      console.log('Added plant stamp:', this.currentStamp);
      
      // Take a snapshot for history
      if (this.board && this.board.history) {
        this.board.history.snapshot();
      }
      
      return;
    }
    
    // Handle both custom and regular stamps
    if (this.customStamp) {
      // Create a consistent, properly marked shape for counting
      let stampObject;
      
      if (this.customStamp.shape === 'circle') {
        stampObject = new fabric.Circle({
          radius: sizeValues.radius,
          fill: this.customStamp.fill || '#4CAF50',
          stroke: '#000000',
          strokeWidth: 1,
          left: pointer.x,
          top: pointer.y,
          originX: 'center',
          originY: 'center',
          selectable: true,
          evented: true,
          // CRITICAL: Make sure these properties match what the ObjectCalculator expects
          objectType: 'stamp',
          stamp: true,
          stampName: this.currentStamp,
          isCustom: true,
          customStamp: true,
          size: size, // Store the size category
          opacity: 0.5  // 50% transparent fill
        });
      } else {
        // For rectangles, use twice the radius as width/height
        const rectSize = sizeValues.radius * 2;
        stampObject = new fabric.Rect({
          width: rectSize,
          height: rectSize,
          fill: this.customStamp.fill || '#4CAF50',
          stroke: '#000000',
          strokeWidth: 1,
          left: pointer.x,
          top: pointer.y,
          originX: 'center',
          originY: 'center',
          selectable: true,
          evented: true,
          // CRITICAL: Make sure these properties match what the ObjectCalculator expects
          objectType: 'stamp',
          stamp: true,
          stampName: this.currentStamp,
          isCustom: true,
          customStamp: true,
          size: size, // Store the size category
          opacity: 0.5  // 50% transparent fill
        });
      }
      
      // Text for the stamp
      const textContent = this.customStamp.text || this.customStamp.symbol || 'ABC';
      const text = new fabric.Text(textContent, {
        left: 0, // Center of group
        top: 0, // Center of group
        fontSize: sizeValues.text, // Use size-appropriate font size
        fill: '#000000',   // Simple black text
        fontFamily: 'Arial, sans-serif', // Regular font
        fontWeight: 'normal',
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        objectType: 'text', // Not a stamp for counting purposes
        opacity: 1         // Keep text fully opaque for readability
      });
      
      // Reset position for grouping
      stampObject.set({
        left: 0,
        top: 0
      });
      
      // Create a group containing both the shape and text
      const group = new fabric.Group([stampObject, text], {
        left: pointer.x,
        top: pointer.y,
        originX: 'center',
        originY: 'center',
        selectable: true,
        evented: true,
        // Maintain the original stamp properties on the group for proper counting
        objectType: 'stamp',
        stamp: true,
        stampName: this.currentStamp,
        isCustom: true,
        customStamp: true,
        size: size
      });
      
      // Add the grouped object to the canvas
      this.canvas.add(group);
      console.log('Added grouped custom stamp:', group);
      
      // Force a full render
      this.canvas.renderAll();
    } else {
      // For regular stamps, use the standard path approach
      const stampObject = new fabric.Path(stampObjects[this.currentStamp].path, {
        left: pointer.x,
        top: pointer.y,
        fill: stampObjects[this.currentStamp].fill,
        stroke: 'rgba(0,0,0,0.2)',
        strokeWidth: 1,
        scaleX: stampObjects[this.currentStamp].scale,
        scaleY: stampObjects[this.currentStamp].scale,
        originX: 'center',
        originY: 'center',
        selectable: true,
        evented: true,
        // CRITICAL: Make sure these properties match what the ObjectCalculator expects
        stamp: true,
        objectType: 'stamp',
        stampName: this.currentStamp
      });
      
      // Add the stamp to canvas
      this.canvas.add(stampObject);
      console.log('Added regular stamp:', this.currentStamp);
    }
    
    // Take a snapshot for history
    if (this.board.history) {
      this.board.history.snapshot();
    }
  }
  
  // Helper method to get contrasting text color for better visibility
  getContrastingColor(hexColor) {
    // Default to white if no color provided
    if (!hexColor) return '#FFFFFF'
    
    // Convert hex to RGB
    let hex = hexColor.replace('#', '')
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    
    // Parse the color components
    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)
    
    // Calculate relative luminance using common formula
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
    
    // Return white for dark colors, black for light colors
    return luminance > 0.5 ? '#000000' : '#FFFFFF'
  }

  /**
   * Clean plant name - removes numbers, prefixes, and formats in title case
   * @param {string} rawName - The raw plant name to clean
   * @returns {string} - The cleaned plant name
   */
  cleanPlantName(rawName) {
    if (!rawName || typeof rawName !== 'string') {
      return 'Unknown Plant';
    }
    
    let name = rawName;
    
    // Common prefixes to remove
    const prefixesToRemove = ['plant', 'tree', 'bush', 'flower', 'shrub', 'specimen', 'dtree'];
    
    // Remove common prefixes if they are full words
    prefixesToRemove.forEach(prefix => {
      const regex = new RegExp(`\\b${prefix}\\b[\\s_+]*`, 'gi');
      name = name.replace(regex, '');
    });
    
    // Replace underscores and plus signs with spaces
    name = name.replace(/[_+]/g, ' ');
    
    // Remove numeric suffixes like " 0", " 1", etc.
    name = name.replace(/\s+\d+\s*$/g, '');
    
    // Remove "dtree" prefix commonly found in plant names
    name = name.replace(/^dtree\s+/i, '');
    
    // Replace multiple spaces with a single space
    name = name.replace(/\s+/g, ' ');
    
    // Trim leading and trailing spaces
    name = name.trim();
    
    // Convert to Title Case (capitalize first letter of each word)
    name = name.split(' ')
      .map(word => word.trim())
      .filter(word => word.length > 0)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    
    // If cleaned name is empty, use a default
    if (!name) {
      return 'Plant';
    }
    
    return name;
  }

  /**
   * Create a custom plant stamp based on plant name
   * @param {string} plantName - Name of the plant 
   * @param {number} x - X position on canvas
   * @param {number} y - Y position on canvas
   * @param {number} scaleParam - Scale factor (ignored, fixed scale used)
   * @returns {fabric.Object} - Created stamp object
   */
  createPlantStamp(plantName, x, y, scaleParam) {
    // Clean the plant name to standardize format
    const originalName = plantName;
    const cleanedName = this.cleanPlantName(originalName);
    
    // Ignore input scale - always use same fixed scale for uniformity
    const fixedScale = 1.0; // Always use the same fixed scale (1.0) for ALL plants
    
    // Create plant symbol - ALWAYS 3 characters
    let plantSymbol = '';
    
    if (cleanedName && typeof cleanedName === 'string') {
      // If there's a custom stamp with this name, check if it has custom data
      if (this.customStamp && this.customStamp.name === cleanedName) {
        // Use custom stamp's prefefined symbol if available
        if (this.customStamp.symbol) {
          // Use custom symbol but ensure it's 3 characters
          plantSymbol = this.customStamp.symbol.padEnd(3, ' ').substring(0, 3).toUpperCase();
        } else {
          // Generate 3-character symbol from name
          const words = cleanedName.split(' ');
          if (words.length >= 3) {
            // If three or more words, take first letter of first three words
            plantSymbol = words.slice(0, 3).map(word => word.charAt(0)).join('').toUpperCase();
          } else if (words.length === 2) {
            // For two words, take first letter of first word and first two letters of second word
            plantSymbol = (words[0].charAt(0) + words[1].substring(0, 2)).toUpperCase();
          } else {
            // For single word, take first three letters
            const letters = cleanedName.replace(/[^a-zA-Z]/g, '');
            plantSymbol = letters.substring(0, 3).padEnd(3, 'X').toUpperCase();
          }
        }
      } else {
        // Generate 3-character symbol
        const words = cleanedName.split(' ');
        if (words.length >= 3) {
          // If three or more words, take first letter of first three words
          plantSymbol = words.slice(0, 3).map(word => word.charAt(0)).join('').toUpperCase();
        } else if (words.length === 2) {
          // For two words, take first letter of first word and first two letters of second word
          plantSymbol = (words[0].charAt(0) + words[1].substring(0, 2)).toUpperCase();
        } else {
          // For single word, take first three letters
          const letters = cleanedName.replace(/[^a-zA-Z]/g, '');
          plantSymbol = letters.substring(0, 3).padEnd(3, 'X').toUpperCase();
        }
      }
    }
    
    // If we still don't have a symbol, use a fallback that's 3 characters
    if (!plantSymbol || plantSymbol.length !== 3) {
      plantSymbol = 'PLT';
    }
    
    console.log(`Creating plant stamp with symbol: ${plantSymbol} for ${cleanedName}`);
    
    // Get the color from customStamp if available, otherwise default to green
    const stampColor = (this.customStamp && (this.customStamp.color || this.customStamp.fill)) 
      ? (this.customStamp.color || this.customStamp.fill) 
      : '#4CAF50';
    
    console.log(`Using stamp color: ${stampColor} for plant ${cleanedName}`);
    
    // Create the plant stamp circle
    const circle = new fabric.Circle({
      radius: 20,
      fill: 'rgba(255, 255, 255, 0)', // Fully transparent background
      stroke: stampColor, // Use the custom color for outline
      strokeWidth: 2,
      left: x,
      top: y,
      originX: 'center',
      originY: 'center',
      objectType: 'plant-circle',
      strokeColor: stampColor, // Store color for future modifications
    });
    
    // Create text with the plant symbol
    const text = new fabric.Text(plantSymbol, {
      fontSize: 12,
      fill: stampColor, // Use the custom color for text
      fontFamily: 'Arial, sans-serif',
      fontWeight: 'bold',
      originX: 'center',
      originY: 'center',
      left: x,
      top: y,
      objectType: 'plant-text',
      textColor: stampColor, // Store color for future modifications
    });
    
    // Store the color for later use
    const colorProperty = {
      color: stampColor,
      fill: stampColor
    };
    
    // Group the circle and text
    const group = new fabric.Group([circle, text], {
      left: x,
      top: y,
      originX: 'center',
      originY: 'center',
      selectable: true,
      objectType: 'plant', // Required for object calculator
      stamp: true,         // Required for stamp recognition 
      isPlant: true,       // Additional identifier
      customStamp: true,   // Mark as custom stamp
      isCustomStamp: true, // Alternative property
      stampName: cleanedName, // Use cleaned name for the stampName property
      originalName: originalName, // Store original name for reference
      plantName: cleanedName,
      plantSymbol: plantSymbol, // Store the generated symbol
      color: stampColor,   // Store color properly
      fill: stampColor,    // Store fill as well for compatibility
      // Store references to children for property access
      stampCircle: circle,
      stampText: text,
      metadata: {
        type: 'plant',
        name: cleanedName,
        stampName: cleanedName,
        originalName: originalName,
        isCustomStamp: true,
        plantSymbol: plantSymbol,
        color: stampColor
      }
    });
    
    // Add custom event handlers to propagate color changes properly
    group.on('modified', () => {
      // If the group is modified, update the color of the circle and text
      if (group.color && group.color !== stampColor) {
        // Update the stroke color of the circle
        group._objects[0].set({
          stroke: group.color,
          strokeColor: group.color
        });
        
        // Update the fill color of the text
        group._objects[1].set({
          fill: group.color,
          textColor: group.color
        });
        
        // Update the stored color
        stampColor = group.color;
        group.fill = group.color;
        
        // Force canvas to render
        if (this.canvas) {
          this.canvas.renderAll();
        }
      }
    });
    
    // Set scale after creating the group
    group.set({
      scaleX: fixedScale,
      scaleY: fixedScale
    });
    
    console.log(`Created plant stamp: ${plantName} with symbol ${plantSymbol}`);
    return group;
  }
}
