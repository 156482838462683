import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class EraserTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorEraser = getCursor('eraser');
    this.isActive = false;
    this.objectAddedHandler = null;
  }

  // Add eraser behavior to a specific object
  addEraserBehavior(obj) {
    if (!obj) return;
    
    // Remove existing event listeners to avoid duplicates
    obj.off('mousedown');
    
    // Make the object selectable
    obj.set({ selectable: true });
    
    // Add mousedown event listener for erasure
    obj.on('mousedown', (e) => {
      if (e && e.target && this.drawingSettings.currentMode === 'ERASER') {
        console.log('Erasing object:', e.target.type);
        this.canvas.remove(e.target);
        this.canvas.requestRenderAll();
        this.board.history.snapshot();
      }
    });
  }

  // Handler for adding eraser behavior to new objects
  handleObjectAdded = (e) => {
    if (e && e.target && this.isActive) {
      console.log('New object detected - adding eraser behavior');
      this.addEraserBehavior(e.target);
    }
  };

  activate() {
    const canvas = this.canvas;
    this.isActive = true;

    canvas.selection = false;
    canvas.defaultCursor = this.cursorEraser;
    canvas.hoverCursor = this.cursorEraser;
    canvas.isDrawingMode = false;
    
    // Add eraser behavior to all existing objects
    const objects = canvas.getObjects();
    objects.forEach(obj => this.addEraserBehavior(obj));
    
    // Register object:added event to handle new objects
    canvas.off('object:added', this.handleObjectAdded);
    canvas.on('object:added', this.handleObjectAdded);
    
    // Also set up canvas click event to handle direct clicks
    canvas.off('mouse:down');
    canvas.on('mouse:down', (options) => {
      if (this.isActive && options.target) {
        console.log('Canvas mouse down on object, erasing:', options.target.type);
        canvas.remove(options.target);
        canvas.requestRenderAll();
        this.board.history.snapshot();
      }
    });
    
    canvas.discardActiveObject().requestRenderAll();
  }
  
  deactivate() {
    super.deactivate();
    this.isActive = false;
    
    // Remove our event handlers
    if (this.canvas) {
      this.canvas.off('object:added', this.handleObjectAdded);
      this.canvas.off('mouse:down');
    }
  }
}
