import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ControlsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
  max-width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 12px;
`;

const ControlHeading = styled.h3`
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #333;
`;

const ControlRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Label = styled.label`
  flex: 1;
  margin-right: 10px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #45a049;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SmallButton = styled.button`
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 3px 8px;
  margin: 0 2px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 11px;
  transition: background-color 0.3s;
  
  &:hover {
    background-color: #303f9f;
  }
`;

const StatsContainer = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
`;

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const DebugSection = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 8px;
`;

const Select = styled.select`
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #ccc;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const RotationDisplay = styled.div`
  font-size: 11px;
  margin-top: 2px;
  color: #666;
`;

const RangeInput = styled.input`
  width: 100%;
  margin-top: 4px;
`;

const ScaleValue = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  margin-top: 2px;
  color: #666;
`;

const SceneControls = ({ board }) => {
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [realTimeUpdates, setRealTimeUpdates] = useState(false);
  const [stats, setStats] = useState({
    plants: 0,
    boxes: 0,
    total: 0
  });
  
  // Debug state for transformation controls - initialized with optimal settings
  const [debugConfig, setDebugConfig] = useState({
    rotationAxis: 'z',
    invertX: false,
    invertY: false,
    invertZ: false,
    centerX: true,
    centerY: true,
    additionalRotationX: 0,
    additionalRotationY: 0,
    additionalRotationZ: -90,
    scaleMapping: {
      width: 'x',
      height: 'y',
      depth: 'y'
    },
    scaleMultiplierX: 0.5,
    scaleMultiplierY: 0.5,
    scaleMultiplierZ: 0.5,
    viewType: 'unreal'
  });
  
  // Add state for global plant scale
  const [globalPlantScale, setGlobalPlantScale] = useState(1.0);
  
  // This path assumes the threejs_scene.json is in the public/test-json directory
  const defaultScenePath = '/test-json/threejs_scene.json';
  
  // Initialize WebSocket URL - should be configured based on your server
  const wsUrl = 'ws://localhost:8080/scene-updates';
  
  useEffect(() => {
    // Initial state check
    if (board) {
      setSceneLoaded(board.sceneLoaded);
      
      if (board.sceneLoaded && board.sceneLoader) {
        updateStats();
        
        // Get initial debug config
        if (board.sceneLoader.getConfig) {
          setDebugConfig(board.sceneLoader.getConfig());
        }
      }
      
      // Check if real-time updates are already enabled
      if (board.isRealTimeEnabled && board.isRealTimeEnabled()) {
        setRealTimeUpdates(true);
      }
    }
    
    // Set up event listeners for scene changes
    if (board && board.canvas) {
      const updateStatsHandler = () => {
        if (board.sceneLoaded) {
          updateStats();
        }
      };
      
      board.canvas.on('plants:rendered', updateStatsHandler);
      board.canvas.on('boxes:rendered', updateStatsHandler);
      
      return () => {
        board.canvas.off('plants:rendered', updateStatsHandler);
        board.canvas.off('boxes:rendered', updateStatsHandler);
      };
    }
  }, [board]);
  
  const updateStats = () => {
    if (!board || !board.sceneLoader) return;
    
    const plants = board.sceneLoader.plants ? board.sceneLoader.plants.length : 0;
    const boxes = board.sceneLoader.boxes ? board.sceneLoader.boxes.length : 0;
    
    setStats({
      plants,
      boxes,
      total: plants + boxes
    });
  };
  
  const handleLoadScene = async () => {
    if (!board) return;
    
    try {
      await board.loadSceneFromUrl(defaultScenePath);
      setSceneLoaded(true);
      updateStats();
    } catch (error) {
      console.error('Error loading scene:', error);
      alert(`Failed to load scene: ${error.message}`);
    }
  };
  
  const handleRealTimeUpdates = async () => {
    if (!board) return;
    
    const newState = !realTimeUpdates;
    
    try {
      if (newState) {
        await board.enableRealTimeUpdates(wsUrl);
      } else {
        await board.disableRealTimeUpdates();
      }
      
      setRealTimeUpdates(newState);
    } catch (error) {
      console.error('Error toggling real-time updates:', error);
      alert(`Failed to toggle real-time updates: ${error.message}`);
    }
  };
  
  const handleDebugConfigChange = (key, value) => {
    const newConfig = { ...debugConfig };
    
    // Handle nested properties like scaleMapping
    if (key.includes('.')) {
      const [parent, child] = key.split('.');
      newConfig[parent] = { ...newConfig[parent], [child]: value };
    } else {
      newConfig[key] = value;
    }
    
    setDebugConfig(newConfig);
    
    // Update the SceneLoader config
    if (board && board.sceneLoader && board.sceneLoader.updateConfig) {
      board.sceneLoader.updateConfig(newConfig);
      
      // Re-render the scene to apply changes
      if (board.sceneLoader.renderPlants && board.sceneLoader.renderBoxes) {
        board.sceneLoader.renderPlants();
        board.sceneLoader.renderBoxes();
      }
    }
  };
  
  const handleRotateBoxes = (axis, degrees) => {
    if (!board || !board.sceneLoader || !board.sceneLoader.rotateBoxes) return;
    
    try {
      // Call the rotateBoxes method on SceneLoader
      board.sceneLoader.rotateBoxes(axis, degrees);
      
      // Update local state to reflect the changes
      const axisKey = `additionalRotation${axis.toUpperCase()}`;
      setDebugConfig(prev => ({
        ...prev,
        [axisKey]: (prev[axisKey] || 0) + degrees
      }));
    } catch (error) {
      console.error(`Error rotating boxes around ${axis} axis:`, error);
    }
  };
  
  return (
    <ControlsContainer>
      <ControlHeading>Scene Controls</ControlHeading>
      
      <ControlRow>
        <Button 
          onClick={handleLoadScene} 
          disabled={sceneLoaded && !board.sceneLoader}
        >
          {sceneLoaded ? 'Reload Scene' : 'Load Scene'}
        </Button>
      </ControlRow>
      
      <ControlRow>
        <Button 
          onClick={handleRealTimeUpdates} 
          disabled={!sceneLoaded || !board.sceneLoader}
        >
          {realTimeUpdates ? 'Disable Real-time' : 'Enable Real-time'}
        </Button>
      </ControlRow>
      
      <StatsContainer>
        <StatRow>
          <strong>Scene Stats:</strong>
        </StatRow>
        <StatRow>
          <span>Plants:</span>
          <span>{stats.plants}</span>
        </StatRow>
        <StatRow>
          <span>Boxes:</span>
          <span>{stats.boxes}</span>
        </StatRow>
        <StatRow>
          <span>Total Objects:</span>
          <span>{stats.total}</span>
        </StatRow>
        
        {/* Add Global Plant Scale Control */}
        <StatRow style={{ marginTop: '10px', paddingTop: '10px', borderTop: '1px solid #eee' }}>
          <strong>Plant Controls:</strong>
        </StatRow>
        <ControlRow>
          <Label>Global Plant Scale: {globalPlantScale.toFixed(1)}</Label>
          <input
            type="range"
            min="0.1"
            max="3.0"
            step="0.1"
            value={globalPlantScale}
            onChange={(e) => {
              const newScale = parseFloat(e.target.value);
              setGlobalPlantScale(newScale);
              if (board && board.sceneLoader && board.sceneLoader.updateGlobalPlantScale) {
                board.sceneLoader.updateGlobalPlantScale(newScale);
              }
            }}
            style={{ width: '100%' }}
          />
        </ControlRow>
        <ButtonGroup>
          <SmallButton
            onClick={() => {
              const newScale = Math.max(0.1, globalPlantScale - 0.1);
              setGlobalPlantScale(newScale);
              if (board && board.sceneLoader && board.sceneLoader.updateGlobalPlantScale) {
                board.sceneLoader.updateGlobalPlantScale(newScale);
              }
            }}
          >
            Smaller
          </SmallButton>
          <SmallButton
            onClick={() => {
              setGlobalPlantScale(1.0);
              if (board && board.sceneLoader && board.sceneLoader.updateGlobalPlantScale) {
                board.sceneLoader.updateGlobalPlantScale(1.0);
              }
            }}
          >
            Reset
          </SmallButton>
          <SmallButton
            onClick={() => {
              const newScale = Math.min(3.0, globalPlantScale + 0.1);
              setGlobalPlantScale(newScale);
              if (board && board.sceneLoader && board.sceneLoader.updateGlobalPlantScale) {
                board.sceneLoader.updateGlobalPlantScale(newScale);
              }
            }}
          >
            Larger
          </SmallButton>
        </ButtonGroup>
      </StatsContainer>
      
      <DebugSection>
        <StatRow>
          <strong>Debug Controls:</strong>
        </StatRow>
        
        <ControlRow>
          <Label>View Type:</Label>
          <Select 
            value={debugConfig.viewType || 'topDown'}
            onChange={(e) => {
              let newConfig = {...debugConfig};
              newConfig.viewType = e.target.value;
              
              // Apply preset transformations based on view type
              if (e.target.value === 'isometric') {
                newConfig.scaleMapping = { width: 'x', height: 'z', depth: 'y' };
                newConfig.invertX = false;
                newConfig.invertY = false;
                newConfig.invertZ = false;
                newConfig.centerX = true;
                newConfig.centerY = true;
                newConfig.rotationAxis = 'z';
                newConfig.additionalRotationZ = -45;
              } else if (e.target.value === 'topDown') {
                newConfig.scaleMapping = { width: 'x', height: 'z', depth: 'y' };
                newConfig.invertX = false;
                newConfig.invertY = false;
                newConfig.invertZ = false;
                newConfig.centerX = true;
                newConfig.centerY = true;
                newConfig.rotationAxis = 'y';
                newConfig.additionalRotationY = 0;
              } else if (e.target.value === 'frontView') {
                newConfig.scaleMapping = { width: 'x', height: 'y', depth: 'z' };
                newConfig.invertX = false;
                newConfig.invertY = true;
                newConfig.invertZ = false;
                newConfig.centerX = true;
                newConfig.centerY = true;
                newConfig.rotationAxis = 'z';
                newConfig.additionalRotationZ = 0;
              } else if (e.target.value === 'sideView') {
                newConfig.scaleMapping = { width: 'z', height: 'y', depth: 'x' };
                newConfig.invertX = false;
                newConfig.invertY = true;
                newConfig.invertZ = false;
                newConfig.centerX = true;
                newConfig.centerY = true;
                newConfig.rotationAxis = 'x';
                newConfig.additionalRotationX = 0;
              } else if (e.target.value === 'unreal') {
                // Special preset to match Unreal Engine view (based on testing)
                newConfig.scaleMapping = { width: 'x', height: 'y', depth: 'y' };
                newConfig.invertX = false;
                newConfig.invertY = false;
                newConfig.invertZ = false;
                newConfig.centerX = true;
                newConfig.centerY = true;
                newConfig.rotationAxis = 'y';
                newConfig.additionalRotationY = 0;
                newConfig.scaleMultiplierX = 0.5;
                newConfig.scaleMultiplierY = 0.5;
                newConfig.scaleMultiplierZ = 0.5;
              } else if (e.target.value === 'custom') {
                // No changes for custom view
              }
              
              setDebugConfig(newConfig);
              handleDebugConfigChange('viewType', e.target.value);
            }}
          >
            <option value="topDown">Top Down (2D)</option>
            <option value="isometric">Isometric</option>
            <option value="frontView">Front View</option>
            <option value="sideView">Side View</option>
            <option value="unreal">Unreal Match</option>
            <option value="custom">Custom</option>
          </Select>
        </ControlRow>
        
        <ControlRow>
          <Label htmlFor="rotationAxis">Rotation Axis:</Label>
          <Select 
            id="rotationAxis"
            value={debugConfig.rotationAxis}
            onChange={(e) => handleDebugConfigChange('rotationAxis', e.target.value)}
          >
            <option value="x">X (Pitch)</option>
            <option value="y">Y (Yaw)</option>
            <option value="z">Z (Roll)</option>
          </Select>
        </ControlRow>
        
        <ControlRow>
          <Label>
            <Checkbox 
              type="checkbox"
              checked={debugConfig.invertX}
              onChange={(e) => handleDebugConfigChange('invertX', e.target.checked)}
            />
            Invert X
          </Label>
        </ControlRow>
        
        <ControlRow>
          <Label>
            <Checkbox 
              type="checkbox"
              checked={debugConfig.invertY}
              onChange={(e) => handleDebugConfigChange('invertY', e.target.checked)}
            />
            Invert Y
          </Label>
        </ControlRow>
        
        <ControlRow>
          <Label>
            <Checkbox 
              type="checkbox"
              checked={debugConfig.invertZ}
              onChange={(e) => handleDebugConfigChange('invertZ', e.target.checked)}
            />
            Invert Z
          </Label>
        </ControlRow>
        
        <ControlRow>
          <Label>
            <Checkbox 
              type="checkbox"
              checked={debugConfig.centerX}
              onChange={(e) => handleDebugConfigChange('centerX', e.target.checked)}
            />
            Center X
          </Label>
        </ControlRow>
        
        <ControlRow>
          <Label>
            <Checkbox 
              type="checkbox"
              checked={debugConfig.centerY}
              onChange={(e) => handleDebugConfigChange('centerY', e.target.checked)}
            />
            Center Y
          </Label>
        </ControlRow>
        
        <StatRow>
          <strong>Additional Box Rotation:</strong>
        </StatRow>
        
        <ControlRow>
          <Label>X Axis:</Label>
          <ButtonGroup>
            <SmallButton onClick={() => handleRotateBoxes('x', -90)}>-90°</SmallButton>
            <SmallButton onClick={() => handleRotateBoxes('x', 90)}>+90°</SmallButton>
          </ButtonGroup>
        </ControlRow>
        <RotationDisplay>Current: {debugConfig.additionalRotationX || 0}°</RotationDisplay>
        
        <ControlRow>
          <Label>Y Axis:</Label>
          <ButtonGroup>
            <SmallButton onClick={() => handleRotateBoxes('y', -90)}>-90°</SmallButton>
            <SmallButton onClick={() => handleRotateBoxes('y', 90)}>+90°</SmallButton>
          </ButtonGroup>
        </ControlRow>
        <RotationDisplay>Current: {debugConfig.additionalRotationY || 0}°</RotationDisplay>
        
        <ControlRow>
          <Label>Z Axis:</Label>
          <ButtonGroup>
            <SmallButton onClick={() => handleRotateBoxes('z', -90)}>-90°</SmallButton>
            <SmallButton onClick={() => handleRotateBoxes('z', 90)}>+90°</SmallButton>
          </ButtonGroup>
        </ControlRow>
        <RotationDisplay>Current: {debugConfig.additionalRotationZ || 0}°</RotationDisplay>
        
        <StatRow>
          <strong>Scale Axis Mapping:</strong>
        </StatRow>
        
        <ControlRow>
          <Label htmlFor="widthAxis">Width Axis:</Label>
          <Select 
            id="widthAxis"
            value={debugConfig.scaleMapping.width}
            onChange={(e) => handleDebugConfigChange('scaleMapping.width', e.target.value)}
          >
            <option value="x">X</option>
            <option value="y">Y</option>
            <option value="z">Z</option>
          </Select>
        </ControlRow>
        
        <ControlRow>
          <Label htmlFor="heightAxis">Height Axis:</Label>
          <Select 
            id="heightAxis"
            value={debugConfig.scaleMapping.height}
            onChange={(e) => handleDebugConfigChange('scaleMapping.height', e.target.value)}
          >
            <option value="x">X</option>
            <option value="y">Y</option>
            <option value="z">Z</option>
          </Select>
        </ControlRow>
        
        <ControlRow>
          <Label htmlFor="depthAxis">Depth Axis:</Label>
          <Select 
            id="depthAxis"
            value={debugConfig.scaleMapping.depth}
            onChange={(e) => handleDebugConfigChange('scaleMapping.depth', e.target.value)}
          >
            <option value="x">X</option>
            <option value="y">Y</option>
            <option value="z">Z</option>
          </Select>
        </ControlRow>
        
        <StatRow>
          <strong>Scale Multipliers:</strong>
        </StatRow>
        
        <ControlRow>
          <Label>X Scale: {debugConfig.scaleMultiplierX.toFixed(1)}</Label>
        </ControlRow>
        <RangeInput 
          type="range" 
          min="0.1" 
          max="2" 
          step="0.1"
          value={debugConfig.scaleMultiplierX}
          onChange={(e) => handleDebugConfigChange('scaleMultiplierX', parseFloat(e.target.value))}
        />
        <ScaleValue>
          <span>0.1</span>
          <span>2.0</span>
        </ScaleValue>
        
        <ControlRow>
          <Label>Y Scale: {debugConfig.scaleMultiplierY.toFixed(1)}</Label>
        </ControlRow>
        <RangeInput 
          type="range" 
          min="0.1" 
          max="2" 
          step="0.1"
          value={debugConfig.scaleMultiplierY}
          onChange={(e) => handleDebugConfigChange('scaleMultiplierY', parseFloat(e.target.value))}
        />
        <ScaleValue>
          <span>0.1</span>
          <span>2.0</span>
        </ScaleValue>
        
        <ControlRow>
          <Label>Z Scale: {debugConfig.scaleMultiplierZ.toFixed(1)}</Label>
        </ControlRow>
        <RangeInput 
          type="range" 
          min="0.1" 
          max="2" 
          step="0.1"
          value={debugConfig.scaleMultiplierZ}
          onChange={(e) => handleDebugConfigChange('scaleMultiplierZ', parseFloat(e.target.value))}
        />
        <ScaleValue>
          <span>0.1</span>
          <span>2.0</span>
        </ScaleValue>
      </DebugSection>
    </ControlsContainer>
  );
};

export default SceneControls;
