import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import { getCursor } from './cursors';
import History from './History';
import { ToolFactory, modes } from './tools/toolFactory';
import { SceneLoader } from './SceneLoader';
import { v4 as uuidv4 } from 'uuid';
import { CRDTIntegration } from './CRDTIntegration.js';
import { getCurrentUser } from '../../firebase/auth';

// Default materials - make sure this matches the definition in ContextPanel or is centralized
const DEFAULT_MATERIAL_COLORS = {
  'Mulch': '#8B4513',
  'Pebbles': '#A9A9A9',
  'Concrete': '#C0C0C0',
  'Turf': '#228B22',
  'Pavers': '#D2691E'
};

export class Board {
  constructor({
    canvasRef,
    drawingSettings,
    canvasConfig,
  }) {
    this.canvasRef = canvasRef;
    this.canvasConfig = canvasConfig || {};
    this.drawingSettings = drawingSettings || {};
    
    // Initialize materials with defaults
    this.materials = { ...DEFAULT_MATERIAL_COLORS };
    
    // Initialize board
    this._init(this.canvasRef);
    
    // Initialize CRDT integration (will be activated when whiteboardId is set)
    this.crdt = null;
    this.whiteboardId = null;
    
    // Callback for context menu
    this.contextMenuCallback = null;
    this.eventCallback = null; // Callback for UI events
    this.fileReaderInfo = 'Teempla'; // Default file info
    this.currentWhiteboardId = null; // The actual ID from Firestore

    this.nowY = 0;
    this.canvasRef = void 0;
    this.limitScale = 10;
    this.sketchWidthLimit = 1920 * this.limitScale;
    this.sketchHeightLimit = 1080 * this.limitScale;
    // [Sketch range limits]
    
    const windowWidth = this.limitScale * (window.screen.width || window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth);
    const windowHeight = this.limitScale * (window.screen.height || window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
    this.sketchWidthLimit = windowWidth > this.sketchWidthLimit ? windowWidth : this.sketchWidthLimit;

    this.setEventCallback = this.setEventCallback.bind(this);
    this.eventCallback = () => {}; // Default empty callback
  }

  _init(canvasRef) {
    if (!canvasRef || !canvasRef.current) {
      console.error('Canvas reference is invalid');
      return;
    }

    // Get the canvas element directly from the ref
    const canvasElement = canvasRef.current;
    
    // Get the parent element for sizing
    const parentElement = canvasElement.parentElement;
    if (!parentElement) {
      console.error('Canvas parent element not found');
      return;
    }
    
    // Setup custom canvas methods
    fabric.Canvas.prototype.getItemByAttr = function (attr, name) {
      var object = null,
        objects = this.getObjects();
      for (var i = 0, len = this.size(); i < len; i++) {
        if (objects[i][attr] && objects[i][attr] === name) {
          object = objects[i];
          break;
        }
      }
      return object;
    };
    
    const options = {
      preserveObjectStacking: true,
      selection: true,
      defaultCursor: getCursor('pencil'),
      backgroundColor: 'white',
      perPixelTargetFind: true,
      isDrawingMode: false,
      enableRetinaScaling: true,
      stopContextMenu: true,
      fireRightClick: true,
      fireMiddleClick: true,
      targetFindTolerance: 4,
      selectionBorderColor: 'rgba(0,0,0,0.3)',
      selectionLineWidth: 1
    };
    
    // Create the canvas
    const canvas = new fabric.Canvas(canvasElement, {
      ...options,
      maxZoom: this.canvasConfig.maxZoom || 7,
      minZoom: this.canvasConfig.minZoom || 0.1,
      viewportTransform: this.canvasConfig.viewportTransform || [1, 0, 0, 1, 0, 0],
      ...(this.canvasConfig.settings || {}),
    });
    
    // Set up the canvas dimensions
    canvas.setWidth(parentElement.clientWidth);
    canvas.setHeight(parentElement.clientHeight);
    
    // Set up resize handler
    const resizeHandler = this.resizeCanvas(canvas, parentElement).bind(this);
    this.element = this.handleResize(resizeHandler);
    if (typeof ResizeObserver !== 'undefined' && this.element && parentElement) {
      this.element.observe(parentElement);
    }

    this.canvas = canvas;
    this.history = new History(canvas);
    this.toolFactory = new ToolFactory(this);
    
    // Setup canvas events
    this.setupCanvasEvents();
    
    // Apply settings
    this.setCanvasConfig(this.canvasConfig);
    this.setDrawingSettings(this.drawingSettings);

    this.canvas.once('after:render', () => {
      this.applyCanvasConfig(this.canvasConfig);
      this.addContextMenuListener();
    });

    return canvas;
  }
  
  // Add a stub for this method if it's not defined elsewhere
  setupCanvasEvents() {
    if (!this.canvas) return;

    // Basic mouse events (down, move, up) are usually handled by tools
    // Panning (Middle mouse or Space + Left mouse)
    let isPanning = false;
    let lastPosX = 0;
    let lastPosY = 0;
    
    this.canvas.on('mouse:down', (opt) => {
      const evt = opt.e;
      // Middle mouse button for panning
      if (evt.button === 1 || (evt.button === 0 && evt.altKey)) { // Alt + Left Click OR Middle button
        isPanning = true;
        this.canvas.defaultCursor = 'grab';
        this.canvas.setCursor('grab');
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
        this.canvas.renderAll();
      } else if (evt.button === 2) { // Right Click
        // Fire context menu callback if defined
        if (this.contextMenuCallback) {
          this.contextMenuCallback(opt);
        }
        evt.preventDefault(); // Prevent default browser context menu
        return false;
      }
    });

    this.canvas.on('mouse:move', (opt) => {
      if (isPanning) {
        const e = opt.e;
        const vpt = this.canvas.viewportTransform;
        vpt[4] += e.clientX - lastPosX;
        vpt[5] += e.clientY - lastPosY;
        this.canvas.requestRenderAll();
        lastPosX = e.clientX;
        lastPosY = e.clientY;
        this.canvas.setCursor('grabbing');
      }
    });

    this.canvas.on('mouse:up', () => {
      if (isPanning) {
        isPanning = false;
        this.canvas.defaultCursor = getCursor(this.drawingSettings.currentMode || 'pencil'); // Restore default cursor
        this.canvas.setCursor(this.canvas.defaultCursor);
        this.canvas.renderAll();
      }
    });

    // Zooming
    this.canvas.on('mouse:wheel', (opt) => {
      const delta = opt.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > (this.canvasConfig.maxZoom || 7)) zoom = (this.canvasConfig.maxZoom || 7);
      if (zoom < (this.canvasConfig.minZoom || 0.1)) zoom = (this.canvasConfig.minZoom || 0.1);
      
      // Calculate point to zoom towards
      const pointer = this.canvas.getPointer(opt.e);
      this.canvas.zoomToPoint(pointer, zoom);
      
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    
    // Update history on object modification
    this.canvas.on('object:modified', () => {
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
        console.warn('[Board] this.history.snapshot is not a function. History not saved on object:modified.');
      }
      if (this.crdt) {
        console.log('🔄 Object modified, triggering CRDT update for whiteboard:', this.whiteboardId);
        this.crdt._handleCanvasUpdate();
      } else {
        console.warn('⚠️ Cannot save modifications - CRDT integration not initialized!');
      }
    });
    
    // Handle object addition (e.g., from drawing tools)
    this.canvas.on('object:added', (e) => {
      // Ignore history save if it's a temporary object like a selection box
      if (e.target && e.target.excludeFromExport) {
          return;
      }
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
         console.warn('[Board] this.history.snapshot is not a function. History not saved on object:added.');
      }
       // Trigger CRDT update if available
      if (this.crdt) {
        console.log('➕ Object added, triggering CRDT update for whiteboard:', this.whiteboardId);
        this.crdt._handleCanvasUpdate(e);
      } else {
        console.warn('⚠️ Cannot save new object - CRDT integration not initialized!');
      }
    });
    
    console.log('Setting up canvas events - COMPLETE');
  }
  
  // Required method for resize handling
  handleResize(callback) {
    if (typeof ResizeObserver !== 'undefined') {
      return new ResizeObserver(callback);
    }
    return null;
  }
  
  // Required method for canvas resizing
  resizeCanvas(canvas, whiteboard) {
    return function () {
      try {
        if (!canvas || !canvas.getElement()) {
          console.warn('Canvas element not initialized yet, skipping resize');
          return;
        }
        
        const width = whiteboard.clientWidth;
        const height = whiteboard.clientHeight;
        
        // Apply zoom safely with fallback
        try {
          if (typeof this.changeZoom === 'function') {
            this.changeZoom({ scale: 1 });
          } else {
            console.warn('changeZoom method not available during resize, continuing with direct dimension setting');
          }
        } catch (zoomError) {
          console.error('Error applying zoom during resize:', zoomError);
          // Continue with dimension setting even if zoom fails
        }
        
        // Set dimensions directly
        canvas.setDimensions({
          width: width,
          height: height
        });
        
        console.log(`Canvas resized to ${width}x${height}`);
      } catch (error) {
        console.error('Error resizing canvas:', error);
      }
    };
  }
  
  /**
   * Set the canvas configuration
   */
  setCanvasConfig(canvasConfig) {
    this.canvasConfig = canvasConfig || {};
    // Apply canvas config if canvas exists
    if (this.canvas) {
      // Apply any configuration to the canvas instance
      console.log('Canvas config applied');
    }
  }
  
  /**
   * Set the drawing settings
   */
  setDrawingSettings(drawingSettings) {
    this.drawingSettings = drawingSettings || {};
    // Apply drawing settings if canvas exists
    if (this.canvas) {
      // Apply mode, color, etc.
      if (this.drawingSettings.currentMode) {
        this.setMode(this.drawingSettings.currentMode);
      }
      
      // Apply other settings as needed
      console.log('Drawing settings applied');
    }
  }
  
  /**
   * Set the current drawing mode
   */
  setMode(mode) {
    if (this.toolFactory) {
      this.toolFactory.activateTool(mode);
    }
  }

  /**
   * Set the whiteboard ID and initialize CRDT
   * @param {string} whiteboardId - The ID of the whiteboard
   */
  async setWhiteboardId(whiteboardId) {
    console.log(`🔍 setWhiteboardId called with: ${whiteboardId}`);
    
    // Don't do anything if the ID is the same
    if (this.whiteboardId === whiteboardId) {
      console.log(`⏭️ Whiteboard ID unchanged (${whiteboardId}), skipping update.`);
      return;
    }
    
    // Clean up existing CRDT integration if we had one
    if (this.crdt) {
      console.log(`🧹 Cleaning up existing CRDT integration for ${this.whiteboardId}`);
      try {
        this.crdt.dispose(); // Changed from cleanup to dispose
        this.crdt = null;
      } catch (error) {
        console.error('Error cleaning up CRDT:', error);
      }
    }

    // Clear canvas and history only if ID becomes null
    if (!whiteboardId) {
        console.log('🗑️ Whiteboard ID is null. Clearing canvas and resetting state.');
        this.clearCanvas(); 
        this.history?.clear(); 
        this.whiteboardId = null;
        this.eventCallback('whiteboard_cleared'); // Notify UI
        return;
    }

    // Set the new ID
    this.whiteboardId = whiteboardId;
    console.log('✅ Whiteboard ID updated to:', this.whiteboardId);
    
    // Initialize CRDT for the new ID
    console.log('🔌 Initializing CRDT integration for new whiteboard:', this.whiteboardId);
    await this._initializeCRDT(this.whiteboardId);
    
    // Apply specific configurations or load data if needed after setting ID
    this.applyCanvasConfig(this.canvasConfig); 
    this.addContextMenuListener();
  }

  _initializeCRDT = async (whiteboardId) => {
    if (!whiteboardId) {
        console.warn('Cannot initialize CRDT without a whiteboard ID.');
        return false; // Explicitly return false, though not used for event anymore
    }
    if (this.crdt && this.crdt.isInitialized()) {
        console.log('CRDT already initialized for', whiteboardId);
        return true; // Explicitly return true, though not used for event anymore
    }

    try {
        console.log('🚀 Creating new CRDT integration instance...');
        // FIX: Correctly instantiate CRDTIntegration - assuming constructor takes an object
        this.crdt = new CRDTIntegration({ 
            whiteboardId: whiteboardId,
            fabricCanvas: this.canvas,
            history: this.history,
            eventCallback: this.eventCallback, 
            getCurrentUser: () => getCurrentUser(), // Need to import or pass getCurrentUser
            onRemoteUpdate: () => { 
              this.updateUndoRedoCallback?.();
            }
        });
        console.log('🚀 CRDT integration instance created, initializing...');
        // Initialize now directly calls the eventCallback on success/fail
        await this.crdt.initialize(); 
        // Return value is no longer the primary way to check success for UI events
        // return success;
        return this.crdt.isInitialized(); // Return actual status if needed elsewhere

    } catch (error) {
        console.error('❌ Error during CRDT initialization process:', error);
        this.crdt = null;
        // Let initialize() handle emitting the failure event via callback
        return false;
    }
  }

  // Make cleanupCRDT public if needed by Whiteboard.jsx, otherwise keep private
  cleanupCRDT() {
    console.log(`🧹 Cleaning up CRDT for whiteboard: ${this.whiteboardId}`);
    if (this.crdt) {
      try {
        this.crdt.dispose(); // Changed from cleanup to dispose
        this.crdt = null;
      } catch (error) {
        console.error('Error cleaning up CRDT:', error);
      }
    }
  }
  
  removeBoard() {
    console.log('[Board] Removing board instance...');
    this.cleanupCRDT(); // This will now call dispose correctly
    if (this.canvas) {
      this.canvas.dispose();
      this.canvas = null;
    }
    // Clean up other resources if necessary
  }

  /**
   * Set the file reader info
   * @param {Object} fileReaderInfo - Information about the file being read
   */
  setFileReaderInfo(fileReaderInfo) {
    this.fileReaderInfo = fileReaderInfo;
    console.log('File reader info updated:', fileReaderInfo?.file?.name);
    
    // If we have a PDF page, we might need to render it
    if (fileReaderInfo?.currentPage) {
      this.openPage(fileReaderInfo.currentPage);
    }
  }
  
  /**
   * Open a PDF page
   * @param {string} page - The page data (base64 or URL)
   */
  openPage(page) {
    if (!this.canvas || !page) return;
    
    // Clear canvas first
    this.clearCanvas();
    
    // If the page is a data URL or base64 string, load it as a background image
    if (typeof page === 'string' && page.length > 0) {
      fabric.Image.fromURL(page, (img) => {
        // Center the image and scale it to fit
        const canvasWidth = this.canvas.width;
        const canvasHeight = this.canvas.height;
        
        // Scale to fit within canvas while maintaining aspect ratio
        const scale = Math.min(
          canvasWidth / img.width,
          canvasHeight / img.height
        ) * 0.9; // 90% of the canvas size
        
        img.scale(scale);
        
        // Center the image
        img.set({
          left: canvasWidth / 2,
          top: canvasHeight / 2,
          originX: 'center',
          originY: 'center',
          selectable: false,
          evented: false,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockUniScaling: true,
          hoverCursor: 'default'
        });
        
        // Add the image to the canvas as a background
        this.canvas.add(img);
        this.canvas.sendToBack(img);
        this.canvas.renderAll();
      });
    }
  }
  
  /**
   * Clear the canvas
   */
  clearCanvas() {
    if (!this.canvas) return;
    
    // Store the current viewport transform
    const vpt = [...this.canvas.viewportTransform];
    
    // Clear all objects
    this.canvas.clear();
    
    // Restore the viewport transform
    this.canvas.setViewportTransform(vpt);
    
    // Set white background
    this.canvas.backgroundColor = 'white';
    this.canvas.renderAll();
  }

  /**
   * Set the context menu callback function
   * @param {Function} callback - Function to call on context menu event
   */
  setContextMenuCallback(callback) {
    this.contextMenuCallback = callback;
  }

  /**
   * Callback for when an object is added to the canvas
   */
  onObjectAdded(event) {
    if (this.history && typeof this.history.snapshot === 'function') {
      this.history.snapshot(); // Use snapshot()
    } else {
      console.warn('this.history.snapshot is not a function. Local history might not be saved correctly.');
    }
    if (this.crdt) {
      console.log('➕ Object added, triggering CRDT update for whiteboard:', this.whiteboardId);
      this.crdt._handleCanvasUpdate(event);
    }
    // Update context menu if needed?
  }

  /**
   * Callback for when an object is modified on the canvas
   */
  onObjectModified(event) {
    if (this.history && typeof this.history.snapshot === 'function') {
      this.history.snapshot(); // Use snapshot()
    } else {
      console.warn('this.history.snapshot is not a function. Local history might not be saved correctly.');
    }
    if (this.crdt) {
      console.log('🔄 Object modified, triggering CRDT update for whiteboard:', this.whiteboardId);
      this.crdt._handleCanvasUpdate(event);
    }
    // Update context menu if needed?
  }

  /**
   * Callback for when an object is removed from the canvas
   */
  onObjectRemoved(event) {
    if (this.history && typeof this.history.snapshot === 'function') {
      this.history.snapshot(); // Use snapshot()
    } else {
      console.warn('this.history.snapshot is not a function. Local history might not be saved correctly.');
    }
    if (this.crdt) {
      console.log('➖ Object removed, triggering CRDT update for whiteboard:', this.whiteboardId);
      this.crdt._handleCanvasUpdate(event);
    }
    // Update context menu if needed?
  }

  // Method to add an image to the canvas from a URL
  addImageFromUrl(url) {
    if (!this.canvas) {
      console.error("Canvas not initialized. Cannot add image.");
      return;
    }
    console.log(`Board: Adding image from URL: ${url}`);

    fabric.Image.fromURL(url, (img) => {
      if (!img) {
        console.error("Failed to load image from URL:", url);
        alert("Failed to load image.");
        return;
      }
      console.log("Image loaded from URL successfully.");

      // --- Basic Scaling Logic --- 
      // Scale the image to fit within, say, half the canvas width/height
      const canvasWidth = this.canvas.getWidth();
      const canvasHeight = this.canvas.getHeight();
      const maxDim = Math.min(canvasWidth, canvasHeight) * 0.5; // Max dimension 50% of smaller canvas dim

      img.scaleToWidth(maxDim);
      // If scaling to width made it taller than maxDim, scale to height instead
      if (img.getScaledHeight() > maxDim) {
        img.scaleToHeight(maxDim);
      }
      // --- End Scaling Logic --- 

      // --- Positioning Logic --- 
      // Center the image in the current viewport
      // Get center coordinates relative to the viewport
      const center = this.canvas.getVpCenter();
      img.set({
        left: center.x - img.getScaledWidth() / 2,
        top: center.y - img.getScaledHeight() / 2,
        originX: 'left',
        originY: 'top',
        // Add custom properties if needed, e.g., for page tracking
        pageNumber: this.fileReaderInfo?.currentPageNumber || 1,
        documentName: this.fileReaderInfo?.file?.name || 'default', 
        id: uuidv4(), // Assign a unique ID
      });
      // --- End Positioning Logic ---
      
      console.log(`Adding image to canvas at (${img.left}, ${img.top}) with scale (${img.scaleX}, ${img.scaleY})`);
      this.canvas.add(img);
      this.canvas.setActiveObject(img); // Make the new image active
      this.canvas.requestRenderAll();

      // History snapshot should be triggered by the 'object:added' event listener 
      // already set up in Whiteboard.jsx, which calls this.history.snapshot()
      console.log('Image added to canvas. History snapshot should be triggered by event.');

    }, { crossOrigin: 'anonymous' }); // Important for loading from external domains
  }

  addContextMenuListener() {
    if (!this.canvas) return;
    console.log('[Board] Adding context menu listener (for click outside only)...'); // Log listener addition

    // Remove the 'mouse:down' listener for right-click trigger
    // this.canvas.off('mouse:down'); // Ensure any previous listener is off if re-running

    // --- Keep only the click-outside logic --- 
    // Hide context menu if user clicks outside the canvas area
    document.addEventListener('click', (e) => {
        // console.log('[Board] Document click detected.'); // Log document clicks (can be noisy)
        if (this.canvas && this.contextMenuCallback && this.canvas.wrapperEl && !this.canvas.wrapperEl.contains(e.target)) {
           // Check if click is outside the whiteboard area AND the panel itself
           const contextPanelElement = document.querySelector('.context-panel-selector'); 
           if (!contextPanelElement || (contextPanelElement && !contextPanelElement.contains(e.target))) {
                console.log('[Board] Click outside canvas and context panel. Hiding panel via callback...'); // Log hide condition
                this.contextMenuCallback({ visible: false, x: 0, y: 0, selectedObject: null });
           }
        }
    }, true); // Use capture phase to catch clicks early
    console.log('[Board] Context menu listener (click outside only) added successfully.'); // Log success
  }
  
  getSelectedObjectProperties(target) {
      // If no target is provided, get the currently active object/selection
      if (!target) {
          const activeSelection = this.canvas?.getActiveObject();
          if (!activeSelection) {
              console.log('[Board] getSelectedObjectProperties: No target provided and no active selection.');
              return null;
          }
          target = activeSelection;
          console.log(`[Board] getSelectedObjectProperties: Using active selection of type ${target.type}`);
      }

      // Define properties to extract, including custom ones
      const properties = {
          type: target.type,
          stroke: target.stroke,
          strokeWidth: target.strokeWidth,
          fill: target.fill,
          opacity: target.opacity === undefined ? 1 : target.opacity, // Default to 1 if undefined
          id: target.id, // Ensure ID is included if available
          // Custom properties
          material: target.material,
          polyline: target.polyline,
          area: target.area, // Include calculated area if exists
          perimeter: target.perimeter, // Include calculated perimeter if exists
          scaleRatio: target.scaleRatio, // Include scaleRatio if exists
          pageNumber: target.pageNumber,
          documentName: target.documentName,
          // Add any other relevant properties
      };
      
      // Handle groups specifically
      if (target.type === 'group' && target._objects?.length > 0) {
          // For groups, maybe take properties from the first object or average?
          // Let's take from the first object for simplicity for now
          const firstObj = target._objects[0];
          properties.stroke = firstObj.stroke;
          properties.strokeWidth = firstObj.strokeWidth;
          properties.fill = firstObj.fill;
          properties.opacity = firstObj.opacity === undefined ? 1 : firstObj.opacity;
          properties.material = firstObj.material;
          properties.polyline = firstObj.polyline;
          // Note: Area/Perimeter might not make sense for a generic group
      }

      return properties;
  }

  applyCanvasConfig(canvasConfig) {
    // ... existing code ...
  }

  // --- Add Material Management Methods ---
  getMaterials() {
    // Return the current map of material names to colors
    return this.materials;
  }

  addMaterial(name, color) {
    if (!name || !color) {
      console.warn('[Board] Attempted to add material with invalid name or color.');
      return;
    }
    // Add or update the material
    this.materials[name] = color;
    console.log(`[Board] Material added/updated: ${name} - ${color}`);
    // TODO: Potentially trigger an event or callback if components need to react immediately
    // For now, ContextPanel re-fetches on selection change, which might be sufficient.
  }
  // --- End Material Management Methods ---

  // --- Layer Management Methods ---
  bringSelectedToFront() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas.bringToFront(activeObject);
      this.canvas.renderAll();
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
        console.warn('[Board] this.history.snapshot is not a function. History not saved on bringToFront.');
      }
      // Use _handleCanvasUpdate instead of handleCanvasUpdate
      if (this.crdt?.isInitialized()) {
        this.crdt._handleCanvasUpdate(); // Update CRDT
      }
      console.log('[Board] Brought object to front:', activeObject.id || activeObject.type);
    } else {
      console.warn('[Board] bringSelectedToFront: No object selected.');
    }
  }

  sendSelectedToBack() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      this.canvas.renderAll();
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
        console.warn('[Board] this.history.snapshot is not a function. History not saved on sendToBack.');
      }
      // Use _handleCanvasUpdate instead of handleCanvasUpdate
      if (this.crdt?.isInitialized()) {
        this.crdt._handleCanvasUpdate(); // Update CRDT
      }
      console.log('[Board] Sent object to back:', activeObject.id || activeObject.type);
    } else {
      console.warn('[Board] sendSelectedToBack: No object selected.');
    }
  }

  bringSelectedForward() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas.bringForward(activeObject);
      this.canvas.renderAll();
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
        console.warn('[Board] this.history.snapshot is not a function. History not saved on bringForward.');
      }
      // Use _handleCanvasUpdate instead of handleCanvasUpdate
      if (this.crdt?.isInitialized()) {
        this.crdt._handleCanvasUpdate(); // Update CRDT
      }
      console.log('[Board] Brought object forward:', activeObject.id || activeObject.type);
    } else {
      console.warn('[Board] bringSelectedForward: No object selected.');
    }
  }

  sendSelectedBackward() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      this.canvas.sendBackwards(activeObject);
      this.canvas.renderAll();
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
        console.warn('[Board] this.history.snapshot is not a function. History not saved on sendBackward.');
      }
      // Use _handleCanvasUpdate instead of handleCanvasUpdate
      if (this.crdt?.isInitialized()) {
        this.crdt._handleCanvasUpdate(); // Update CRDT
      }
      console.log('[Board] Sent object backward:', activeObject.id || activeObject.type);
    } else {
      console.warn('[Board] sendSelectedBackward: No object selected.');
    }
  }

  deleteSelectedObjects() {
    const activeObject = this.canvas?.getActiveObject();
    if (activeObject) {
      // Handle active selection (group) or single object
      if (activeObject.type === 'activeSelection' && activeObject._objects) {
        console.log(`[Board] Deleting group of ${activeObject._objects.length} objects.`);
        activeObject._objects.forEach(obj => {
          this.canvas.remove(obj);
        });
      } else {
        console.log('[Board] Deleting single object:', activeObject.id || activeObject.type);
        this.canvas.remove(activeObject);
      }
      this.canvas.discardActiveObject(); // Deselect
      this.canvas.requestRenderAll();
      // Check if history and snapshot exist before calling
      if (this.history && typeof this.history.snapshot === 'function') {
        this.history.snapshot(); // Use snapshot()
      } else {
        console.warn('[Board] this.history.snapshot is not a function. History not saved on deleteSelectedObjects.');
      }
      // Use _handleCanvasUpdate instead of handleCanvasUpdate
      if (this.crdt?.isInitialized()) {
        this.crdt._handleCanvasUpdate(); // Update CRDT
      }
      return true; // Indicate deletion occurred
    } else {
      console.warn('[Board] deleteSelectedObjects: No object selected.');
      return false; // Indicate nothing was deleted
    }
  }
  // --- End Layer Management Methods ---

  /**
   * Sets the callback function for board events sent to the UI layer.
   * @param {Function} callback - The function to call with event details.
   */
  setEventCallback(callback) {
    if (typeof callback === 'function') {
      console.log('[Board] Event callback set.');
      this.eventCallback = callback;
    } else {
      console.warn('[Board] Attempted to set invalid event callback.');
    }
  }

  startPeriodicSnapshots(whiteboardId) {
    // Ensure we only run one interval per board instance
    this.stopPeriodicSnapshots(); // Clear any existing interval first
  
    if (!whiteboardId || !this.crdt) {
      console.warn('[Board] Cannot start periodic snapshots: missing ID or CRDT instance.');
      return;
    }
    // Check if CRDT is actually initialized before starting
    if (!this.crdt.isInitialized) {
       console.warn('[Board] Cannot start periodic snapshots: CRDT is not initialized.');
       return;
    }
  
    console.log(`⏱️ [Board] Starting periodic snapshot saving every 60 seconds for ${whiteboardId}`);
  
    // Save immediately first?
    // this.crdt.saveSnapshot('latest').catch(e => console.error("Initial periodic save failed:", e));

    this.snapshotInterval = setInterval(async () => {
      // Check if CRDT is still initialized and has the same ID
      if (this.crdt && this.crdt.isInitialized && this.whiteboardId === whiteboardId) {
        console.log(`📸 [Board] Taking periodic snapshot for whiteboard: ${whiteboardId}`);
        try {
          // Save to the 'latest' document ID for quick retrieval
          await this.crdt.saveSnapshot('latest'); 
        } catch (error) {
          console.error(`❌ [Board] Error during periodic snapshot save for ${whiteboardId}:`, error);
          // Optionally stop the interval if saving consistently fails?
          // this.stopPeriodicSnapshots();
        }
      } else {
        console.log(`⏹️ [Board] Stopping periodic snapshots. Conditions not met (CRDT Initialized: ${this.crdt?.isInitialized}, Current ID: ${this.whiteboardId}, Target ID: ${whiteboardId}).`);
        this.stopPeriodicSnapshots();
      }
    }, 60000); // 60 seconds
  }
  
  stopPeriodicSnapshots() {
    if (this.snapshotInterval) {
      console.log('⏱️ [Board] Stopping periodic snapshots.');
      clearInterval(this.snapshotInterval);
      this.snapshotInterval = null;
    }
  }

  // Placeholder function if it doesn't exist
  _configureCanvas() {
    // Safety check to ensure canvas exists and is properly initialized
    if (!this.canvas || !this.canvas.getElement()) {
      console.warn('Canvas not fully initialized in _configureCanvas. Will retry later.');
      // Optionally, you could set up a retry mechanism here
      return;
    }
    
    try {
      // Apply any stored configuration
      if (this.canvasConfig) {
        this.applyCanvasConfig(this.canvasConfig);
      }
      
      // Setup event listeners and other canvas configurations
      this.canvas.renderAll();
    } catch (error) {
      console.error('Error configuring canvas:', error);
    }
  }

  _setupContextMenuListener() {
    console.log('[Board] Adding context menu listener (for click outside only)...');
    // Rest of the method...
  }

  // Add a new changeZoom method before the _configureCanvas method
  changeZoom({ point, scale }) {
    if (!this.canvas) return;
    
    try {
      // If no point provided, use center of canvas
      if (!point) {
        const width = this.canvas.width;
        const height = this.canvas.height;
        point = {
          x: width / 2,
          y: height / 2
        };
      }
      
      // Get current zoom level
      let currentZoom = this.canvas.getZoom();
      
      // Apply scale factor to current zoom
      let newZoom = currentZoom;
      if (typeof scale === 'number') {
        // Absolute scale value provided
        newZoom = scale;
      } else if (scale && typeof scale.scale === 'number') {
        // Scale factor provided
        newZoom = currentZoom * scale.scale;
      }
      
      // Enforce zoom limits
      const minZoom = this.canvasConfig.minZoom || 0.1;
      const maxZoom = this.canvasConfig.maxZoom || 7;
      newZoom = Math.max(minZoom, Math.min(maxZoom, newZoom));
      
      // Apply zoom
      this.canvas.zoomToPoint(point, newZoom);
      
      // Update canvas config
      if (this.canvasConfig) {
        this.canvasConfig.zoom = newZoom;
      }
      
      // Fire event if needed
      this.canvas.fire('zoom:change', { scale: newZoom, point });
      
      // Request render
      this.canvas.requestRenderAll();
      
      console.log(`Zoom changed to ${newZoom.toFixed(2)}`);
      return newZoom;
    } catch (error) {
      console.error('Error in changeZoom:', error);
    }
  }
}

// Export modes for use elsewhere
export { modes };
