import { 
  getAuth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut as firebaseSignOut,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { app } from './config';

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Set persistence to LOCAL (survives browser restarts)
setPersistence(auth, browserLocalPersistence)
  .catch((error) => {
    console.error("Error setting auth persistence:", error);
  });

// Current user state cache
let currentUser = null;

// Initialize the current user when the auth state changes
firebaseOnAuthStateChanged(auth, (user) => {
  currentUser = user;
  // Dispatch a custom event so components can react to auth changes
  window.dispatchEvent(new CustomEvent('authStateChanged', { detail: { user } }));
});

/**
 * Register a new user with email and password
 * @param {string} email - User's email
 * @param {string} password - User's password
 * @param {string} displayName - User's display name
 * @returns {Promise<UserCredential>} Firebase user credential
 */
export const registerUser = async (email, password, displayName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // Update the user profile with display name
    if (displayName) {
      await updateProfile(userCredential.user, { displayName });
    }
    return userCredential;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

/**
 * Sign in an existing user with email and password
 * @param {string} email - User's email
 * @param {string} password - User's password
 * @returns {Promise<UserCredential>} Firebase user credential
 */
export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};

/**
 * Sign in user with Google Popup
 * @returns {Promise<UserCredential>} Firebase user credential
 */
export const signInWithGooglePopup = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    console.log('Google Sign-In successful:', result.user.displayName);
    return result;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error('Error during Google Sign-In:', errorCode, errorMessage);
    throw error;
  }
};

/**
 * Sign out the current user
 * @returns {Promise<void>}
 */
export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};

/**
 * Listen to authentication state changes
 * @param {function} callback - Callback function that receives the user object
 * @returns {function} Unsubscribe function
 */
export const onAuthChange = (callback) => {
  return firebaseOnAuthStateChanged(auth, callback);
};

/**
 * Get the current authenticated user
 * @returns {User|null} Firebase user or null if not authenticated
 */
export const getCurrentUser = () => {
  // First check our cached value
  if (currentUser) return currentUser;
  
  // If not in cache, check auth directly
  return auth.currentUser;
};

/**
 * Check if user is authenticated
 * @returns {boolean} True if user is authenticated
 */
export const isAuthenticated = () => {
  return !!getCurrentUser();
}; 