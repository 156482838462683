import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class ScaleTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('pencil');
    this.toolMode = 'SCALE';
  }

  activate() {
    const canvas = this.canvas;
    
    // Explicitly set the mode to SCALE to avoid conflict with other tools
    if (this.board._currentMode !== 'SCALE') {
      this.board._currentMode = 'SCALE';
    }

    canvas.on('mouse:down', this.startAddScale.bind(this));
    canvas.on('mouse:move', this.startDrawingScale.bind(this));
    canvas.on('mouse:up', this.stopDrawingScale.bind(this));

    canvas.selection = false;
    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
    
    // Make sure snapshots are enabled when the tool is activated
    if (this.board.history) {
      this.board.history._disableSnapshots = false;
    }
    
    // Log that scale tool is activated - for debugging
    console.log('ScaleTool activated, mode set to:', this.board._currentMode);
  }
  
  deactivate() {
    this.canvas.off('mouse:down', this.startAddScale.bind(this));
    this.canvas.off('mouse:move', this.startDrawingScale.bind(this));
    this.canvas.off('mouse:up', this.stopDrawingScale.bind(this));
    
    // Ensure snapshots are re-enabled when deactivating the tool
    if (this.board.history && this.board.history._disableSnapshots) {
      this.board.history._disableSnapshots = false;
      
      if (process.env.NODE_ENV !== 'production') {
        console.log('Re-enabling snapshots on scale tool deactivation');
      }
    }
  }

  startAddScale(e) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      console.warn('ScaleTool.startAddScale called but tool is not active, current mode:', this.board._currentMode);
      return;
    }
    
    // Disable snapshots before any canvas modifications
    // This ensures the line creation is part of the same atomic undo operation
    if (this.board.history) {
      this.board.history._disableSnapshots = true;
      
      if (process.env.NODE_ENV !== 'production') {
        console.log('Disabling snapshots at the start of scale drawing');
      }
    }
    
    this.mouseDown = true;

    let pointer = this.canvas.getPointer(e.e);
    this.drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: this.drawingSettings.brushWidth,
      stroke: this.drawingSettings.currentColor,
      selectable: false,
    });

    this.canvas.add(this.drawInstance);
    this.canvas.requestRenderAll();
  }

  startDrawingScale(e) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      return;
    }
    
    if (this.mouseDown) {
      const pointer = this.canvas.getPointer(e.e);
      this.drawInstance.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      this.drawInstance.setCoords();
      this.canvas.requestRenderAll();
    }
  }

  stopDrawingScale() {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      if (this.mouseDown && this.drawInstance) {
        this.canvas.remove(this.drawInstance);
        this.mouseDown = false;
        
        // Re-enable snapshots
        if (this.board.history) {
          this.board.history._disableSnapshots = false;
        }
      }
      return;
    }
    
    // Now proceed if we're in the mouse down state
    if (this.mouseDown) {
      this.mouseDown = false;
      this.drawInstance.setCoords();
      
      // Calculate the pixel distance of the line
      const pixelDistance = this.calculateDistance(
        this.drawInstance.x1,
        this.drawInstance.y1,
        this.drawInstance.x2,
        this.drawInstance.y2
      );
      
      // Get the real-world distance from the user
      const realDistance = prompt('Enter the real-world distance of this line (in feet and inches, e.g., "5.5" for 5 feet 6 inches):', '1');
      
      if (realDistance && !isNaN(parseFloat(realDistance))) {
        // Calculate the scale ratio (pixels per unit)
        const scaleRatio = pixelDistance / parseFloat(realDistance);
        
        // Store the scale ratio on the canvas
        this.canvas.scaleRatio = scaleRatio;
        
        // Create a text object to display the distance
        const midX = (this.drawInstance.x1 + this.drawInstance.x2) / 2;
        const midY = (this.drawInstance.y1 + this.drawInstance.y2) / 2;
        
        const text = new fabric.Text(this.formatFeetAndInches(parseFloat(realDistance)), {
          left: midX,
          top: midY,
          fontSize: 14,
          fill: this.drawingSettings.currentColor,
          originX: 'center',
          originY: 'center',
          selectable: false,
        });
        
        // Group the line and text together
        const group = new fabric.Group([this.drawInstance, text], {
          selectable: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
          // Add metadata to identify this as a scale group
          data: {
            type: 'scale',
            scaleRatio: scaleRatio,
            realDistance: parseFloat(realDistance)
          }
        });
        
        this.canvas.remove(this.drawInstance);
        this.canvas.add(group);
        this.canvas.requestRenderAll();
        
        alert(`Scale set: ${pixelDistance.toFixed(2)} pixels = ${this.formatFeetAndInches(parseFloat(realDistance))}`);
      } else {
        // If the user cancels or enters invalid input, remove the line
        this.canvas.remove(this.drawInstance);
        this.canvas.requestRenderAll();
      }
      
      // Re-enable snapshots before taking a snapshot
      if (this.board.history) {
        this.board.history._disableSnapshots = false;
        
        if (process.env.NODE_ENV !== 'production') {
          console.log('Re-enabling snapshots after scale drawing');
        }
      }
      
      this.board.history.snapshot();
    }
  }

  calculateDistance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  formatFeetAndInches(feet) {
    const wholeFeet = Math.floor(feet);
    const inches = Math.round((feet - wholeFeet) * 12);
    
    // Handle case where inches rounds to 12
    if (inches === 12) {
      return `${wholeFeet + 1}'0"`;
    }
    
    return `${wholeFeet}'${inches}"`;
  }

  calculateRealDistance(x1, y1, x2, y2) {
    const pixelDistance = this.calculateDistance(x1, y1, x2, y2);
    if (this.canvas.scaleRatio) {
      return pixelDistance / this.canvas.scaleRatio;
    }
    return pixelDistance; // Return pixel distance if no scale is set
  }
}
