import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getCurrentUser, isAuthenticated } from '../../firebase/auth';
import { getUserWhiteboards, deleteWhiteboard } from '../../firebase/whiteboardManager';

// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 900px;
  max-height: 80vh;
  overflow-y: auto;
`;

const Title = styled.h2`
  margin-top: 0;
  color: #333;
  text-align: center;
`;

const WhiteboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`;

const WhiteboardCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const WhiteboardName = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
`;

const WhiteboardDate = styled.p`
  color: #777;
  font-size: 14px;
  margin-bottom: 15px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const OpenButton = styled(Button)`
  background-color: #4a90e2;
  color: white;
  
  &:hover:not(:disabled) {
    background-color: #357abd;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #f44336;
  color: white;
  
  &:hover:not(:disabled) {
    background-color: #d32f2f;
  }
`;

const CreateButton = styled(Button)`
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  margin-top: 20px;
  
  &:hover:not(:disabled) {
    background-color: #388e3c;
  }
`;

const CloseButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  
  &:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #4a90e2;
  animation: spin 1s ease-in-out infinite;
  margin: 50px auto;
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  text-align: center;
  padding: 20px;
  background-color: #ffebee;
  border-radius: 4px;
  margin: 20px 0;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 40px 20px;
  color: #777;
`;

/**
 * WhiteboardList Component - Displays a list of user's whiteboards
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onClose - Function to call when closing the modal
 * @param {Function} props.onSelect - Function to call when a whiteboard is selected
 * @param {Function} props.onCreateNew - Function to call when creating a new whiteboard
 * @returns {JSX.Element|null}
 */
const WhiteboardList = ({ isOpen, onClose, onSelect, onCreateNew }) => {
  const [whiteboards, setWhiteboards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleting, setDeleting] = useState(null);
  
  // Load whiteboards on mount and when auth state changes
  useEffect(() => {
    if (!isOpen) return;
    
    const loadWhiteboards = async () => {
      if (!isAuthenticated()) {
        setError('Please sign in to view your whiteboards');
        setLoading(false);
        return;
      }
      
      setLoading(true);
      setError(null);
      
      try {
        const userWhiteboards = await getUserWhiteboards();
        setWhiteboards(userWhiteboards);
      } catch (err) {
        console.error('Error loading whiteboards:', err);
        setError(err.message || 'Failed to load whiteboards');
      } finally {
        setLoading(false);
      }
    };
    
    loadWhiteboards();
  }, [isOpen]);
  
  // Handle whiteboard selection
  const handleSelect = (whiteboard) => {
    if (onSelect) {
      onSelect(whiteboard);
      onClose(); // Close the modal after selection
    }
  };
  
  // Handle whiteboard deletion
  const handleDelete = async (event, whiteboardId) => {
    // Prevent the click from bubbling up to the card
    event.stopPropagation();
    
    // Use a message without apostrophes
    const confirmText = "Are you sure you want to delete this whiteboard? This cannot be undone.";
    if (!window.confirm(confirmText)) {
      return;
    }
    
    setDeleting(whiteboardId);
    
    try {
      await deleteWhiteboard(whiteboardId);
      setWhiteboards(whiteboards.filter(wb => wb.id !== whiteboardId));
    } catch (err) {
      console.error('Error deleting whiteboard:', err);
      setError(err.message || 'Failed to delete whiteboard');
    } finally {
      setDeleting(null);
    }
  };
  
  // Handle creating a new whiteboard
  const handleCreateNew = () => {
    if (onCreateNew) {
      onCreateNew();
      // Don't close the modal yet - we'll wait for the creation to complete
    }
  };
  
  if (!isOpen) return null;
  
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>✕</CloseButton>
        <Title>Your Whiteboards</Title>
        
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : whiteboards.length === 0 ? (
          <EmptyState>
            <p>You don&apos;t have any whiteboards yet.</p>
            <CreateButton onClick={handleCreateNew}>Create New Whiteboard</CreateButton>
          </EmptyState>
        ) : (
          <>
            <WhiteboardGrid>
              {whiteboards.map(whiteboard => (
                <WhiteboardCard 
                  key={whiteboard.id} 
                  onClick={() => handleSelect(whiteboard)}
                >
                  <WhiteboardName>{whiteboard.name || 'Untitled Whiteboard'}</WhiteboardName>
                  <WhiteboardDate>
                    Created: {whiteboard.createdAt?.toDate().toLocaleString() || 'Unknown date'}
                  </WhiteboardDate>
                  <WhiteboardDate>
                    Last modified: {whiteboard.updatedAt?.toDate().toLocaleString() || 'Not modified'}
                  </WhiteboardDate>
                  <ButtonGroup>
                    <OpenButton onClick={(e) => {
                      e.stopPropagation(); // Prevent card click
                      handleSelect(whiteboard);
                    }}>Open</OpenButton>
                    <DeleteButton 
                      onClick={(e) => handleDelete(e, whiteboard.id)}
                      disabled={deleting === whiteboard.id}
                    >
                      {deleting === whiteboard.id ? 'Deleting...' : 'Delete'}
                    </DeleteButton>
                  </ButtonGroup>
                </WhiteboardCard>
              ))}
            </WhiteboardGrid>
            
            <ActionBar>
              <CreateButton onClick={handleCreateNew}>Create New Whiteboard</CreateButton>
            </ActionBar>
          </>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default WhiteboardList; 