import { v4 as uuidv4 } from 'uuid';
import { 
  doc, 
  collection, 
  addDoc, 
  onSnapshot, 
  updateDoc,
  query, 
  orderBy, 
  serverTimestamp,
  getFirestore,
  setDoc,
  getDoc,
  getDocs,
  limit,
  where
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { firestore, app as firebaseApp } from '../../firebase/config';
import { getCurrentUser, isAuthenticated } from '../../firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

// Initialize Firebase Auth using the specific app instance
const auth = getAuth(firebaseApp);

/**
 * Handles whiteboard data persistence with Firebase
 * (Previously used CRDT with Y-Sweet, now Firebase-only)
 */
export class CRDTIntegration {
  constructor({ 
    whiteboardId, 
    fabricCanvas, 
    history, 
    eventCallback,
    getCurrentUser,
    onRemoteUpdate,
    firebaseConfig 
  }) {
    if (!whiteboardId || !fabricCanvas || !history) {
      throw new Error('Missing required parameters for CRDTIntegration');
    }
    if (typeof eventCallback !== 'function') {
      console.warn('CRDTIntegration: eventCallback is not a function, events will not be emitted.');
      this.eventCallback = () => {};
    } else {
      this.eventCallback = eventCallback;
    }
    
    this.whiteboardId = whiteboardId;
    this.canvas = fabricCanvas;
    this.history = history;
    this.initialized = false;
    this.processingRemoteUpdate = false;
    this.authUnsubscribe = null;
    this.remoteUpdateCallback = onRemoteUpdate || (() => {});
    this.user = getCurrentUser ? getCurrentUser() : null;
    this.lastLoadedVersion = null; // Track the last loaded version to prevent overwriting
    this.autoSaveInterval = null;
    this.db = getFirestore();

    this.bindMethods();

    console.log(`📊 Firebase persistence initialized for whiteboard ID: ${this.whiteboardId}`);
    
    this._setupAuthListener(); 
  }

  bindMethods() {
    this.initialize = this.initialize.bind(this);
    this.dispose = this.dispose.bind(this);
    this._handleCanvasUpdate = this._handleCanvasUpdate.bind(this);
    this.saveSnapshot = this.saveSnapshot.bind(this);
    this.loadLatestSnapshot = this.loadLatestSnapshot.bind(this);
    this._handleAuthStateChanged = this._handleAuthStateChanged.bind(this);
    this._setupAuthListener = this._setupAuthListener.bind(this);
    this.saveWhiteboardData = this.saveWhiteboardData.bind(this);
    this.loadWhiteboardData = this.loadWhiteboardData.bind(this);
  }

  isInitialized() {
    return this.initialized;
  }

  async initialize() {
    console.log(`🔄 Initializing persistence for ${this.whiteboardId}`);
    
    if (this.initialized) {
      console.log(`Already initialized for ${this.whiteboardId}. Disposing before re-init.`);
      this.dispose(false);
    }
    this.initialized = false;

    if (!this.user) {
      console.warn('⚠️ Initialization skipped: User not available (checked during init).');
      this.eventCallback('crdt_init_failed', { whiteboardId: this.whiteboardId, reason: 'auth' });
      return false; 
    }
    console.log(`👤 User authenticated (${this.user.uid}). Proceeding.`);
    
    if (!this.whiteboardId) {
      console.error('❌ Initialization failed: Missing whiteboardId.');
      this.eventCallback('crdt_init_failed', { whiteboardId: this.whiteboardId, reason: 'missing_id' });
      return false;
    }
    
    try {
      // Load the latest snapshot from Firebase
      console.log(`💾 Loading latest snapshot for ${this.whiteboardId}...`);
      const snapshotLoaded = await this.loadLatestSnapshot();
      
      if (!snapshotLoaded) {
        console.log(`ℹ️ No existing data found for ${this.whiteboardId}, starting fresh.`);
        // We won't immediately save since that would overwrite potentially existing cloud data
        // Users will need to explicitly save
      }

      // Set up an autosave interval (e.g., every 60 seconds) if needed
      // this._setupAutoSave();

      this.initialized = true;
      console.log(`✅ Firebase persistence initialized successfully for ${this.whiteboardId}`);
      this.eventCallback('crdt_init_success', { whiteboardId: this.whiteboardId });
      return true;

    } catch (error) {
      console.error(`❌ Initialization failed for ${this.whiteboardId}:`, error);
      this.dispose(false);
      this.initialized = false;
      this.eventCallback('crdt_init_failed', { whiteboardId: this.whiteboardId, reason: 'error', error: error });
      return false;
    }
  }
  
  _setupAuthListener() {
    if (this.authUnsubscribe) {
      console.log('Auth listener already exists.');
      return;
    }
    console.log('Setting up Firebase Auth listener...');
    this.authUnsubscribe = onAuthStateChanged(auth, this._handleAuthStateChanged);
  }

  async _handleAuthStateChanged(user) {
    console.log(`🔒 Auth state changed. New user: ${user?.uid}`);
    const oldUserId = this.user?.uid;
    this.user = user;

    if (!user) {
      console.log(`🔒 User logged out for board ${this.whiteboardId}. Disposing.`);
      this.dispose(false);
      this.initialized = false;
    } 
    else if (user && this.whiteboardId && user.uid !== oldUserId) {
      console.log(`🔒 User logged in/changed for board ${this.whiteboardId}. Re-initializing.`);
      await this.initialize(); 
    }
  }

  dispose(removeAuthListener = true) {
    console.log(`🧹 Disposing resources for ${this.whiteboardId}`);
    this.initialized = false;
    
    // Clear any autosave interval
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
      this.autoSaveInterval = null;
    }
    
    if (removeAuthListener && this.authUnsubscribe) {
      console.log('🔒 Unsubscribing from Firebase Auth listener.');
      this.authUnsubscribe();
      this.authUnsubscribe = null;
    }
    
    console.log(`✅ Resources disposed for ${this.whiteboardId}.`);
  }

  /**
   * Handle canvas updates - doesn't automatically save to avoid data loss
   */
  _handleCanvasUpdate(event) {
    if (!this.initialized) {
      return;
    }
    
    if (event && event.type === 'canvas:cleared') {
      console.log(`🗑️ Canvas cleared locally for ${this.whiteboardId}.`);
      // We don't automatically save the cleared state to avoid data loss
      return;
    }
    
    // Update last modification time but don't save automatically
    this.lastModified = new Date();
    console.log(`✏️ Canvas modified. Use the save button to persist changes.`);
  }

  /**
   * Save current whiteboard state to Firebase
   * @param {boolean} force - If true, skip confirmation even when overwriting
   * @returns {Promise<boolean>} - Success status
   */
  async saveWhiteboardData(force = false) {
    if (!this.initialized || !this.whiteboardId || !this.user) {
      console.warn(`⚠️ Cannot save: Not initialized, missing whiteboard ID, or not logged in.`);
      return false;
    }

    try {
      // Check if we'd be overwriting newer data
      if (!force && this.lastLoadedVersion) {
        // Check if there's a newer version in Firebase
        const newerVersion = await this._checkForNewerVersion();
        if (newerVersion) {
          // Ask user for confirmation before overwriting
          const shouldOverwrite = confirm(
            "There is a newer version of this whiteboard on the server. Overwrite it with your current changes?"
          );
          if (!shouldOverwrite) {
            console.log("💡 Save cancelled by user to avoid overwriting newer version.");
            return false;
          }
        }
      }

      console.log(`💾 Saving whiteboard data for ${this.whiteboardId}...`);
      const canvasJSON = this.canvas.toJSON(['id']);
      const snapshotData = JSON.stringify(canvasJSON);
      
      // Create snapshot document
      const snapshotDoc = {
        whiteboardId: this.whiteboardId,
        createdAt: serverTimestamp(),
        createdBy: this.user.uid,
        canvasJSON: snapshotData,
        version: Date.now() // Use timestamp as version number
      };

      // Save to the whiteboardSnapshots collection
      const snapshotsCol = collection(this.db, 'whiteboardSnapshots');
      const newSnapshot = await addDoc(snapshotsCol, snapshotDoc);
      
      // Update the whiteboard's lastSnapshotId field
      const whiteboardRef = doc(this.db, 'whiteboards', this.whiteboardId);
      await updateDoc(whiteboardRef, {
        lastSnapshotId: newSnapshot.id,
        lastModified: serverTimestamp()
      });
      
      // Update our local version tracking
      this.lastLoadedVersion = snapshotDoc.version;
      
      console.log(`✅ Whiteboard saved successfully! Version: ${snapshotDoc.version}`);
      return true;
    } catch (error) {
      console.error(`❌ Error saving whiteboard:`, error);
      return false;
    }
  }

  /**
   * Load the latest whiteboard data from Firebase
   */
  async loadWhiteboardData(confirmIfUnsaved = true) {
    if (!this.initialized || !this.whiteboardId) {
      console.warn(`⚠️ Cannot load: Not initialized or missing whiteboard ID.`);
      return false;
    }

    // Check if there are unsaved changes
    if (confirmIfUnsaved && this.lastModified && this.lastLoadedVersion) {
      const shouldDiscard = confirm(
        "You have unsaved changes. Load the server version and discard your changes?"
      );
      if (!shouldDiscard) {
        console.log("💡 Load cancelled by user to avoid losing unsaved changes.");
        return false;
      }
    }

    return await this.loadLatestSnapshot();
  }

  /**
   * Check if there's a newer version in Firebase than our last loaded version
   * @returns {Promise<boolean>}
   */
  async _checkForNewerVersion() {
    if (!this.lastLoadedVersion) return false;
    
    try {
      const snapshotsCol = collection(this.db, 'whiteboardSnapshots');
      const q = query(
        snapshotsCol,
        where('whiteboardId', '==', this.whiteboardId),
        where('version', '>', this.lastLoadedVersion),
        limit(1)
      );
      
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error("Error checking for newer version:", error);
      return false; // Assume no newer version on error
    }
  }

  /**
   * Set up autosave interval (if desired)
   */
  _setupAutoSave(intervalMs = 60000) {
    this.stopAutoSave();
    console.log(`⏱️ Setting up autosave every ${intervalMs / 1000} seconds`);
    
    this.autoSaveInterval = setInterval(async () => {
      if (this.initialized && this.lastModified) {
        // Only autosave if there are changes since last save/load
        const lastSaveTime = this.lastLoadedVersion || 0;
        if (this.lastModified.getTime() > lastSaveTime) {
          console.log(`⏱️ Auto-saving whiteboard ${this.whiteboardId}...`);
          await this.saveWhiteboardData(false); // Don't force overwrite
        }
      }
    }, intervalMs);
  }

  /**
   * Stop autosave interval
   */
  stopAutoSave() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
      this.autoSaveInterval = null;
      console.log(`⏱️ Autosave stopped`);
    }
  }

  /**
   * Legacy method name kept for compatibility
   */
  async saveSnapshot(snapshotId) {
    return this.saveWhiteboardData(false);
  }

  /**
   * Load the latest snapshot from Firebase
   * @returns {Promise<boolean>} - Success status
   */
  async loadLatestSnapshot() {
    if (!this.whiteboardId) {
      console.warn(`⚠️ Cannot load snapshot: Missing whiteboard ID.`);
      return false;
    }
    
    console.log(`🔎 Loading latest snapshot for ${this.whiteboardId}...`);
    
    try {
      // Query for the latest snapshot by createdAt timestamp
      const snapshotsCol = collection(this.db, 'whiteboardSnapshots');
      const q = query(
        snapshotsCol, 
        where('whiteboardId', '==', this.whiteboardId),
        orderBy('createdAt', 'desc'), 
        limit(1)
      );
      
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log(`🤷 No snapshot found for ${this.whiteboardId}.`);
        return false;
      }

      const snapshotDoc = querySnapshot.docs[0].data();
      console.log(`✅ Snapshot found (created at ${snapshotDoc.createdAt?.toDate()}). Applying...`);

      if (snapshotDoc.canvasJSON) {
        // Store the version we're loading
        this.lastLoadedVersion = snapshotDoc.version || Date.now();
        
        const canvasData = JSON.parse(snapshotDoc.canvasJSON);
        if (canvasData && canvasData.objects) {
          console.log(`   Applying ${canvasData.objects.length} objects from snapshot...`);
          
          // Clear the canvas first
          this.canvas.clear();
          
          // Load the snapshot data
          this.canvas.loadFromJSON(canvasData, () => {
            this.canvas.renderAll();
            console.log('   ✅ Canvas populated from snapshot.');
            
            // Snapshot for history
            setTimeout(() => {
              this.history?.snapshot();
            }, 100);
            
            // Reset modification tracking
            this.lastModified = null;
          });
          
          return true;
        } else {
          console.warn('   ⚠️ Snapshot canvasJSON is invalid or has no objects.');
        }
      } else {
        console.warn('   ⚠️ Snapshot document missing canvasJSON data.');
      }
      
      return false;

    } catch (error) {
      console.error(`❌ Error loading snapshot for ${this.whiteboardId}:`, error);
      return false;
    }
  }
} 