import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class LineTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('pencil');
  }

  activate() {
    const canvas = this.canvas;

    canvas.on('mouse:down', this.startAddLine.bind(this));
    canvas.on('mouse:move', this.startDrawingLine.bind(this));
    canvas.on('mouse:up', this.stopDrawing.bind(this));

    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.discardActiveObject().requestRenderAll();
  }

  startAddLine(e) {
    this.mouseDown = true;

    let pointer = this.canvas.getPointer(e.e);
    this.drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: this.drawingSettings.brushWidth,
      stroke: this.drawingSettings.currentColor,
      selectable: false,
    });

    this.canvas.add(this.drawInstance);
    this.canvas.requestRenderAll();
  }

  startDrawingLine(e) {
    if (this.mouseDown) {
      const pointer = this.canvas.getPointer(e.e);
      this.drawInstance.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      this.drawInstance.setCoords();
      this.canvas.requestRenderAll();
    }
  }
}
