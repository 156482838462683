class History {
  constructor(canvas) {
    this.canvas = canvas;
    this.history = [];
    this.redo_history = [];
    this._redoing = false;
    this._undoing = false; // Flag to track if we're in the middle of an undo operation
    this._disableSnapshots = false; // Flag to temporarily disable snapshots (used during complex operations)
    this.onHistoryChange = null; // Callback for when history state changes
  }
  
  // Method to get the length of the history stack
  getHistoryLength() {
    return this.history.length;
  }
  
  // Method to get the length of the redo history stack
  getRedoHistoryLength() {
    return this.redo_history.length;
  }

  clear() {
    this.history = [];
    this.redo_history = [];
    
    // Trigger callback if defined
    if (typeof this.onHistoryChange === 'function') {
      this.onHistoryChange();
    }
  }

  snapshot() {
    // Initial call, Skip empty calls
    if (!this.history.length && !this.canvas._objects.length) {
      return;
    }

    // Don't register history if snapshots are explicitly disabled
    if (this._disableSnapshots) {
      return;
    }

    // Don't register history if we're in the middle of undo or redo operations
    // This prevents us from creating new history entries while we're still processing
    // the previous undo/redo operation
    if (this._redoing || this._undoing) {
      return;
    }
    
    // Check if we're in the middle of a polyline drawing operation
    // If the current active tool is a polyline tool and it's not yet complete, don't create a snapshot
    const isPolylineDrawing = this._isPolylineDrawingInProgress();
    if (isPolylineDrawing) {
      return;
    }

    // JSON string better approach to deep-clone Object
    const canvasJSON = JSON.stringify(this.canvas.toJSON(['id', 'selectable', 'name']));

    // If current state same as previous - ignore it
    if (this.history.length && canvasJSON === this.history[this.history.length - 1]) {
      return;
    }

    // Add current state of canvas to history
    this.history.push(canvasJSON);

    // Only clear redo history if we're not in the middle of a redo operation
    // This preserves the ability to continue redoing multiple actions
    if (!this._redoing) {
      this.redo_history = [];
    }
    
    // Notify the canvas that the history has changed
    if (this.canvas.fire) {
      this.canvas.fire('history:changed');
    }
    
    // Trigger callback if defined
    if (typeof this.onHistoryChange === 'function') {
      this.onHistoryChange();
    }
  }

  redo() {
    if (!this.redo_history.length) {
      return;
    }

    // Save flag that we're in redoing process
    this._redoing = true;

    // Get redo JSON, and send it from redo history to timeline history
    // BTW: last element of array is the latest item
    const canvasJSON = this.redo_history.pop();
    this.history.push(canvasJSON);

    // Parse canvas state and re-render canvas
    this.canvas.loadFromJSON(JSON.parse(canvasJSON), () => {
      this.canvas.renderAll();
      
      // Notify canvas that history has changed
      if (this.canvas.fire) {
        this.canvas.fire('history:changed');
      }
      
      // Reset the redoing flag only after the canvas has been fully rendered
      // This ensures that if snapshot() is called during rendering, it won't clear the redo stack
      setTimeout(() => {
        this._redoing = false;
        
        // Trigger callback if defined
        if (typeof this.onHistoryChange === 'function') {
          this.onHistoryChange();
        }
      }, 50);
    });
  }

  undo() {
    // Prevent undo if already undoing
    if (this._undoing) {
      return;
    }
    
    // Allow undoing as long as there's at least one item in history
    if (this.history.length === 0) {
      return;
    }
    
    // Set undoing flag to prevent history snapshots during undo operation
    this._undoing = true;

    // Get item from history (last element is current state, before-last is where we need to go)
    const canvasJSON = this.history.pop();

    // Pass current (old) state to redo stack
    this.redo_history.push(canvasJSON);
    
    // Trigger callback if defined
    if (typeof this.onHistoryChange === 'function') {
      this.onHistoryChange();
    }
    
    // Notify canvas that history has changed
    if (this.canvas.fire) {
      this.canvas.fire('history:changed');
    }

    // Get new active state and parse it to canvas state if available
    if (this.history.length > 0) {
      const newActiveState = this.history[this.history.length - 1];
      this.canvas.loadFromJSON(newActiveState, () => {
        this.canvas.renderAll();
        
        // Reset undoing flag after the canvas is fully updated
        setTimeout(() => {
          this._undoing = false;
        }, 50);
      });
    } else {
      // If no history left, clear the canvas
      this.canvas.clear();
      this.canvas.renderAll();
      
      // Reset undoing flag after the canvas is fully updated
      setTimeout(() => {
        this._undoing = false;
      }, 50);
    }
  }
  
  /**
   * Check if a polyline drawing is in progress
   * This prevents creating multiple history entries during polyline creation
   * @private
   * @returns {boolean} - true if a polyline drawing is in progress
   */
  _isPolylineDrawingInProgress() {
    // If the canvas has a board with the helper method, use it
    if (this.canvas.board && this.canvas.board._isPolylineDrawingInProgress) {
      return this.canvas.board._isPolylineDrawingInProgress();
    }
    
    // Fallback implementation
    // Check if the canvas has a board property with a toolFactory
    if (!this.canvas.board || !this.canvas.board.toolFactory) {
      return false;
    }
    
    // Get the current mode/tool name
    const currentMode = this.canvas.board.getMode();
    
    // If the current mode is POLYLINE, get the tool instance
    if (currentMode === 'POLYLINE') {
      const polylineTool = this.canvas.board.toolFactory.getTool('POLYLINE');
      
      // If the tool exists and is currently drawing but not complete, return true
      if (polylineTool && polylineTool.isDrawing && !polylineTool.isDrawingComplete) {
        return true;
      }
    }
    
    return false;
  }
}

export default History;
