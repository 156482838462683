import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

/**
 * Clean plant name for consistent formatting
 * Removes numeric suffixes, trims whitespace, etc.
 * @param {string} originalName - Original plant name
 * @returns {string} - Cleaned plant name
 */
function cleanPlantName(originalName) {
  if (!originalName || typeof originalName !== 'string') {
    return 'Unknown Plant';
  }
  
  // Replace underscores and plus signs with spaces
  let cleanedName = originalName.replace(/[_+]/g, ' ');
  
  // Common prefixes to remove
  const prefixesToRemove = ['plant', 'tree', 'bush', 'flower', 'shrub', 'specimen', 'dtree'];
  
  // Remove common prefixes if they are full words
  prefixesToRemove.forEach(prefix => {
    const regex = new RegExp(`\\b${prefix}\\b[\\s_+]*`, 'gi');
    cleanedName = cleanedName.replace(regex, '');
  });
  
  // Remove numeric suffixes like " 0", " 1", etc.
  cleanedName = cleanedName.replace(/\s+\d+$/g, '');
  
  // Also remove "dtree" prefix commonly found in plant names
  cleanedName = cleanedName.replace(/^dtree\s+/i, '');
  
  // Replace multiple spaces with a single space
  cleanedName = cleanedName.replace(/\s+/g, ' ');
  
  // Trim leading and trailing spaces
  cleanedName = cleanedName.trim();
  
  // Convert to Title Case (capitalize first letter of each word)
  cleanedName = cleanedName.split(' ')
    .map(word => word.trim())
    .filter(word => word.length > 0)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  
  // If cleaned name is empty, use a default
  if (!cleanedName) {
    return 'Plant';
  }
  
  return cleanedName;
}

const CalculatorContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 300px;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const CalculatorHeader = styled.div`
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ActionButton = styled.button`
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 8px;
  
  &:hover {
    background: #43a047;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  
  &:hover {
    color: #333;
  }
`

const CalculatorContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 8px 16px;
`

const SectionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #666;
  margin: 12px 0 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  font-size: 13px;
`

const ItemName = styled.div`
  display: flex;
  align-items: center;
`

const ItemCount = styled.div`
  font-weight: 500;
`

const ColorSwatch = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
  background-color: ${props => props.color || '#ddd'};
  border: 1px solid rgba(0, 0, 0, 0.1);
`

const IconSwatch = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.03);
`

const TotalCount = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  padding-top: 8px;
  font-weight: 500;
`

function ObjectCalculator({ board, onClose }) {
  // Track if there's an active selection
  const [hasSelection, setHasSelection] = useState(false);
  // Track which objects are selected
  const [selectedObjects, setSelectedObjects] = useState([]);
  
  // Initial check for selections when component mounts
  useEffect(() => {
    if (board?.canvas) {
      const activeSelection = board.canvas.getActiveObjects();
      setHasSelection(activeSelection && activeSelection.length > 0);
      setSelectedObjects(activeSelection || []);
    }
  }, [board]);
  
  // Calculate object counts from the canvas
  const calculateObjectCounts = () => {
    if (!board?.canvas) return { materials: {}, stamps: {} };
    
    // Determine which objects to count
    let objectsToCount;
    
    // If there are selected objects, count only those
    if (hasSelection && selectedObjects.length > 0) {
      objectsToCount = selectedObjects;
    } else {
      // Otherwise count all visible objects
      objectsToCount = board.canvas.getObjects();
    }
    
    // Filter out UI controls, temporary objects, and hidden objects
    const objects = objectsToCount.filter(obj => {
      // Filter out UI controls, temporary objects, and hidden objects
      // Allow groups IF they have stamp=true (custom stamps)
      const isStampGroup = obj.type === 'group' && obj.stamp === true;
      
      return obj.visible !== false && 
             !obj._controlsVisibility && // Not a control handle
             !obj.isToolCreated && // Not a temporary tool-created object
             obj.type !== 'activeSelection' && // Not a selection box
             (obj.type !== 'group' || isStampGroup) && // Allow stamp groups
             !obj.excludeFromExport && // Not an object marked to exclude from export
             obj.opacity !== 0 && // Not invisible
             obj !== board.canvas.backgroundImage && // Not the background image
             obj !== board.canvas.overlayImage; // Not the overlay image
    });
    
    if (process.env.NODE_ENV !== 'production') {
      console.log(`Total visible objects: ${objects.length}`);
    }
    
    // Count materials (polygons with material property)
    const materials = objects.reduce((acc, obj) => {
      if (obj.material) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Found material object: ${obj.material}`);
        }
        acc[obj.material] = (acc[obj.material] || 0) + 1;
      }
      return acc;
    }, {});
    
    // Add detailed debugging for all objects
    if (process.env.NODE_ENV !== 'production') {
      objects.forEach((obj, index) => {
        console.log(`Object ${index}:`, {
          type: obj.type,
          stamp: obj.stamp,
          objectType: obj.objectType,
          stampName: obj.stampName,
          customStamp: obj.customStamp,
          fill: obj.fill,
          hasPath: !!obj.path
        });
      });
    }
    
    // Count stamps (objects with stamp=true)
    const stamps = {};
    
    // First pass to identify all unique objects and debug them
    objects.forEach(obj => {
      if (obj.stamp === true && obj.objectType === 'stamp') {
        const stampName = getStampNameFromObject(obj);
        const positionKey = `${Math.round(obj.left)}_${Math.round(obj.top)}`;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Found stamp: ${stampName}, pos: ${positionKey}`, {
            left: obj.left,
            top: obj.top,
            strokeWidth: obj.strokeWidth,
            stamp: obj.stamp,
            objectType: obj.objectType,
            _objects: obj._objects ? obj._objects.length : 'none'
          });
        }
      }
    });
    
    // Build sets of positions to detect duplicates (objects at the same position)
    const positionSets = {};
    
    // Second pass to count stamps, avoiding duplicates at the same position
    objects.forEach(obj => {
      // Count both regular stamps AND plants (objectType can be 'stamp' or 'plant')
      if (obj.stamp === true && (obj.objectType === 'stamp' || obj.objectType === 'plant')) {
        const stampName = getStampNameFromObject(obj);
        
        // Use unique identifier from metadata if available, otherwise use position
        let uniqueKey;
        if (obj.metadata && obj.metadata.uniqueId) {
          uniqueKey = obj.metadata.uniqueId;
        } else {
          // Fall back to position-based keys for backward compatibility
          const x = isNaN(obj.left) ? 0 : Math.round(obj.left);
          const y = isNaN(obj.top) ? 0 : Math.round(obj.top);
          uniqueKey = `${x}_${y}_${Math.random().toString(36).substring(2, 5)}`;
        }
        
        // Initialize position set for this stamp type if needed
        if (!positionSets[stampName]) {
          positionSets[stampName] = new Set();
        }
        
        // Only count if we haven't seen an object with this unique ID before
        if (!positionSets[stampName].has(uniqueKey)) {
          positionSets[stampName].add(uniqueKey);
          stamps[stampName] = (stamps[stampName] || 0) + 1;
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Counting stamp: ${stampName} with uniqueKey ${uniqueKey}`);
          }
        } else if (process.env.NODE_ENV !== 'production') {
          console.log(`Skipping duplicate stamp: ${stampName} with uniqueKey ${uniqueKey}`);
        }
      }
    });
    
    return { materials, stamps };
  };
  
  // Helper function to identify the stamp name from an object
  const getStampNameFromObject = (obj) => {
    let originalName;
    
    // If stampName property exists (added in our update), use it directly
    if (obj.stampName) {
      originalName = obj.stampName;
    }
    // For plants, use the plantName property if available
    else if (obj.objectType === 'plant' && obj.plantName) {
      originalName = obj.plantName;
    }
    // For plants, check metadata
    else if (obj.objectType === 'plant' && obj.metadata && obj.metadata.name) {
      originalName = obj.metadata.name;
    }
    else {
      // Fallback method using color if stampName isn't available
      const { fill } = obj;
      
      // Color-based fallback mapping
      const colorToNameMap = {
        '#2E7D32': 'Small Tree',
        '#1B5E20': 'Large Tree',
        '#33691E': 'Palm Tree',
        '#388E3C': 'Bush',
        '#558B2F': 'Shrub',
        '#795548': 'Bench',
        '#8D6E63': 'Table',
        '#A1887F': 'Planter',
        '#F57C00': 'Umbrella',
      };
      
      originalName = colorToNameMap[fill] || 'Other Object';
    }
    
    // Clean plant names to ensure all similar plants are grouped together
    if (obj.objectType === 'plant' || originalName.includes('Tree') || 
        originalName.includes('Bush') || originalName.includes('Flower') ||
        originalName.includes('Daisy') || originalName.includes('Salvia') ||
        originalName.includes('Gaura') || originalName.includes('Echinacea') ||
        originalName.includes('Lily') || originalName.includes('Buddleia') ||
        originalName.includes('Rosmarinus')) {
      return cleanPlantName(originalName);
    }
    
    // Return original name for non-plant objects
    return originalName;
  };
  
  const { materials, stamps } = calculateObjectCounts();
  
  // Calculate totals
  const totalMaterials = Object.values(materials).reduce((sum, count) => sum + count, 0);
  const totalStamps = Object.values(stamps).reduce((sum, count) => sum + count, 0);
  const totalObjects = totalMaterials + totalStamps;
  
  // Function to export data to Excel
  const exportToExcel = () => {
    try {
      // Create worksheet data
      const wsData = [];
      
      // Add header
      wsData.push(['Object Calculator Export']);
      wsData.push(['Generated on', new Date().toLocaleString()]);
      wsData.push(['Mode', hasSelection ? 'Selected Objects Only' : 'All Objects']);
      wsData.push([]);
      
      // Add materials section if there are materials
      if (Object.keys(materials).length > 0) {
        wsData.push(['Materials']);
        wsData.push(['Name', 'Count']);
        
        Object.entries(materials).forEach(([material, count]) => {
          wsData.push([material, count]);
        });
        
        wsData.push(['Total Materials', totalMaterials]);
        wsData.push([]);
      }
      
      // Add stamps section if there are stamps
      if (Object.keys(stamps).length > 0) {
        wsData.push(['Stamps']);
        wsData.push(['Name', 'Count']);
        
        Object.entries(stamps).forEach(([stampName, count]) => {
          wsData.push([stampName, count]);
        });
        
        wsData.push(['Total Stamps', totalStamps]);
        wsData.push([]);
      }
      
      // Add total objects
      wsData.push(['Total Objects', totalObjects]);
      
      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);
      
      // Create workbook and add worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Object Calculator');
      
      // Generate Excel file
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      
      // Save file
      const fileName = `object-calculator-export-${new Date().toISOString().slice(0, 10)}.xlsx`;
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
      
      console.log('Excel export completed');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      alert('Failed to export to Excel. See console for details.');
    }
  };
  
  // Update when selection changes
  useEffect(() => {
    const handleSelectionCreated = () => {
      const selection = board.canvas.getActiveObjects();
      setHasSelection(true);
      setSelectedObjects(selection || []);
    };
    
    const handleSelectionUpdated = () => {
      const selection = board.canvas.getActiveObjects();
      setHasSelection(true);
      setSelectedObjects(selection || []);
    };
    
    const handleSelectionCleared = () => {
      setHasSelection(false);
      setSelectedObjects([]);
    };
    
    if (board?.canvas) {
      board.canvas.on('selection:created', handleSelectionCreated);
      board.canvas.on('selection:updated', handleSelectionUpdated);
      board.canvas.on('selection:cleared', handleSelectionCleared);
      
      return () => {
        board.canvas.off('selection:created', handleSelectionCreated);
        board.canvas.off('selection:updated', handleSelectionUpdated);
        board.canvas.off('selection:cleared', handleSelectionCleared);
      };
    }
  }, [board]);
  
  // Prevent calculator clicks from clearing selection
  const handleCalculatorClick = (e) => {
    e.stopPropagation();
  };
  
  return (
    <CalculatorContainer onClick={handleCalculatorClick}>
      <CalculatorHeader>
        <span>
          {hasSelection 
            ? `Object Calculator (Selected: ${selectedObjects.length})` 
            : 'Object Calculator (All)'}
        </span>
        <ButtonsContainer>
          <ActionButton onClick={exportToExcel}>
            Export to Excel
          </ActionButton>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ButtonsContainer>
      </CalculatorHeader>
      
      <CalculatorContent>
        {/* Materials Section */}
        {Object.keys(materials).length > 0 && (
          <>
            <SectionTitle>Materials</SectionTitle>
            {Object.entries(materials).map(([material, count]) => (
              <ItemRow key={material}>
                <ItemName>
                  <ColorSwatch color={getMaterialColor(material)} />
                  {material}
                </ItemName>
                <ItemCount>{count}</ItemCount>
              </ItemRow>
            ))}
            
            <ItemRow>
              <div>Total Materials</div>
              <ItemCount>{totalMaterials}</ItemCount>
            </ItemRow>
          </>
        )}
        
        {/* Stamps Section */}
        {Object.keys(stamps).length > 0 && (
          <>
            <SectionTitle>Stamps</SectionTitle>
            {Object.entries(stamps).map(([stampName, count]) => (
              <ItemRow key={stampName}>
                <ItemName>
                  <IconSwatch>
                    <svg width="16" height="16" viewBox="0 0 24 24">
                      <path 
                        d={getStampPath(stampName)} 
                        fill={getStampColor(stampName)}
                      />
                    </svg>
                  </IconSwatch>
                  {stampName}
                </ItemName>
                <ItemCount>{count}</ItemCount>
              </ItemRow>
            ))}
            
            <ItemRow>
              <div>Total Stamps</div>
              <ItemCount>{totalStamps}</ItemCount>
            </ItemRow>
          </>
        )}
        
        {/* Total Objects */}
        <TotalCount>
          <div>Total Objects</div>
          <div>{totalObjects}</div>
        </TotalCount>
      </CalculatorContent>
    </CalculatorContainer>
  );
}

// Helper functions to get material colors and stamp paths/colors
function getMaterialColor(materialName) {
  const MATERIAL_COLORS = {
    'Concrete': '#858585',
    'Wood': '#CD853F',
    'Metal': '#B8B8B8',
    'Glass': '#ADD8E6',
    'Plastic': '#F0E68C',
    'Stone': '#A9A9A9',
    'Brick': '#B22222',
    'Drywall': '#F5F5F5',
    'Ceramic': '#F5F5DC',
    'Carpet': '#008080',
    'Asphalt': '#2F4F4F',
    'Rubber': '#000000',
    'Vinyl': '#BA55D3',
    'Foam': '#FFE4B5',
    'Tile': '#F0FFF0',
    'Insulation': '#FFF8DC',
    'Composite': '#9370DB',
  };
  
  return MATERIAL_COLORS[materialName] || '#cccccc';
}

// Simplified mapping of stamp names to their properties
function getStampColor(stampName) {
  const stampColors = {
    'Small Tree': '#2E7D32',
    'Large Tree': '#1B5E20',
    'Palm Tree': '#33691E',
    'Bush': '#388E3C',
    'Shrub': '#558B2F',
    'Bench': '#795548',
    'Table': '#8D6E63',
    'Chair': '#8D6E63',
    'Umbrella': '#F57C00',
    'Planter': '#A1887F',
    'Other Object': '#999999',
    'Unknown Object': '#999999',
  };
  
  return stampColors[stampName] || '#999999';
}

function getStampPath(stampName) {
  // Simplified paths for icons
  const basicCircle = 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z';
  const basicSquare = 'M3,3H21V21H3V3Z';
  
  const stampPaths = {
    'Small Tree': 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z',
    'Large Tree': 'M12,2L4,12H6V19H18V12H20L12,2Z',
    'Palm Tree': 'M12,15L7,20H11V24H13V20H17L12,15Z',
    'Bush': basicCircle,
    'Shrub': 'M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
    'Bench': 'M4,2H20V8H4V2Z',
    'Table': 'M3,6H21V8H3V6Z',
    'Chair': 'M15,12V16H9V12H15Z',
    'Umbrella': 'M12,2A9,9 0 0,0 3,11H21A9,9 0 0,0 12,2Z',
    'Planter': 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z'
  };
  
  return stampPaths[stampName] || basicSquare;
}

export { ObjectCalculator }
