import React, { useState } from 'react';
import styled from 'styled-components';

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  max-width: 90%;
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  
  h3 {
    margin: 0;
    font-size: 18px;
  }
  
  button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0 5px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
`;

const ColorPicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
`;

const ColorOption = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid ${props => props.selected ? '#000' : 'transparent'};
  background-color: ${props => props.color};
  cursor: pointer;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  
  button {
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    
    &.primary {
      background-color: #4a6ee0;
      color: white;
      border: none;
    }
    
    &.secondary {
      background-color: #f2f2f2;
      color: #333;
      border: 1px solid #ddd;
    }
  }
`;

const DEFAULT_COLORS = [
  '#FF0000', '#FF9900', '#FFCC00', '#33CC33', 
  '#3366FF', '#9933FF', '#FF3399', '#999999',
  '#624a2e', '#8b7d6b', '#606c38', '#dda15e',
  '#006d77', '#83c5be', '#bc6c25', '#ffd166'
];

const MaterialDialog = ({ open, onClose, onAdd }) => {
  const [materialName, setMaterialName] = useState('');
  const [selectedColor, setSelectedColor] = useState(DEFAULT_COLORS[0]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!materialName.trim()) {
      alert('Please enter a material name');
      return;
    }
    
    onAdd(materialName, selectedColor);
    
    // Reset form
    setMaterialName('');
    setSelectedColor(DEFAULT_COLORS[0]);
  };
  
  if (!open) return null;
  
  return (
    <DialogOverlay onClick={onClose}>
      <DialogContent onClick={e => e.stopPropagation()}>
        <DialogHeader>
          <h3>Add New Material</h3>
          <button onClick={onClose}>×</button>
        </DialogHeader>
        
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <label htmlFor="materialName">Material Name</label>
            <input
              id="materialName"
              type="text"
              value={materialName}
              onChange={e => setMaterialName(e.target.value)}
              placeholder="Enter material name"
              autoFocus
            />
          </FormGroup>
          
          <label>Material Color</label>
          <ColorPicker>
            {DEFAULT_COLORS.map(color => (
              <ColorOption
                key={color}
                color={color}
                selected={selectedColor === color}
                onClick={() => setSelectedColor(color)}
              />
            ))}
          </ColorPicker>
          
          <ActionButtons>
            <button 
              type="button" 
              className="secondary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="primary"
            >
              Add Material
            </button>
          </ActionButtons>
        </form>
      </DialogContent>
    </DialogOverlay>
  );
};

export default MaterialDialog;
