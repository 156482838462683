import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';

// Material to color mapping - keep synced with PolylineTool and ContextPanel
const MATERIAL_COLORS = {
  'Mulch': '#8B4513',   // Brown
  'Pebbles': '#A9A9A9',  // Dark gray
  'Concrete': '#C0C0C0', // Silver
  'Turf': '#228B22',    // Forest green
  'Pavers': '#D2691E'    // Chocolate
};

export class MaterialCalculatorTool extends BaseTool {
  constructor(board) {
    super(board);
    this.toolMode = 'MATERIAL_CALCULATOR';
    this.resultsWindow = null;
    this.resultsHTMLContainer = null;
    this.documentClickHandler = null;
  }

  activate() {
    // When activated, immediately calculate material areas
    this.calculateMaterialAreas();
  }

  deactivate() {
    // Remove HTML container if it exists
    if (this.resultsHTMLContainer) {
      try {
        document.body.removeChild(this.resultsHTMLContainer);
      } catch (err) {
        console.warn('Error removing HTML container:', err);
      }
      this.resultsHTMLContainer = null;
      
      // Remove any document click handlers that might be lingering
      // This is a safety measure to ensure no event handlers are left behind
      if (this.documentClickHandler) {
        document.removeEventListener('click', this.documentClickHandler);
        this.documentClickHandler = null;
      }
    }
  }

  // Calculate total areas by material
  calculateMaterialAreas() {
    console.log('Starting material area calculation');
    
    // Get current page information
    const currentPageNumber = this.board?.fileReaderInfo?.currentPageNumber || 1;
    const documentName = this.board?.fileReaderInfo?.file?.name || 'Whiteboard';
    console.log(`Calculating materials for page ${currentPageNumber} of document "${documentName}"`);
    
    // First, ensure all polygons have proper visibility set for the current page
    // This is critical for multi-page PDFs to only show/calculate polygons from current page
    const polylineTool = this.board.toolFactory?.getTool('POLYLINE');
    if (polylineTool) {
      polylineTool.filterInfoWindowsByPage(currentPageNumber, documentName);
    } else {
      console.warn('PolylineTool not found - cannot filter by page');
    }
    
    // Find all VISIBLE polyline objects on the canvas (critical to only process visible ones)
    const allObjects = this.canvas.getObjects();
    console.log('Found', allObjects.length, 'total objects on canvas');
    
    // STRICT PAGE FILTERING: Only include polylines that:
    // 1. Are of the correct type (polyline/polygon)
    // 2. Are currently visible
    // 3. MUST belong to the current page - no exceptions
    const polylines = allObjects.filter(obj => {
      // Check if it's a polyline or polygon
      const isPolylineType = (obj.polyline === true || obj.type === 'polygon');
      
      // Must be visible
      const isVisible = obj.visible !== false;
      
      // CRITICAL: Must have matching page and document info
      // If page info is missing, it doesn't belong to any page
      const hasPageInfo = obj.pageNumber !== undefined && obj.documentName !== undefined;
      const isCurrentPage = hasPageInfo && 
                           obj.pageNumber === currentPageNumber && 
                           obj.documentName === documentName;
      
      return isPolylineType && isVisible && isCurrentPage;
    });
    
    console.log('Found', polylines.length, 'visible polyline objects for this page');
    
    // IMPORTANT: Log all materials before calculation to verify they're correct
    console.log('Materials before calculation:');
    polylines.forEach(polygon => {
      console.log(`Polygon ${polygon.id || 'unknown'}: Material = ${polygon.material || 'none'}, Fill = ${polygon.fill}`);
    });
    
    // NEVER modify existing materials, only log warnings for polylines without material
    polylines.forEach(polygon => {
      if (!polygon.material) {
        console.warn(`WARNING: Polygon ${polygon.id || 'unknown'} has no material property but is visible.`);
      }
    });
    
    // Log detailed information about each polygon
    polylines.forEach(obj => {
      console.log(`Processing polyline: type=${obj.type}, material=${obj.material || 'none'}, ` + 
                 `area=${obj.area || 0}, id=${obj.id || 'unknown'}, page=${obj.pageNumber}`);
    });
    
    if (polylines.length === 0) {
      this.showAlert('No polylines found on this page. Draw polylines with materials first.');
      return;
    }
    
    // Use ft² (square feet) for all area measurements
    const areaUnit = 'ft²';
    
    // Calculate total area by material
    const materialAreas = {};
    const totalArea = { value: 0, unit: areaUnit };
    
    polylines.forEach(polyline => {
      // Skip objects without area
      if (polyline.area === undefined) {
        console.log('Skipping polyline without area:', polyline.id || 'unknown');
        return;
      }
      
      // CRITICAL: Always use the actual material property, never default or override
      // This prevents the incorrect material calculation issue
      if (!polyline.material) {
        console.warn(`Skipping polyline with no material (id: ${polyline.id || 'unknown'})`);
        return;
      }
      
      const material = polyline.material; // Never default this to 'Mulch'
      const area = polyline.area;
      
      console.log(`Adding polyline with material: ${material}, area: ${area}, fill: ${polyline.fill}`);
      
      // Initialize material entry if it doesn't exist
      if (!materialAreas[material]) {
        // Use the polyline's fill color or find it in the MATERIAL_COLORS mapping
        const materialColor = polyline.fill || MATERIAL_COLORS[material] || '#cccccc';
        materialAreas[material] = { value: 0, unit: areaUnit, color: materialColor };
      }
      
      // Add to material total
      materialAreas[material].value += area;
      
      // Add to overall total
      totalArea.value += area;
    });
    
    // Log the results for debugging
    console.log('Material calculation complete:', materialAreas, 'Total:', totalArea);
    
    // Display results
    this.showResults(materialAreas, totalArea);
  }
  
  // Display a results window on the canvas
  showResults(materialAreas, totalArea) {
    console.log('Showing material calculation results:', materialAreas, totalArea);
    
    // Remove previous window
    if (this.resultsWindow) {
      this.canvas.remove(this.resultsWindow);
      if (this.resultsHTMLContainer) {
        document.body.removeChild(this.resultsHTMLContainer);
        this.resultsHTMLContainer = null;
      }
    }
    
    const canvas = this.canvas;
    const materialNames = Object.keys(materialAreas);
    
    if (materialNames.length === 0) {
      this.showAlert('No materials found with area measurements.');
      return;
    }
    
    // Clear canvas selection before showing results
    // Store current selection for later if needed
    this.previousSelection = canvas.getActiveObjects();
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    
    // Create a container for the HTML results
    const container = document.createElement('div');
    container.style.position = 'fixed'; // Fixed position instead of absolute for better viewport positioning
    container.style.zIndex = '10000';
    container.style.background = 'white';
    container.style.padding = '20px';
    container.style.borderRadius = '8px';
    container.style.boxShadow = '0 4px 12px rgba(0,0,0,0.15)';
    container.style.width = '350px';
    container.style.maxWidth = '90vw';
    container.style.maxHeight = '80vh';
    container.style.overflowY = 'auto';
    container.style.pointerEvents = 'auto';
    container.style.border = '1px solid #e0e0e0';
    container.style.fontFamily = 'Arial, sans-serif';
    
    // Create a header container for better styling and close button layout
    const headerContainer = document.createElement('div');
    headerContainer.style.display = 'flex';
    headerContainer.style.justifyContent = 'space-between';
    headerContainer.style.alignItems = 'center';
    headerContainer.style.marginBottom = '15px';
    headerContainer.style.borderBottom = '1px solid #eee';
    headerContainer.style.paddingBottom = '8px';
    container.appendChild(headerContainer);
    
    // Create the header
    const header = document.createElement('h3');
    header.style.margin = '0';
    header.style.fontWeight = '600';
    header.style.color = '#333';
    header.textContent = 'Material Area Calculation';
    headerContainer.appendChild(header);
    
    // Create a close button
    const closeButton = document.createElement('button');
    closeButton.textContent = '×';
    closeButton.style.background = 'none';
    closeButton.style.border = 'none';
    closeButton.style.fontSize = '20px';
    closeButton.style.fontWeight = 'bold';
    closeButton.style.cursor = 'pointer';
    closeButton.style.color = '#666';
    closeButton.style.padding = '5px 10px';
    closeButton.style.borderRadius = '4px';
    closeButton.title = 'Close';
    closeButton.onmouseover = () => { closeButton.style.backgroundColor = '#f0f0f0'; };
    closeButton.onmouseout = () => { closeButton.style.backgroundColor = 'transparent'; };
    headerContainer.appendChild(closeButton);
    
    // Create the table
    const table = document.createElement('table');
    table.style.width = '100%';
    table.style.borderCollapse = 'collapse';
    
    // Create header row
    const thead = document.createElement('thead');
    const headerRow = document.createElement('tr');
    
    const materialHeader = document.createElement('th');
    materialHeader.style.textAlign = 'left';
    materialHeader.style.padding = '5px';
    materialHeader.style.borderBottom = '1px solid #eee';
    materialHeader.textContent = 'Material';
    
    const areaHeader = document.createElement('th');
    areaHeader.style.textAlign = 'right';
    areaHeader.style.padding = '5px';
    areaHeader.style.borderBottom = '1px solid #eee';
    areaHeader.textContent = 'Area';
    
    const colorHeader = document.createElement('th');
    colorHeader.style.textAlign = 'center';
    colorHeader.style.padding = '5px';
    colorHeader.style.borderBottom = '1px solid #eee';
    colorHeader.textContent = 'Color';
    
    headerRow.appendChild(materialHeader);
    headerRow.appendChild(areaHeader);
    headerRow.appendChild(colorHeader);
    thead.appendChild(headerRow);
    table.appendChild(thead);
    
    // Create table body
    const tbody = document.createElement('tbody');
    
    // Add rows for each material
    materialNames.forEach(name => {
      const material = materialAreas[name];
      // Format area with 2 decimal places in square feet
      const formattedArea = material.value.toFixed(2);
      
      const row = document.createElement('tr');
      
      const materialCell = document.createElement('td');
      materialCell.style.padding = '5px';
      materialCell.style.borderBottom = '1px solid #eee';
      materialCell.textContent = name;
      
      const areaCell = document.createElement('td');
      areaCell.style.textAlign = 'right';
      areaCell.style.padding = '5px';
      areaCell.style.borderBottom = '1px solid #eee';
      areaCell.textContent = `${formattedArea} ${material.unit}`;
      
      const colorCell = document.createElement('td');
      colorCell.style.textAlign = 'center';
      colorCell.style.padding = '5px';
      colorCell.style.borderBottom = '1px solid #eee';
      
      const colorDiv = document.createElement('div');
      colorDiv.style.width = '20px';
      colorDiv.style.height = '20px';
      colorDiv.style.backgroundColor = material.color || '#ccc';
      colorDiv.style.borderRadius = '3px';
      colorDiv.style.margin = '0 auto';
      
      colorCell.appendChild(colorDiv);
      
      row.appendChild(materialCell);
      row.appendChild(areaCell);
      row.appendChild(colorCell);
      
      tbody.appendChild(row);
    });
    
    // Add total row
    const totalRow = document.createElement('tr');
    
    const totalLabelCell = document.createElement('td');
    totalLabelCell.style.padding = '5px';
    totalLabelCell.style.fontWeight = 'bold';
    totalLabelCell.textContent = 'Total';
    
    const totalValueCell = document.createElement('td');
    totalValueCell.style.textAlign = 'right';
    totalValueCell.style.padding = '5px';
    totalValueCell.style.fontWeight = 'bold';
    totalValueCell.textContent = `${totalArea.value.toFixed(2)} ${totalArea.unit}`;
    
    const emptyCell = document.createElement('td');
    
    totalRow.appendChild(totalLabelCell);
    totalRow.appendChild(totalValueCell);
    totalRow.appendChild(emptyCell);
    
    tbody.appendChild(totalRow);
    table.appendChild(tbody);
    container.appendChild(table);
    
    // Define a shared close handler function
    const closeResultsWindow = (e) => {
      // Remove the HTML container
      if (container && container.parentNode) {
        document.body.removeChild(container);
      }
      this.resultsHTMLContainer = null;
      
      // Switch back to SELECT mode
      if (this.board.setMode) {
        this.board.setMode('SELECT');
      }
      
      // Prevent event propagation
      if (e) e.stopPropagation();
    };
    
    // Add close event listener to the close button
    closeButton.addEventListener('click', closeResultsWindow);
    
    // Add a click handler to the container itself to prevent clicks from affecting the canvas
    container.addEventListener('click', (e) => {
      e.stopPropagation();
    });
    
    // Create a document-level click listener to close the results window when clicking outside it
    // Store it as a class property so it can be cleaned up in deactivate()
    this.documentClickHandler = (e) => {
      if (!container.contains(e.target)) {
        closeResultsWindow();
        document.removeEventListener('click', this.documentClickHandler);
        this.documentClickHandler = null;
      }
    };
    
    // Add the handler after a slight delay to prevent immediate triggering
    setTimeout(() => {
      document.addEventListener('click', this.documentClickHandler);
    }, 100);
    
    // Add container to the document body
    document.body.appendChild(container);
    this.resultsHTMLContainer = container;
    
    // Position the HTML container in the center of the viewport
    const canvasEl = canvas.getElement();
    const canvasRect = canvasEl.getBoundingClientRect();
    
    // Calculate position to center the popup
    const containerWidth = 300; // Expected width of container
    const containerHeight = 350; // Expected max height of container
    
    // Position in the viewport center
    const left = canvasRect.left + (canvasRect.width - containerWidth) / 2;
    const top = canvasRect.top + (canvasRect.height - containerHeight) / 2;
    
    // Apply calculated position
    container.style.left = `${left}px`;
    container.style.top = `${top}px`;
    
    // Set a reference to track this container
    this.resultsHTMLContainer = container;
    this.resultsWindow = null; // No canvas element needed
  }
  
  // Show alert message
  showAlert(message) {
    alert(message);
    // Switch back to SELECT mode
    if (this.board.setMode) {
      this.board.setMode('SELECT');
    }
  }
}
