import { fabric } from 'fabric';

export class BaseTool {
  constructor(board) {
    this.board = board;
    this.canvas = board.canvas;
    this.drawingSettings = board.drawingSettings;
    this.mouseDown = false;
    this.drawInstance = null;
    this.toolMode = null; // Subclasses should set this to the appropriate mode
    
    // Bind touch event handlers so they can be properly removed later
    this._preventDefaultTouchMove = this._preventDefaultTouchMove.bind(this);
    
    // Flag to identify if this is the pan tool - set by subclasses
    this.isPanTool = false;
  }

  // Common methods for all tools
  activate() {
    // If this is not the pan tool, add touch event prevention
    if (!this.isPanTool && this.canvas.upperCanvasEl) {
      // Add touch event prevention to stop browser default behaviors
      this.canvas.upperCanvasEl.addEventListener('touchmove', this._preventDefaultTouchMove, { passive: false });
    }
    
    // Override in subclasses for additional activation behavior
  }

  deactivate() {
    // Remove the touch event listener if it was added
    if (!this.isPanTool && this.canvas.upperCanvasEl) {
      this.canvas.upperCanvasEl.removeEventListener('touchmove', this._preventDefaultTouchMove);
    }
    
    this.removeCanvasListeners();
  }
  
  // Prevent default touch move behavior for non-pan tools
  _preventDefaultTouchMove(evt) {
    // Only prevent default if this isn't the pan tool
    if (!this.isPanTool) {
      evt.preventDefault();
    }
  }

  removeCanvasListeners() {
    // Remove mouse events
    this.canvas.off('mouse:down');
    this.canvas.off('mouse:move');
    this.canvas.off('mouse:up');
    this.canvas.off('mouse:over');
    
    // Remove touch events
    this.canvas.off('touchstart');
    this.canvas.off('touchmove');
    this.canvas.off('touchend');
  }

  stopDrawing() {
    this.mouseDown = false;
  }
  
  // Check if this tool is the active one based on the current mode
  isActive() {
    if (!this.toolMode) {
      console.warn('Tool mode not set for this tool');
      return false;
    }
    return this.board._currentMode === this.toolMode;
  }
  
  // Helper method for calculating distance between two points
  calculateDistance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }
}
