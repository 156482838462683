import React, { useState, useEffect } from 'react'
import { stampObjects } from './tools/StampTool'
import styled from 'styled-components'
import { CustomStampCreator } from './CustomStampCreator'
import { cleanPlantName } from './tools/StampTool'

// Group objects by category
const groupedObjects = Object.entries(stampObjects).reduce((acc, [name, obj]) => {
  const category = name.includes('Tree') || name.includes('Bush') || name.includes('Shrub')
    ? 'Plants'
    : 'Furniture'
  
  if (!acc[category]) acc[category] = []
  acc[category].push([name, obj])
  return acc
}, {})

const PaletteContainer = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 240px;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const PaletteHeader = styled.div`
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CreateButton = styled.button`
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: #2563eb;
  }
`

const CustomStampPreview = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  color: white;
  
  ${props => props.shape === 'circle' && `
    border-radius: 50%;
    background-color: ${props.color};
  `}
  
  ${props => props.shape === 'rectangle' && `
    border-radius: 2px;
    background-color: ${props.color};
  `}
`

const PaletteContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
`

const CategoryContainer = styled.div`
  margin-bottom: 12px;
`

const CategoryLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #666;
  margin-bottom: 4px;
  padding: 0 4px;
`

const SubCategoryLabel = styled.div`
  font-size: 11px;
  font-weight: 400;
  color: #888;
  margin-bottom: 4px;
  padding: 0 4px;
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 8px 0;
`

const StampButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  border: none;
  background-color: ${props => props.selected ? '#e6f7ff' : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  text-align: left;
  
  &:hover {
    background-color: ${props => props.selected ? '#e6f7ff' : '#f5f5f5'};
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
`

const StampLabel = styled.span`
  font-size: 13px;
  color: #333;
`

function StampPalette({ onSelectStamp, selectedStamp, board }) {
  const [showCreator, setShowCreator] = useState(false)
  // State for custom stamps - retrieve from localStorage if available
  const [customStamps, setCustomStamps] = useState({})
  // State for scene plants
  const [scenePlants, setScenePlants] = useState([])
  
  // Load custom stamps from localStorage on component mount
  useEffect(() => {
    try {
      const savedStamps = localStorage.getItem('customStamps')
      if (savedStamps) {
        setCustomStamps(JSON.parse(savedStamps))
      }
    } catch (error) {
      console.error('Error loading custom stamps:', error)
    }
  }, [])
  
  // Save custom stamps to localStorage when they change
  useEffect(() => {
    if (Object.keys(customStamps).length > 0) {
      localStorage.setItem('customStamps', JSON.stringify(customStamps))
    }
  }, [customStamps])
  
  // Load plants from SceneLoader when board is available
  useEffect(() => {
    const loadPlantsFromScene = () => {
      if (!board || !board.sceneLoader) return
      
      // Get plants from the scene loader
      const plants = board.sceneLoader.plants || []
      
      // Map plants to a format compatible with stamps
      const plantStamps = plants.map(plant => {
        // Use cleanedName as stamp name (match what we use in render)
        const originalName = plant.name || plant.stampName || 'Unknown Plant';
        let cleanedName = plant.name;
        
        // Generate plant symbol (for preview) - ALWAYS 3 characters
        let plantSymbol = '';
        if (cleanedName && typeof cleanedName === 'string') {
          const words = cleanedName.split(' ');
          if (words.length >= 3) {
            // If three or more words, take first letter of first three words
            plantSymbol = words.slice(0, 3).map(word => word.charAt(0)).join('').toUpperCase();
          } else if (words.length === 2) {
            // For two words, take first letter of first word and first two letters of second word
            plantSymbol = (words[0].charAt(0) + words[1].substring(0, 2)).toUpperCase();
          } else {
            // For single word, take first three letters
            const letters = cleanedName.replace(/[^a-zA-Z]/g, '');
            plantSymbol = letters.substring(0, 3).padEnd(3, 'X').toUpperCase();
          }
        }
        
        // Ensure symbol is exactly 3 characters
        if (!plantSymbol || plantSymbol.length !== 3) {
          plantSymbol = 'PLT';
        }
        
        // Create a stamp definition object
        return {
          originalName,
          plantName: cleanedName,
          name: cleanedName,
          isPlant: true,
          isCustomStamp: true,
          color: plant.color || '#4CAF50', // Use plant color if available
          fill: plant.color || '#4CAF50', // Green color for plants
          shape: 'circle',
          symbol: plantSymbol, // Store the generated symbol
          originalData: plant,
          metadata: {
            type: 'plant',
            plantId: plant.id,
            name: cleanedName
          }
        }
      })
      
      // Deduplicate plants by name (use Map with plant name as key)
      const uniquePlants = new Map()
      plantStamps.forEach(plant => {
        if (!uniquePlants.has(plant.name)) {
          uniquePlants.set(plant.name, plant)
        }
      })
      
      // Convert map values to array
      setScenePlants(Array.from(uniquePlants.values()))
    }
    
    // Load plants initially
    loadPlantsFromScene()
    
    // Add event listener for when plants are loaded/rendered
    const handlePlantsRendered = () => {
      loadPlantsFromScene()
    }
    
    if (board?.canvas) {
      board.canvas.on('plants:rendered', handlePlantsRendered)
      
      // Also listen for scene:loaded event
      board.canvas.on('scene:loaded', handlePlantsRendered)
    }
    
    return () => {
      // Clean up listeners when component unmounts
      if (board?.canvas) {
        board.canvas.off('plants:rendered', handlePlantsRendered)
        board.canvas.off('scene:loaded', handlePlantsRendered)
      }
    }
  }, [board])
  
  // Handler for creating a new custom stamp
  const handleCreateStamp = (newStamp) => {
    const updatedStamps = {
      ...customStamps,
      [newStamp.name]: newStamp
    }
    setCustomStamps(updatedStamps)
    // Automatically select the newly created stamp
    onSelectStamp(newStamp.name, newStamp)
  }
  
  return (
    <>
      <PaletteContainer>
        <PaletteHeader>
          Object Library
          <CreateButton onClick={() => setShowCreator(true)}>
            + Custom
          </CreateButton>
        </PaletteHeader>
        <PaletteContent>
          {/* Built-in stamps */}
          {Object.entries(groupedObjects).map(([category, items]) => (
            <CategoryContainer key={category}>
              <CategoryLabel>{category}</CategoryLabel>
              {items.map(([name, obj]) => (
                <StampButton
                  key={name}
                  selected={selectedStamp === name}
                  onClick={() => onSelectStamp(name)}
                >
                  <IconContainer>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path 
                        d={obj.path} 
                        fill={obj.fill}
                        stroke={selectedStamp === name ? 'rgba(0,0,0,0.2)' : 'none'}
                        strokeWidth="0.5"
                      />
                    </svg>
                  </IconContainer>
                  <StampLabel>{name}</StampLabel>
                </StampButton>
              ))}
              <Divider />
            </CategoryContainer>
          ))}
          
          {/* Scene plants and custom objects in a single section */}
          {(scenePlants.length > 0 || Object.keys(customStamps).length > 0) && (
            <CategoryContainer>
              <CategoryLabel>Custom Objects</CategoryLabel>
              
              {/* Scene plants */}
              {scenePlants.length > 0 && (
                <>
                  <SubCategoryLabel>Plants</SubCategoryLabel>
                  {scenePlants.map((plant) => (
                    <StampButton
                      key={`plant-${plant.name}`}
                      selected={selectedStamp === plant.name}
                      onClick={() => {
                        console.log('Selecting plant stamp:', plant.name, plant)
                        onSelectStamp(plant.name, plant)
                      }}
                    >
                      <IconContainer>
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <circle cx="12" cy="12" r="10" fill="rgba(76, 175, 80, 0.2)" stroke="#4CAF50" strokeWidth="2" />
                          <text
                            x="12"
                            y="16"
                            fontSize="10"
                            fontWeight="bold"
                            fill={plant.color || plant.fill || "#4CAF50"}
                            textAnchor="middle"
                          >
                            {plant.symbol || 'PLT'}
                          </text>
                        </svg>
                      </IconContainer>
                      <StampLabel>{plant.name}</StampLabel>
                    </StampButton>
                  ))}
                </>
              )}
              
              {/* User-created custom stamps */}
              {Object.keys(customStamps).length > 0 && (
                <>
                  {scenePlants.length > 0 && <SubCategoryLabel>User Objects</SubCategoryLabel>}
                  {Object.entries(customStamps).map(([name, stamp]) => (
                    <StampButton
                      key={name}
                      selected={selectedStamp === name}
                      onClick={() => {
                        console.log('Selecting custom stamp:', name, stamp)
                        onSelectStamp(name, stamp)
                      }}
                    >
                      <IconContainer>
                        {stamp.isCustom ? (
                          <CustomStampPreview 
                            shape={stamp.shape} 
                            color={stamp.fill}
                            text={stamp.text}
                          >
                            {stamp.text}
                          </CustomStampPreview>
                        ) : (
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path d={stamp.path} fill={stamp.fill} />
                          </svg>
                        )}
                      </IconContainer>
                      <StampLabel>{name}</StampLabel>
                    </StampButton>
                  ))}
                </>
              )}
              <Divider />
            </CategoryContainer>
          )}
        </PaletteContent>
      </PaletteContainer>
      
      {/* Custom Stamp Creator */}
      {showCreator && (
        <CustomStampCreator 
          onClose={() => setShowCreator(false)}
          onCreateStamp={handleCreateStamp}
        />
      )}
    </>
  )
}

export { StampPalette }
