import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

// Material to color mapping
export const MATERIAL_COLORS = {
  'Mulch': '#8B4513',   // Brown
  'Pebbles': '#A9A9A9',  // Dark gray
  'Concrete': '#C0C0C0', // Silver
  'Turf': '#228B22',    // Forest green
  'Pavers': '#D2691E'    // Chocolate
};

// Use a consistent pattern to retrieve material objects by color
// This is critical for finding the correct material when only color is preserved
function getMaterialByColor(color) {
  for (const [material, materialColor] of Object.entries(MATERIAL_COLORS)) {
    if (materialColor === color) {
      return material;
    }
  }
  return null;
}

export class PolylineTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('pencil');
    this.points = [];
    this.lines = [];
    this.isDrawing = false;
    this.isDrawingComplete = false;
    this.doubleClickTimeout = null;
    this.finishDistance = 20; // Distance in pixels to connect to first point
    this.toolMode = 'POLYLINE';
    // Ensure no info window properties exist
    this.infoWindow = null;
    this.previewPolygon = null;
  }

  activate() {
    const canvas = this.canvas;

    // Check if scale has been set
    if (!canvas.scaleRatio) {
      alert('Please set a scale first using the Scale tool.');
      // Switch to SELECT mode
      if (this.board.setMode) {
        this.board.setMode('SELECT');
      }
      return;
    }

    canvas.on('mouse:down', this.onMouseDown.bind(this));
    canvas.on('mouse:move', this.onMouseMove.bind(this));
    canvas.on('mouse:dblclick', this.onDoubleClick.bind(this));

    canvas.selection = false;
    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.isDrawingMode = false;
    
    // Make all objects non-selectable when the polyline tool is active
    canvas.getObjects().forEach((item) => {
      // Only make non-polyline objects non-selectable
      if (!item.polyline) {
        item.set({ selectable: false });
      }
      
      // Attach handlers for polygons
      if (item.type === 'polygon' && !item._movingHandlerAttached) {
        // Add scaling event handler
        item.on('scaling', () => {
          const { area, perimeter } = this.calculateMeasurements(item);
          if (area !== undefined && perimeter !== undefined) {
            item.set({
              area: area,
              perimeter: perimeter
            });
            // Ensure the item stays selected
            if (this.canvas.getActiveObject() !== item) {
              this.canvas.setActiveObject(item);
            }
            this.canvas.requestRenderAll();
          }
        });
        
        item.on('modified', () => {
          const { area, perimeter } = this.calculateMeasurements(item);
          if (area !== undefined && perimeter !== undefined) {
            item.set({
              area: area,
              perimeter: perimeter
            });
            // Ensure the item stays selected
            if (this.canvas.getActiveObject() !== item) {
              this.canvas.setActiveObject(item);
            }
            this.canvas.requestRenderAll();
          }
        });
        
        item._movingHandlerAttached = true;
      }
    });
    
    canvas.discardActiveObject().requestRenderAll();
    
    // Subscribe to selection events to handle multiple polylines selection
    this.boundOnSelectionChange = this.onSelectionChange.bind(this);
    this.boundOnSelectionCleared = this.onSelectionCleared.bind(this);
    
    canvas.on('selection:created', this.boundOnSelectionChange);
    canvas.on('selection:updated', this.boundOnSelectionChange);
    canvas.on('selection:cleared', this.boundOnSelectionCleared);
    
    // No need for any info window functionality
  }

  deactivate() {
    super.deactivate();
    
    // Remove the mouse:dblclick listener
    this.canvas.off('mouse:dblclick', this.onDoubleClick.bind(this));
    
    // Remove event listeners with proper references
    if (this.boundOnSelectionChange) {
      this.canvas.off('selection:created', this.boundOnSelectionChange);
      this.canvas.off('selection:updated', this.boundOnSelectionChange);
    }
    
    if (this.boundOnSelectionCleared) {
      this.canvas.off('selection:cleared', this.boundOnSelectionCleared);
    }
    
    // Clean up any in-progress drawing
    if (this.tempLine) {
      this.canvas.remove(this.tempLine);
      this.tempLine = null;
    }
    
    // If there's an incomplete polyline, clean it up
    if (this.isDrawing && !this.isDrawingComplete) {
      this.points.forEach(point => {
        if (point.marker) {
          this.canvas.remove(point.marker);
        }
      });
      
      this.lines.forEach(line => {
        this.canvas.remove(line);
      });
      
      this.canvas.requestRenderAll();
    }
    
    // If we were in the middle of drawing, re-enable snapshots
    if (this.board.history && this.board.history._disableSnapshots) {
      this.board.history._disableSnapshots = false;
      console.log('Re-enabling snapshots on polyline tool deactivation');
    }
    
    this.resetDrawing();
  }
  
  /**
   * Filter polygons to show only those that belong to the current page
   * Also updates measurement calculations for visible polygons to ensure accurate display
   * @param {number} pageNumber - The current page number
   * @param {string} documentName - The current document name
   */
  filterInfoWindowsByPage(pageNumber, documentName) {
    const canvas = this.canvas;
    if (!canvas) return;
    
    // Get all polygons
    const polygons = canvas.getObjects().filter(obj => {
      return obj.type === 'polygon';
    });
    
    console.log(`Filtering polygons for page ${pageNumber} of ${documentName}`);
    console.log(`Found ${polygons.length} total polygons`);
    
    // STRICT PAGE SEPARATION: For each polygon, we need to:
    // 1. Set page and document info if missing (this assigns it to the current page)
    // 2. Set visibility based on matching current page exactly
    // 3. Never allow polygons to appear on multiple pages
    polygons.forEach(polygon => {
      // If polygon doesn't have page info, assign it to current page
      if (polygon.pageNumber === undefined || polygon.documentName === undefined) {
        polygon.set({
          pageNumber: pageNumber,
          documentName: documentName
        });
        console.log(`Assigned page ${pageNumber} to polygon without page info`);
      }
      
      // Strictly show only polygons belonging to this exact page
      const visible = polygon.pageNumber === pageNumber && 
                      polygon.documentName === documentName;
      
      console.log(`Polygon ${polygon.id || 'unknown'}: page=${polygon.pageNumber}, ` +
                  `doc=${polygon.documentName}, setting visible=${visible}`);
      
      // Set visibility
      polygon.set({ visible: visible });
      
      // Process visible polygons
      if (visible) {
        // Update scale ratio if needed
        if (canvas.scaleRatio && polygon.scaleRatio !== canvas.scaleRatio) {
          polygon.scaleRatio = canvas.scaleRatio;
          
          // Recalculate measurements with current canvas scale
          const { area, perimeter } = this.calculateMeasurements(polygon);
          polygon.set({
            area: area,
            perimeter: perimeter
          });
        }
        
        // CRITICAL: Only set default material if completely missing
        // NEVER override an existing material
        if (!polygon.material) {
          console.log(`Setting default material for polygon ${polygon.id || 'unknown'}`);
          polygon.set({
            material: 'Mulch',  // Default material
            fill: MATERIAL_COLORS['Mulch']
          });
        } else {
          // Ensure fill color matches the material (important for visual consistency)
          const materialColor = MATERIAL_COLORS[polygon.material];
          if (materialColor && polygon.fill !== materialColor) {
            console.log(`Fixing fill color for material ${polygon.material}`);
            polygon.set({
              fill: materialColor
            });
          }
        }
      }
    });
    
    canvas.requestRenderAll();
  }

  resetDrawing() {
    // If we were in the middle of drawing and now resetting,
    // we need to re-enable snapshots
    if (this.isDrawing && !this.isDrawingComplete && this.board.history) {
      this.board.history._disableSnapshots = false;
    }

    this.points = [];
    this.lines = [];
    this.isDrawing = false;
    this.isDrawingComplete = false;
    
    if (this.tempLine) {
      this.canvas.remove(this.tempLine);
      this.tempLine = null;
    }
  }

  onMouseDown(e) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      console.warn('PolylineTool.onMouseDown called but tool is not active, current mode:', this.board._currentMode);
      return;
    }
    
    // Prevent object selection during polyline drawing
    if (e.target && e.target.type === 'polygon') {
      // If we clicked on a polygon, prevent it from being selected
      this.canvas.discardActiveObject();
      
      // But don't stop the event propagation or return false,
      // so we can still place points on top of existing polygons
    }
    
    const pointer = this.canvas.getPointer(e.e);
    
    // If we're close to the first point and have at least 3 points, complete the polyline
    if (this.points.length >= 3 && this.isNearFirstPoint(pointer)) {
      this.completePolyline();
      return;
    }
    
    // If there are no points yet, this is the start of a new polyline
    // Disable snapshots right from the beginning before any canvas modifications
    if (this.points.length === 0 && this.board.history) {
      this.board.history._disableSnapshots = true;
      
      if (process.env.NODE_ENV !== 'production') {
        console.log('Disabling snapshots at the start of polyline drawing');
      }
    }
    
    // Add a point marker at the clicked position
    const point = new fabric.Circle({
      left: pointer.x,
      top: pointer.y,
      radius: 5,
      fill: this.drawingSettings.currentColor,
      stroke: 'white',
      strokeWidth: 1,
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasControls: false,
    });
    
    this.canvas.add(point);
    
    // Add the point to our points array
    this.points.push({
      x: pointer.x,
      y: pointer.y,
      marker: point
    });
    
    // If this is not the first point, create a line from the previous point
    if (this.points.length > 1) {
      const prevPoint = this.points[this.points.length - 2];
      const line = new fabric.Line(
        [prevPoint.x, prevPoint.y, pointer.x, pointer.y],
        {
          stroke: this.drawingSettings.currentColor,
          strokeWidth: this.drawingSettings.brushWidth,
          selectable: false,
        }
      );
      
      this.canvas.add(line);
      this.lines.push(line);
    }
    
    // If this is the first point, start drawing
    if (this.points.length === 1) {
      this.isDrawing = true;
      this.isDrawingComplete = false;
      
      // Create a temporary line that follows the mouse
      this.tempLine = new fabric.Line(
        [pointer.x, pointer.y, pointer.x, pointer.y],
        {
          stroke: this.drawingSettings.currentColor,
          strokeWidth: this.drawingSettings.brushWidth,
          strokeDashArray: [5, 5],
          selectable: false,
        }
      );
      
      this.canvas.add(this.tempLine);
    }
    
    this.canvas.requestRenderAll();
  }

  onMouseMove(e) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      return;
    }
    
    if (!this.isDrawing || this.points.length === 0) return;
    
    const pointer = this.canvas.getPointer(e.e);
    
    // Update the temporary line to follow the mouse
    if (this.tempLine) {
      const lastPoint = this.points[this.points.length - 1];
      this.tempLine.set({
        x1: lastPoint.x,
        y1: lastPoint.y,
        x2: pointer.x,
        y2: pointer.y
      });
      
      // If we're close to the first point, highlight it
      if (this.points.length >= 3 && this.isNearFirstPoint(pointer)) {
        this.points[0].marker.set({
          radius: 8,
          fill: 'red',
        });
      } else if (this.points.length >= 3) {
        this.points[0].marker.set({
          radius: 5,
          fill: this.drawingSettings.currentColor,
        });
      }
      
      this.canvas.requestRenderAll();
    }
  }

  onDoubleClick() {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      return;
    }
    
    if (this.points.length < 3) {
      alert('A polygon must have at least 3 points. Please continue drawing or start over.');
      return;
    }
    
    this.completePolyline();
  }

  isNearFirstPoint(pointer) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      return false;
    }
    
    if (this.points.length === 0) return false;
    
    const firstPoint = this.points[0];
    const distance = Math.sqrt(
      Math.pow(pointer.x - firstPoint.x, 2) + 
      Math.pow(pointer.y - firstPoint.y, 2)
    );
    
    return distance < this.finishDistance;
  }

  completePolyline() {
    if (this.points.length < 3) return;
    
    // Mark that we're completing the drawing
    this.isDrawingComplete = true;
    
    // Disable automatic history snapshots during the completion process
    if (this.board.history) {
      this.board.history._disableSnapshots = true;
    }
    
    try {
      // Close the polyline by connecting the last point to the first
      const firstPoint = this.points[0];
      const lastPoint = this.points[this.points.length - 1];
      
      const closingLine = new fabric.Line(
        [lastPoint.x, lastPoint.y, firstPoint.x, firstPoint.y],
        {
          stroke: this.drawingSettings.currentColor,
          strokeWidth: this.drawingSettings.brushWidth,
          selectable: false,
        }
      );
      
      this.canvas.add(closingLine);
      this.lines.push(closingLine);
      
      // Create polygon points array for fabric
      const polygonPoints = this.points.map(p => ({
        x: p.x,
        y: p.y
      }));
      
      // Create a polygon from all the points
      const polygon = new fabric.Polygon(polygonPoints, {
        fill: 'rgba(0, 0, 255, 0.1)',
        stroke: this.drawingSettings.currentColor,
        strokeWidth: this.drawingSettings.brushWidth,
        objectCaching: false,
        selectable: false, // Not selectable when using polyline tool
        transparentCorners: false,
        cornerColor: 'rgba(0, 0, 255, 0.5)',
        lockScalingX: false,
        lockScalingY: false,
        hasControls: true,
        opacity: 0.5, // Set base opacity to 50%
      });
      
      // Calculate area and perimeter
      const { area, perimeter } = this.calculateMeasurements(polygon);
      
      // Get the current page number from the Board if available
      const currentPageNumber = this.board?.fileReaderInfo?.currentPageNumber || 1;
      const documentName = this.board?.fileReaderInfo?.file?.name || 'Whiteboard';
      
      // Default material
      const defaultMaterial = 'Mulch';
      
      // Store measurements on the polygon
      polygon.set({
        area: area,
        perimeter: perimeter,
        scaleRatio: this.canvas.scaleRatio,
        pageNumber: currentPageNumber,    // Store page number with the polygon
        documentName: documentName,       // Store document name with the polygon
        polyline: true,                   // Mark this as a polyline object
        tool: 'polyline',                 // Tool identifier for property panel
        material: defaultMaterial,        // Default material type
        fill: MATERIAL_COLORS[defaultMaterial], // Apply the color for the material
        opacity: 0.5                      // Always set default opacity to 50%
      })
      
      // Remove the temporary drawing elements
      this.points.forEach(p => this.canvas.remove(p.marker));
      this.lines.forEach(line => this.canvas.remove(line));
      if (this.tempLine) this.canvas.remove(this.tempLine);
      
      // Add the completed polygon to the canvas
      this.canvas.add(polygon);
      
      // Make the polygon selectable
      polygon.set({ selectable: true });
      
      // Create a unique id for this polygon
      polygon.id = 'polygon-' + Date.now();
      
      // Add an event for scaling
      polygon.on('scaling', () => {
        // Update measurements in real-time during scaling
        const { area, perimeter } = this.calculateMeasurements(polygon);
        polygon.set({
          area: area,
          perimeter: perimeter
        });
      });
      
      // Also handle modifications like scaling/rotating
      polygon.on('modified', () => {
        // Recalculate measurements after modification
        const { area, perimeter } = this.calculateMeasurements(polygon);
        if (area !== undefined && perimeter !== undefined) {
          polygon.set({
            area: area,
            perimeter: perimeter
          });
          // Ensure the polygon stays selected
          if (this.canvas.getActiveObject() !== polygon) {
            this.canvas.setActiveObject(polygon);
          }
          this.canvas.requestRenderAll();
        }
      });
      
      // Request a render to show all changes at once
      this.canvas.renderAll();
      
    } finally {
      // Re-enable snapshots after all objects have been added
      if (this.board.history) {
        this.board.history._disableSnapshots = false;
      }
      
      // Take a SINGLE snapshot for the entire process
      this.board.history.snapshot();
      
      // Finally, reset the drawing state
      this.resetDrawing();
    }
  }

  calculateMeasurements(polygon) {
    if (!polygon || !polygon.points || polygon.points.length < 3) {
      console.log('Cannot calculate - not enough points');
      return null;
    }
    
    try {
      // Get transformed points that take into account scaling, rotation, etc.
      const transformedPoints = this.getTransformedPoints(polygon);
      
      // Calculate area using the Shoelace formula with transformed points
      let pixelArea = 0;
      for (let i = 0; i < transformedPoints.length; i++) {
        const j = (i + 1) % transformedPoints.length;
        pixelArea += transformedPoints[i].x * transformedPoints[j].y;
        pixelArea -= transformedPoints[j].x * transformedPoints[i].y;
      }
      pixelArea = Math.abs(pixelArea / 2);
      
      // Calculate perimeter with transformed points
      let pixelPerimeter = 0;
      for (let i = 0; i < transformedPoints.length; i++) {
        const j = (i + 1) % transformedPoints.length;
        pixelPerimeter += Math.sqrt(
          Math.pow(transformedPoints[j].x - transformedPoints[i].x, 2) + 
          Math.pow(transformedPoints[j].y - transformedPoints[i].y, 2)
        );
      }
      
      // Get the scale ratio, use the one on the canvas (most current) or fallback to polygon's stored value
      // This ensures we're using the most up-to-date scale ratio
      const scaleRatio = this.canvas.scaleRatio || polygon.scaleRatio || 1;
      
      // Convert to real-world units using scale ratio
      // Prevent division by zero or invalid values
      const effectiveScaleRatio = scaleRatio > 0 ? scaleRatio : 1;
      
      // Calculate measurements directly in feet since scale is already in feet
      const area = pixelArea / (effectiveScaleRatio * effectiveScaleRatio);
      const perimeter = pixelPerimeter / effectiveScaleRatio;
      
      // Use feet as the unit
      const unit = 'ft²';
      
      // Store these values directly on the polygon for material calculator
      polygon.area = area;
      polygon.perimeter = perimeter;
      polygon.unit = unit;
      polygon.polyline = true; // Mark as polyline for detection
      
      // Ensure material property exists
      if (!polygon.material) {
        polygon.material = 'Mulch'; // Default material
      }
      
      return { area, perimeter, unit, unit_perimeter: 'ft' };
    } catch (error) {
      console.error('Error calculating measurements:', error);
      return null;
    }
  }
  
  /**
   * Gets the actual transformed points of a polygon, taking into account
   * scaling, rotation, skewing, etc.
   */
  getTransformedPoints(polygon) {
    if (!polygon || !polygon.points || !Array.isArray(polygon.points)) {
      console.warn('Invalid polygon object passed to getTransformedPoints');
      return [];
    }
    
    // If the polygon has no transforms applied, just use the points as is
    if (polygon.scaleX === 1 && 
        polygon.scaleY === 1 && 
        polygon.skewX === 0 && 
        polygon.skewY === 0 && 
        polygon.angle === 0) {
      return polygon.points.map(p => ({ x: p.x, y: p.y }));
    }
    
    try {
      // Get the actual transformed coordinates
      const transformedPoints = [];
      const matrix = polygon.calcTransformMatrix();
      
      // Apply the transformation matrix to each point
      polygon.points.forEach(point => {
        if (point && typeof point.x === 'number' && typeof point.y === 'number') {
          const transformedPoint = fabric.util.transformPoint({
            x: point.x, 
            y: point.y
          }, matrix);
          transformedPoints.push(transformedPoint);
        }
      });
      
      return transformedPoints;
    } catch (error) {
      console.error('Error transforming polygon points:', error);
      // Fallback to untransformed points
      return polygon.points.map(p => ({ x: p.x, y: p.y }));
    }
  }

  /**
   * Returns measurements for a polygon/polyline object
   * This method is called by the Board class when extracting object properties
   * @param {Object} object - The fabric.js object to get measurements for
   * @returns {Object} - An object containing measurement data
   */
  getMeasurementsForObject(object) {
    console.log('Getting measurements for polyline object:', object.id || 'unknown');
    
    try {
      // Check if we have a valid object
      if (!object) {
        console.warn('No valid object provided for measurements');
        return null;
      }
      
      // If the object already has cached measurements, return those
      if (object.area !== undefined && object.perimeter !== undefined) {
        console.log('Using cached measurements from object');
        return {
          area: object.area,
          perimeter: object.perimeter,
          unit: object.unit || 'm'
        };
      }
      
      // Otherwise calculate fresh measurements
      const { area, perimeter } = this.calculateMeasurements(object);
      
      // Cache measurements on the object for future use
      object.set({
        area: area,
        perimeter: perimeter,
        unit: 'm' // Default unit
      });
      
      console.log('Calculated measurements:', { area, perimeter });
      
      return {
        area: area,
        perimeter: perimeter,
        unit: 'm'
      };
    } catch (error) {
      console.error('Error calculating measurements:', error);
      return null;
    }
  }

  onSelectionChange(e) {
    // If no objects are selected, return
    if (!e || !e.selected || !e.selected.length) {
      return;
    }
    
    const activeSelection = e.selected;
    
    // Filter for polygon objects
    const polygons = activeSelection.filter(obj => 
      (obj.type === 'polygon' || obj.polyline === true) && obj.scaleRatio !== undefined
    );
    
    // Check if there are any group objects that might contain polylines
    const groups = activeSelection.filter(obj => obj.type === 'group');
    groups.forEach(group => {
      // Check if this group is a polyline group
      if (group._objects && group._objects.some(obj => obj.type === 'polygon')) {
        // Set polyline flag on the group for context menu visibility
        group.set({
          polyline: true,
          tool: 'polyline',
          opacity: group.opacity !== undefined ? group.opacity : 0.5
        });
        
        // Add this group to our polygons array for measurement
        if (!polygons.includes(group)) {
          polygons.push(group);
        }
      }
    });
    
    // If no polygons are selected, return
    if (polygons.length === 0) {
      return;
    }
    
    // Get the scale unit based on canvas setting
    const scaleUnit = this.canvas.scaleUnit || 'm';
    const areaUnit = scaleUnit + '²';
    
    // Make sure each polygon has the polyline property set
    polygons.forEach(polygon => {
      // Make sure the polyline property is set for proper context menu handling
      const currentMaterial = polygon.material || 'Mulch';
      
      // Get the material color - from the material's color mapping or use the existing fill color
      const materialColor = MATERIAL_COLORS[currentMaterial] || polygon.fill;
      
      polygon.set({
        polyline: true,
        tool: 'polyline',
        // Add material property if it doesn't exist yet
        material: currentMaterial,
        // Apply material color
        fill: materialColor,
        // Set the unit
        unit: areaUnit,
        // Set default opacity if not already set
        opacity: polygon.opacity !== undefined ? polygon.opacity : 0.5
      });
    });
    
    // Calculate measurements for each polygon (but don't display in info window)
    polygons.forEach(polygon => {
      // For individual polygons, calculate directly
      if (polygon.type === 'polygon') {
        // Recalculate measurements to ensure they're up to date
        const measurements = this.calculateMeasurements(polygon);
        if (measurements) {
          // Store the measurements on the polygon object itself
          const { area, perimeter } = measurements;
          polygon.set({
            area: area,
            perimeter: perimeter
          });
        }
      } 
      // For groups containing polygons, recalculate measurements
      else if (polygon.type === 'group' && polygon._objects) {
        polygon._objects.forEach(obj => {
          if (obj.type === 'polygon') {
            const measurements = this.calculateMeasurements(obj);
            if (measurements) {
              const { area, perimeter } = measurements;
              obj.set({
                area: area,
                perimeter: perimeter
              });
            }
          }
        });
      }
    });
    
    // No info window needed
  }

  onSelectionCleared() {
    // No info window to clear - no action needed
  }
}
