import { fabric } from 'fabric';
import { BaseTool } from './BaseTool';
import { getCursor } from '../cursors';

export class DimensionTool extends BaseTool {
  constructor(board) {
    super(board);
    this.cursorPencil = getCursor('pencil');
    this.toolMode = 'DIMENSION';
  }

  activate() {
    const canvas = this.canvas;

    // Check if scale has been set
    if (!canvas.scaleRatio) {
      alert('Please set a scale first using the Scale tool.');
      // Switch to SELECT mode
      if (this.board.setMode) {
        this.board.setMode('SELECT');
      }
      return;
    }

    // Explicitly set the tool to DIMENSION mode to prevent other tools
    // from taking over or showing prompts
    if (this.board._currentMode !== 'DIMENSION') {
      this.board._currentMode = 'DIMENSION';
    }

    canvas.on('mouse:down', this.startAddDimension.bind(this));
    canvas.on('mouse:move', this.startDrawingDimension.bind(this));
    canvas.on('mouse:up', this.stopDrawingDimension.bind(this));

    canvas.selection = false;
    canvas.defaultCursor = this.cursorPencil;
    canvas.hoverCursor = this.cursorPencil;
    canvas.isDrawingMode = false;
    canvas.getObjects().map((item) => item.set({ selectable: false }));
    canvas.discardActiveObject().requestRenderAll();
    
    // Make sure snapshots are enabled when the tool is activated
    if (this.board.history) {
      this.board.history._disableSnapshots = false;
    }
    
    // Log that dimension tool is activated - for debugging
    console.log('DimensionTool activated, mode set to:', this.board._currentMode);
  }
  
  deactivate() {
    this.canvas.off('mouse:down', this.startAddDimension.bind(this));
    this.canvas.off('mouse:move', this.startDrawingDimension.bind(this));
    this.canvas.off('mouse:up', this.stopDrawingDimension.bind(this));
    
    // Ensure snapshots are re-enabled when deactivating the tool
    if (this.board.history && this.board.history._disableSnapshots) {
      this.board.history._disableSnapshots = false;
      
      if (process.env.NODE_ENV !== 'production') {
        console.log('Re-enabling snapshots on dimension tool deactivation');
      }
    }
  }

  startAddDimension(e) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      console.warn('DimensionTool.startAddDimension called but tool is not active, current mode:', this.board._currentMode);
      return;
    }
    
    // Disable snapshots before any canvas modifications
    // This ensures the line creation is part of the same atomic undo operation
    if (this.board.history) {
      this.board.history._disableSnapshots = true;
      
      if (process.env.NODE_ENV !== 'production') {
        console.log('Disabling snapshots at the start of dimension drawing');
      }
    }
    
    this.mouseDown = true;

    let pointer = this.canvas.getPointer(e.e);
    this.drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: this.drawingSettings.brushWidth,
      stroke: this.drawingSettings.currentColor,
      selectable: false,
    });

    this.canvas.add(this.drawInstance);
    this.canvas.requestRenderAll();
  }

  startDrawingDimension(e) {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      return;
    }

    if (this.mouseDown) {
      const pointer = this.canvas.getPointer(e.e);
      this.drawInstance.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      this.drawInstance.setCoords();
      this.canvas.requestRenderAll();
    }
  }

  stopDrawingDimension() {
    // Check if this tool is active before proceeding
    if (!this.isActive()) {
      if (this.mouseDown && this.drawInstance) {
        this.canvas.remove(this.drawInstance);
        this.mouseDown = false;
        
        // Re-enable snapshots
        if (this.board.history) {
          this.board.history._disableSnapshots = false;
        }
      }
      return;
    }
    
    if (this.mouseDown) {
      this.mouseDown = false;
      this.drawInstance.setCoords();
      
      const x1 = this.drawInstance.x1;
      const y1 = this.drawInstance.y1;
      const x2 = this.drawInstance.x2;
      const y2 = this.drawInstance.y2;
      
      try {
        
        // Calculate the real distance using the scale ratio
        const realDistance = this.calculateRealDistance(x1, y1, x2, y2);
        
        // Create dimension lines (extension lines and dimension line)
        const objects = this.createDimensionObjects(x1, y1, x2, y2, realDistance);
        
        // Remove the original line
        this.canvas.remove(this.drawInstance);
        
        // Add the dimension objects
        objects.forEach(obj => this.canvas.add(obj));
        
        // Group all the dimension objects
        const group = new fabric.Group(objects, {
          selectable: true,
          lockScalingX: true,
          lockScalingY: true,
          // Add metadata to identify this as a dimension group
          data: {
            type: 'dimension',
            realDistance: realDistance
          }
        });
        
        // Remove individual objects and add the group
        objects.forEach(obj => this.canvas.remove(obj));
        this.canvas.add(group);
        this.canvas.requestRenderAll();
        
        // Re-enable snapshots before taking a snapshot
        if (this.board.history) {
          this.board.history._disableSnapshots = false;
          
          if (process.env.NODE_ENV !== 'production') {
            console.log('Re-enabling snapshots after dimension drawing');
          }
        }
        
        this.board.history.snapshot();
      } catch (error) {
        console.error('Error in stopDrawingDimension:', error);
        // Clean up on error
        if (this.drawInstance) {
          this.canvas.remove(this.drawInstance);
        }
        // Re-enable snapshots if there was an error
        if (this.board.history) {
          this.board.history._disableSnapshots = false;
        }
      }
    }
  }

  calculateRealDistance(x1, y1, x2, y2) {
    const pixelDistance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    // Scale is already in feet, so just convert pixels to feet using the scale ratio
    return pixelDistance / this.canvas.scaleRatio;
  }

    // Helper function to format distance in feet and inches
  formatFeetAndInches(distance) {
    // Convert to feet and inches
    const feet = Math.floor(distance);
    const inches = Math.round((distance - feet) * 12);
    
    // Handle the case where inches is 12 (should roll over to next foot)
    if (inches === 12) {
      return `${feet + 1}'0"`;
    }
    
    // Standard format: 5'11"
    return `${feet}'${inches}"`;
  }

  createDimensionObjects(x1, y1, x2, y2, realDistance) {
    const objects = [];
    
    // Main dimension line
    const mainLine = new fabric.Line([x1, y1, x2, y2], {
      strokeWidth: this.drawingSettings.brushWidth,
      stroke: this.drawingSettings.currentColor,
      selectable: false,
    });
    objects.push(mainLine);
    
    // Calculate angle of the line
    const angle = Math.atan2(y2 - y1, x2 - x1);
    
    // Length of extension lines (perpendicular to main line)
    const extensionLength = 20;
    
    // Calculate extension line coordinates (perpendicular to main line)
    const extensionAngle = angle + Math.PI / 2;
    const cosExt = Math.cos(extensionAngle);
    const sinExt = Math.sin(extensionAngle);
    
    // Create extension lines
    const extLine1 = new fabric.Line([
      x1, 
      y1, 
      x1 + cosExt * extensionLength, 
      y1 + sinExt * extensionLength
    ], {
      strokeWidth: this.drawingSettings.brushWidth,
      stroke: this.drawingSettings.currentColor,
      selectable: false,
    });
    
    const extLine2 = new fabric.Line([
      x2, 
      y2, 
      x2 + cosExt * extensionLength, 
      y2 + sinExt * extensionLength
    ], {
      strokeWidth: this.drawingSettings.brushWidth,
      stroke: this.drawingSettings.currentColor,
      selectable: false,
    });
    
    objects.push(extLine1, extLine2);
    
    // Create dimension text
    const midX = (x1 + x2) / 2;
    const midY = (y1 + y2) / 2;
    
    // Format the distance in feet and inches
    const formattedDistance = this.formatFeetAndInches(realDistance);
    
    const text = new fabric.Text(`${formattedDistance}`, {
      left: midX + cosExt * extensionLength / 2,
      top: midY + sinExt * extensionLength / 2,
      fontSize: 14,
      fill: this.drawingSettings.currentColor,
      originX: 'center',
      originY: 'center',
      angle: angle * 180 / Math.PI,
      selectable: false,
    });
    
    objects.push(text);
    
    return objects;
  }
}
