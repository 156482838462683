import { PencilTool } from './PencilTool';
import { LineTool } from './LineTool';
import { RectangleTool } from './RectangleTool';
import { EllipseTool } from './EllipseTool';
import { TriangleTool } from './TriangleTool';
import { TextTool } from './TextTool';
import { EraserTool } from './EraserTool';
import { SelectTool } from './SelectTool';
import { ScaleTool } from './ScaleTool';
import { DimensionTool } from './DimensionTool';
import { PolylineTool } from './PolylineTool';
import { PanTool } from './PanTool';
import { MaterialCalculatorTool } from './MaterialCalculatorTool';
import { StampTool } from './StampTool';

export const modes = {
  PENCIL: 'PENCIL',
  LINE: 'LINE',
  RECTANGLE: 'RECTANGLE',
  TRIANGLE: 'TRIANGLE',
  ELLIPSE: 'ELLIPSE',
  ERASER: 'ERASER',
  SELECT: 'SELECT',
  TEXT: 'TEXT',
  SCALE: 'SCALE',
  DIMENSION: 'DIMENSION',
  POLYLINE: 'POLYLINE',
  PAN: 'PAN',
  MATERIAL_CALCULATOR: 'MATERIAL_CALCULATOR',
  STAMP: 'STAMP',
  OBJECT_CALCULATOR: 'OBJECT_CALCULATOR',
};

export class ToolFactory {
  constructor(board) {
    this.board = board;
    this.tools = {
      [modes.PENCIL]: new PencilTool(board),
      [modes.LINE]: new LineTool(board),
      [modes.RECTANGLE]: new RectangleTool(board),
      [modes.ELLIPSE]: new EllipseTool(board),
      [modes.TRIANGLE]: new TriangleTool(board),
      [modes.TEXT]: new TextTool(board),
      [modes.ERASER]: new EraserTool(board),
      [modes.SELECT]: new SelectTool(board),
      [modes.SCALE]: new ScaleTool(board),
      [modes.DIMENSION]: new DimensionTool(board),
      [modes.POLYLINE]: new PolylineTool(board),
      [modes.PAN]: new PanTool(board),
      [modes.MATERIAL_CALCULATOR]: new MaterialCalculatorTool(board),
      [modes.STAMP]: new StampTool(board),
    };
    this.activeTool = null;
  }

  getTool(mode) {
    return this.tools[mode];
  }

  activateTool(mode) {
    // Deactivate the previous tool if any
    if (this.activeTool) {
      this.activeTool.deactivate();
    }
    
    // Make sure the board knows the current mode
    this.board._currentMode = mode;
    console.log(`ToolFactory activating tool: ${mode}`);

    // Activate the new tool
    this.activeTool = this.getTool(mode);
    if (this.activeTool) {
      this.activeTool.activate();
    }

    return this.activeTool;
  }
}
