import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MATERIAL_COLORS } from './tools/PolylineTool';
import styled from 'styled-components';
import {
  WrapperS,
  TabsS,
  TabS,
  WhiteBoardS,
  ButtonS,
  ToolbarS,
  ZoomBarS,
  ColorBarS,
  ToolbarItemS,
  RangeInputS,
  ColorButtonS,
  SeparatorS,
  ToolbarHolderS,
  PDFWrapperS,
  LogoWrapperS,
} from './Whiteboard.styled.js';
import { fabric } from 'fabric';
import { PdfReader } from '../PdfReader';
import { saveAs } from 'file-saver';
import { Board, modes } from './Board.Class.js';
import { ColorPicker } from '../ColorPicker';
import ContextPanel from './ContextPanel';
import SceneControls from './SceneControls'; // Updated import
import SaveLoadControls from './SaveLoadControls';
import { isAuthenticated } from '../../firebase/auth';

import SelectIcon from './../images/cross.svg';
import EraserIcon from './../images/eraser.svg';
import TextIcon from './../images/text.svg';
import RectangleIcon from './../images/rectangle.svg';
import LineIcon from './../images/line.svg';
import EllipseIcon from './../images/ellipse.svg';
import TriangleIcon from './../images/triangle.svg';
import PencilIcon from './../images/pencil.svg';
import DeleteIcon from './../images/delete.svg';
import ZoomInIcon from './../images/zoom-in.svg';
import ZoomOutIcon from './../images/zoom-out.svg';
import DownloadIcon from './../images/download.svg';
import UploadIcon from './../images/add-photo.svg';
import FillIcon from './../images/color-fill.svg';
import Recenter from './../images/focus.svg';
import ScaleIcon from './../images/scale.svg';
import PanIcon from './../images/pan.png';
import DimensionIcon from './../images/dimension.svg';
import PolylineIcon from './../images/polyline.svg';
import UndoIcon from './../images/undo.svg';
import RedoIcon from './../images/redo.svg';
import MaterialCalculatorIcon from './../images/material-calculator.svg';
import ObjectCalculatorIcon from './../images/object-calculator.svg';
import StampIcon from './../images/stamp.svg';
import SaveIcon from './../images/save.svg';
import LoadIcon from './../images/load.svg';
import { StampPalette } from './StampPalette';
import { ObjectCalculator } from './ObjectCalculator';

// Import Firebase Storage functions
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

// Import resources for collaboration
import { createWhiteboard, getWhiteboard } from '../../firebase/whiteboardManager';
import { getCurrentUser, signInWithGooglePopup } from '../../firebase/auth';
import ShareIcon from './../images/add-photo.svg'; // Temporarily reuse an existing icon
// import SceneIcon from './../images/3d-cube.svg'; // Placeholder for a 3D scene icon - Commented out for now

const initFileInfo = {
  file: { name: 'Teempla' },
  totalPages: 1,
  currentPageNumber: 1,
  currentPage: '',
};

const initDrawingSettings = {
  brushWidth: 2,
  currentMode: 'PENCIL',
  currentColor: '#FF0000FF',
  fill: false,
  // background: true,
};

const initSettings = {
  zoom: 1,
  contentJSON: null,
};
const defaultFunction = (data, event, canvas) => {};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above the canvas */
  color: white;
`;

const LoginButton = styled.button`
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #357abd;
  }
`;

const WhiteboardComponent = ({
  controls,
  settings,
  drawingSettings,
  fileInfo,
  imageSlot,
  onObjectAdded = defaultFunction,
  onObjectRemoved = defaultFunction,
  onObjectModified = defaultFunction,
  onCanvasRender = defaultFunction,
  onCanvasChange = defaultFunction,
  onZoom = defaultFunction,
  onImageUploaded = defaultFunction,
  onPDFUploaded = defaultFunction,
  onPDFUpdated = defaultFunction,
  onPageChange = defaultFunction,
  onOptionsChange = defaultFunction,
  onSaveCanvasAsImage = defaultFunction,
  onConfigChange = defaultFunction,
  onSaveCanvasState = defaultFunction,
  onDocumentChanged = defaultFunction,
  enableSceneIntegration = true,
  whiteboardId,
  enableCollaboration = false,
}) => {
  const [canvasSaveData, setCanvasSaveData] = useState([]);
  const [canvasObjectsPerPage, setCanvasObjectsPerPage] = useState({});
  const [canvasDrawingSettings, setCanvasDrawingSettings] = useState({
    ...initDrawingSettings,
    ...drawingSettings,
  });
  const canvasConfig = { ...initSettings, ...settings };
  const [documents, setDocuments] = useState(
    new Map().set(initFileInfo.file.name, initFileInfo.file),
  );
  const [zoom, setZoom] = useState(canvasConfig.zoom);
  const [fileReaderInfo, setFileReaderInfo] = useState({ ...initFileInfo, ...fileInfo });
  const [contextPanel, setContextPanel] = useState({ visible: false, x: 0, y: 0, selectedObject: null });
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [hasSelectedObjects, setHasSelectedObjects] = useState(false);
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [showSceneControls, setShowSceneControls] = useState(false); // State for SceneControls visibility
  const canvasRef = useRef(null);
  const whiteboardRef = useRef(null);
  const uploadPdfRef = useRef(null);
  const boardRef = useRef(null);
  const previousModeRef = useRef(null);
  const historyRef = useRef({ undoStack: [], redoStack: [] });
  const [historyState, setHistoryState] = useState({ canUndo: false, canRedo: false });
  const [isLoginRequired, setIsLoginRequired] = useState(false); // State for login overlay
  const [isUndoing, setIsUndoing] = useState(false);
  const [isRedoing, setIsRedoing] = useState(false);

  // --- Define Callbacks and Helper Functions FIRST ---

  // Function to update undo/redo availability states (ensure it's stable with useCallback)
  const updateUndoRedoState = useCallback(() => {
    // Check boardRef.current and history existence more robustly
    if (boardRef.current?.history) {
        const historyLength = boardRef.current.history.getHistoryLength ? boardRef.current.history.getHistoryLength() : 0;
        const redoHistoryLength = boardRef.current.history.getRedoHistoryLength ? boardRef.current.history.getRedoHistoryLength() : 0;
        setCanUndo(historyLength > 0);
        setCanRedo(redoHistoryLength > 0);
    } else {
        setCanUndo(false);
        setCanRedo(false);
    }
  }, []); // Stable: depends only on boardRef

  // addListeners function definition
  const addListeners = useCallback((canvas) => {
     if (!canvas) return;
     console.log("[Whiteboard] Adding listeners to canvas");

     canvas.on('after:render', (e) => {
       const data = getFullData(canvas); // Use getFullData defined later
       onCanvasRender(data, e, canvas);
     });
 
     canvas.on('zoom:change', function (data) {
       onZoom(data, null, canvas);
       setZoom(data.scale); // Use setZoom state setter
     });
 
     const handleObjectChange = (event) => {
        if (event.target) {
            onCanvasChange(event.target.toJSON(), event, canvas);
            // Update undo/redo buttons state after change
            setTimeout(updateUndoRedoState, 100); 
        }
     };

     canvas.on('object:added', (event) => {
       if (event.target) onObjectAdded(event.target.toJSON(), event, canvas);
       handleObjectChange(event);
     });
 
     canvas.on('object:removed', (event) => {
       if (event.target) onObjectRemoved(event.target.toJSON(), event, canvas);
       handleObjectChange(event);
     });
 
     canvas.on('object:modified', (event) => {
       if (event.target) onObjectModified(event.target.toJSON(), event, canvas);
       handleObjectChange(event);
     });
     
     // --- Selection listeners for ContextPanel ---
     const handleSelection = (event) => {
        console.log(`[Whiteboard] Selection event: ${event.type}`);
        const selected = canvas.getActiveObject(); // Use getActiveObject for reliable selection state
        setHasSelectedObjects(!!selected); // Update selection state

        if (boardRef.current && selected) {
            // Handle single object or active selection (group)
            const properties = boardRef.current.getSelectedObjectProperties(selected); 
            console.log('[Whiteboard] Got properties for selection:', properties);
            setContextPanel({ visible: true, selectedObject: properties, x: 0, y: 0 }); 
        } else {
            // No selection or board not ready
            setContextPanel({ visible: false, selectedObject: null, x: 0, y: 0 });
        }
     };

     canvas.on('selection:created', handleSelection);
     canvas.on('selection:updated', handleSelection);
     canvas.on('selection:cleared', handleSelection); // Also use handleSelection for cleared

     // --- RETURN the cleanup function --- 
     return () => {
        console.log('[Whiteboard] Removing canvas listeners...');
        canvas.off('after:render');
        canvas.off('zoom:change');
        canvas.off('object:added');
        canvas.off('object:removed');
        canvas.off('object:modified');
        canvas.off('selection:created', handleSelection);
        canvas.off('selection:updated', handleSelection);
        canvas.off('selection:cleared', handleSelection);
        // Remove any other listeners added above
     }; 
     // --- End of RETURNED cleanup function ---

  }, [onCanvasRender, onZoom, onObjectAdded, onObjectRemoved, onObjectModified, onCanvasChange, updateUndoRedoState]); // Add dependencies used inside

  // Memoized Controls
  const enabledControls = useMemo(
    function () {
      return {
        [modes.PENCIL]: true,
        [modes.LINE]: true,
        [modes.RECTANGLE]: true,
        [modes.ELLIPSE]: true,
        [modes.TRIANGLE]: true,
        [modes.TEXT]: true,
        [modes.SELECT]: true,
        [modes.ERASER]: true,
        [modes.SCALE]: true,
        [modes.DIMENSION]: true,
        [modes.POLYLINE]: true,
        [modes.PAN]: true,
        [modes.MATERIAL_CALCULATOR]: true,
        [modes.STAMP]: true,
        [modes.OBJECT_CALCULATOR]: true,
        CLEAR: true,
        FILL: true,
        BRUSH: true,
        COLOR_PICKER: true,
        DEFAULT_COLORS: true,
        FILES: true,
        SAVE_AS_IMAGE: true,
        GO_TO_START: true,
        SAVE_AND_LOAD: true,
        ZOOM: true,
        TABS: true,
        UNDO_REDO: true,

        ...controls,
      };
    },
    [controls],
  );

  // --- Effect 1: One-Time Board Initialization --- 
  useEffect(() => {
    console.log('[Whiteboard] Initializing Board instance effect running...');
    
    if (!boardRef.current && canvasRef.current) {
      console.log('[Whiteboard] Creating new Board instance...');
      boardRef.current = new Board({
        canvasRef, // Pass the ref itself
        drawingSettings: canvasDrawingSettings, // Initial settings
        canvasConfig: canvasConfig, // Initial config
        // Pass callbacks directly
        setContextPanelCallback: setContextPanel, 
        updateUndoRedoCallback: updateUndoRedoState,
        // Pass other necessary callbacks if Board needs them at init
      });

      // Set the event callback *immediately* after creating the instance
      boardRef.current.setEventCallback((eventType, payload) => {
        console.log(`[Whiteboard] Received event from Board: ${eventType}`, payload);
        if (eventType === 'crdt_init_failed') {
           // If init fails for a specific board ID, assume login is needed
           // The CRDTIntegration logs the specific reason (auth or other)
           console.log(`[Whiteboard] CRDT init failed for ID: ${payload?.whiteboardId}. Assuming login required.`);
           setIsLoginRequired(true); 
        } else if (eventType === 'crdt_init_success') {
           console.log('[Whiteboard] CRDT init success. Hiding login prompt if shown.');
           setIsLoginRequired(false); // Ensure overlay is hidden on success
        } else if (eventType === 'whiteboard_cleared') {
           console.log('[Whiteboard] Board was cleared.');
           setIsLoginRequired(false); // Ensure overlay is hidden when board is cleared
           updateUndoRedoState(); // Update history buttons
        }
        // Add other event handling if needed
      });

      // Add canvas listeners
      const removeListeners = addListeners(boardRef.current.canvas);
      
      // Set initial history state
      updateUndoRedoState();

      // Cleanup function for *this effect only* (true unmount)
      return () => {
        console.log('[Whiteboard] Component UNMOUNTING. Disposing Board instance.');
        removeListeners(); // Remove listeners added by this effect
        boardRef.current?.removeBoard(); // Full cleanup
        boardRef.current = null;
      };
    } else {
        console.log("[Whiteboard] Board instance already exists or canvasRef not ready.");
    }
  // Dependencies for initialization: only run once canvasRef is available.
  // Avoid adding things that change often like drawingSettings here.
  // Add functions from props if they are needed *only* at initialization and are stable.
  }, [addListeners, updateUndoRedoState]); // Keep stable callbacks as deps

  // --- Effect 2: Handle whiteboardId Changes --- 
  useEffect(() => {
    if (!boardRef.current) {
      console.log('[Whiteboard ID Effect] Board not ready, skipping ID processing.');
      return; // Wait for the board instance to be created
    }

    console.log(`[Whiteboard ID Effect] Processing whiteboardId: ${whiteboardId}`);
    
    // Compare with the ID stored inside the Board instance
    const currentBoardId = boardRef.current.whiteboardId;
    if (currentBoardId !== whiteboardId) {
        if (whiteboardId) {
          console.log(`[Whiteboard ID Effect] Calling setWhiteboardId with new ID: ${whiteboardId}`);
          // Reset login required flag optimistically
          setIsLoginRequired(false); 
          boardRef.current.setWhiteboardId(whiteboardId);
        } else {
          console.log('[Whiteboard ID Effect] whiteboardId is null. Cleaning up CRDT and clearing canvas.');
          boardRef.current.cleanupCRDT(); 
          boardRef.current.clearCanvas(); 
          // History clearing is now handled inside Board.Class clearCanvas or setWhiteboardId(null)
          // if (boardRef.current.history && typeof boardRef.current.history.clear === 'function') {
          //   boardRef.current.history.clear();
          // } else {
          //   console.warn('⚠️ History object does not have a clear method.');
          // }
          updateUndoRedoState(); 
          setContextPanel((prev) => ({ ...prev, visible: false, selectedObject: null }));
          // Maybe reset canvasObjectsPerPage if needed? Let Board.Class handle internal state.
          // setCanvasObjectsPerPage({});
          setIsLoginRequired(false); // Ensure login prompt is hidden
        }
    } else {
       console.log(`[Whiteboard ID Effect] whiteboardId (${whiteboardId}) hasn't changed. No action needed.`);
    }

    // No cleanup function here, as this effect only reacts to ID changes
    // The main cleanup is handled by the initialization effect.
    
  }, [whiteboardId, updateUndoRedoState]); // Dependency: only whiteboardId and stable callbacks

  // --- Effect 3: Update drawing settings when they change --- 
  useEffect(() => {
     if (boardRef.current) {
        console.log("[Whiteboard Settings Effect] Applying drawing settings:", canvasDrawingSettings);
        boardRef.current.setDrawingSettings(canvasDrawingSettings);
        if (canvasDrawingSettings.currentMode !== modes.STAMP) {
          setSelectedStamp(null);
        }
     }
  }, [canvasDrawingSettings]); // Runs only when drawingSettings state changes

  // --- Effect 4: Update canvas config when settings prop changes --- 
  useEffect(() => {
    const newCanvasConfig = { ...initSettings, ...settings };
    if (boardRef.current) {
      console.log("[Whiteboard Settings Effect] Applying canvas config:", newCanvasConfig);
      boardRef.current.setCanvasConfig(newCanvasConfig);
    }
  }, [settings]); // Runs only when settings prop changes

  // ... other useEffects for specific features (imageSlot, fileInfo, stamp handling) ...
  useEffect(() => {
    if (imageSlot) {
      fileChanger(imageSlot); // Ensure fileChanger is defined before this
    }
  }, [imageSlot]);

  useEffect(() => {
    setFileReaderInfo({ ...initFileInfo, ...fileInfo }); // Ensure fileInfo is stable or handled correctly
  }, [fileInfo]);

  useEffect(() => {
    if (!boardRef.current || !canvasDrawingSettings) return;
    boardRef.current.setDrawingSettings(canvasDrawingSettings);
    if (canvasDrawingSettings.currentMode !== modes.STAMP) {
      setSelectedStamp(null);
    }
  }, [canvasDrawingSettings]);
  
  useEffect(() => {
    if (!boardRef.current || canvasDrawingSettings.currentMode !== modes.STAMP) return;
    if (boardRef.current.toolFactory && selectedStamp) {
       const stampTool = boardRef.current.toolFactory.getTool(modes.STAMP);
       if (stampTool) {
           stampTool.setCurrentStamp(selectedStamp);
       }
    }
  }, [selectedStamp, canvasDrawingSettings.currentMode]);
  
  // ... Handlers (handleUndo, handleRedo, etc.) defined using useCallback ...
  const handleUndo = useCallback(() => {
    if (!boardRef.current || !canUndo || isUndoing) return;
    setIsUndoing(true);
    try {
      boardRef.current.undo();
      setTimeout(() => {
        updateUndoRedoState();
        setIsUndoing(false);
      }, 100);
    } catch (error) {
      console.error('Error during undo operation:', error);
      setIsUndoing(false);
      updateUndoRedoState();
    }
  }, [canUndo, isUndoing, updateUndoRedoState]);

  const handleRedo = useCallback(() => {
    if (!boardRef.current || !canRedo || isRedoing) return;
    setIsRedoing(true);
    try {
      boardRef.current.redo();
      setTimeout(() => {
        updateUndoRedoState();
        setIsRedoing(false);
      }, 100);
    } catch (error) {
      console.error('Error during redo operation:', error);
      setIsRedoing(false);
      updateUndoRedoState();
    }
  }, [canRedo, isRedoing, updateUndoRedoState]);

  // ... other handlers defined (changeBrushWidth, changeMode, etc.) ...
  // Ensure they use boardRef.current and needed dependencies are stable

  // Define getFullData (needs to be defined before used in addListeners)
  const getFullData = useCallback((canvas) => {
     if (!canvas) return null;
     return {
       settings: {
         contentJSON: canvas.toJSON(),
         viewportTransform: canvas.viewportTransform,
       },
       drawingSettings: canvasDrawingSettings, // Use state directly
       fileInfo: fileReaderInfo, // Use state directly
     };
  }, [canvasDrawingSettings, fileReaderInfo]); // Dependencies

  function saveCanvasState() {
    if (!boardRef.current?.canvas) return; // Use boardRef.current
    // Explicitly include custom properties in the JSON serialization
    const customProperties = ['material', 'polyline', 'pageNumber', 'documentName', 'area', 'perimeter', 'scaleRatio'];
    
    // Use toJSON with custom property array to ensure all important properties are saved
    const newValue = {
      ...canvasObjectsPerPage,
      [fileReaderInfo.file.name]: {
        ...canvasObjectsPerPage[fileReaderInfo.file.name],
        [fileReaderInfo.currentPageNumber]: boardRef.current.canvas.toJSON(customProperties), // Use boardRef.current
      },
    };
    
    // Debug info to verify materials are being saved properly
    const polygons = boardRef.current.canvas.getObjects().filter(obj => // Use boardRef.current
      obj.polyline === true || obj.type === 'polygon'
    );
    
    console.log(`Saving ${polygons.length} polygons with the following materials:`);
    polygons.forEach(p => console.log(`  - ${p.id || 'unknown'}: ${p.material || 'NO MATERIAL'}`));
    
    setCanvasObjectsPerPage(newValue);
    onSaveCanvasState(newValue);
  }

  function changeBrushWidth(e) {
    if (!boardRef.current?.canvas?.freeDrawingBrush) return; // Use boardRef.current and check existence
    const intValue = parseInt(e.target.value);
    boardRef.current.canvas.freeDrawingBrush.width = intValue;
    const newOptions = { ...canvasDrawingSettings, brushWidth: intValue };
    setCanvasDrawingSettings(newOptions);
    onOptionsChange(newOptions, e, boardRef.current.canvas);
  }

  function changeMode(mode, e) {
    if (canvasDrawingSettings.currentMode === mode) return; 
    if (!boardRef.current) return; // Check boardRef
    const newOptions = { ...canvasDrawingSettings, currentMode: mode };
    setCanvasDrawingSettings(newOptions);
    // Board.Class now handles mode changes internally via setDrawingSettings effect
    // boardRef.current.setMode(mode); // Remove if Board.Class handles this internally
    onOptionsChange(newOptions, e, boardRef.current.canvas);
  }

  function changeCurrentColor(color, e) {
    if (!boardRef.current?.canvas?.freeDrawingBrush) return; // Use boardRef.current and check existence
    boardRef.current.canvas.freeDrawingBrush.color = color;
    const newOptions = { ...canvasDrawingSettings, currentColor: color };
    setCanvasDrawingSettings(newOptions);
    onOptionsChange(newOptions, e, boardRef.current.canvas);
  }

  function changeFill(e) {
    if (!boardRef.current) return; // Use boardRef.current
    const newOptions = { ...canvasDrawingSettings, fill: !canvasDrawingSettings.fill };
    setCanvasDrawingSettings(newOptions);
    // Board.Class might handle fill changes internally via setDrawingSettings effect
    onOptionsChange(newOptions, e, boardRef.current.canvas);
  }

  function handleSaveCanvasAsImage() {
    if (!canvasRef.current) return; // Use canvasRef directly for this
    canvasRef.current.toBlob(function (blob) {
      saveAs(
        blob,
        `${fileReaderInfo.file.name}-${fileReaderInfo.currentPageNumber ? '_page-' : ''}.png`,
      );
      onSaveCanvasAsImage(blob, null, boardRef.current?.canvas); // Pass canvas if board exists
    });
  }

  function bringControlTOStartPosition() {
    if (!boardRef.current?.canvas) return; // Use boardRef.current
    boardRef.current.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
    boardRef.current.resetZoom(1); // Assuming resetZoom is a method on Board instance
    // These might be internal to Board.Class, check if needed
    // boardRef.current.nowX = 0; 
    // boardRef.current.nowY = 0;
  }

  // Handles file input changes (both image and PDF)
  async function onFileChange(event) {
    const file = event.target?.files?.[0];
    if (!file || !boardRef.current) return; // Use boardRef.current

    console.log(`File selected: ${file.name}, type: ${file.type}`);

    if (file.type.includes('image/')) {
      console.log('Image file detected. Uploading to Firebase Storage...');
      try {
        const storage = getStorage();
        const filePath = `whiteboardImages/${boardRef.current.whiteboardId || 'general'}/${Date.now()}_${file.name}`; // Use boardRef.current
        const imageRef = storageRef(storage, filePath);

        console.log(`Uploading to: ${filePath}`);
        const snapshot = await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('Upload successful. Image URL:', downloadURL);

        if (boardRef.current.addImageFromUrl) { // Use boardRef.current
          boardRef.current.addImageFromUrl(downloadURL);
          console.log('board.addImageFromUrl called');
        } else {
          console.error('board.addImageFromUrl method not found! Cannot add image to canvas via CRDT.');
          alert('Image uploaded, but cannot add it to the board currently.');
        }

        onImageUploaded(file, event, boardRef.current.canvas); // Use boardRef.current
      } catch (error) {
        console.error("Error uploading image to Firebase Storage:", error);
        alert(`Failed to upload image: ${error.message}`);
      }
    } else if (file.type.includes('pdf')) {
      console.log('PDF file detected. Processing locally...');
      saveCanvasState(); 
      boardRef.current.clearCanvas(); // Use boardRef.current
      updateFileReaderInfo({ file: file, currentPageNumber: 1 });
      setDocuments((prev) => new Map(prev.set(file.name, file)));
      onPDFUploaded(file, event, boardRef.current.canvas); // Use boardRef.current
    } else {
      console.warn(`Unsupported file type: ${file.type}`);
      alert('Unsupported file type. Please upload an image or PDF.');
    }
    event.target.value = null;
  }

  // Handles files dropped or provided via props (e.g., imageSlot)
  async function fileChanger(file) {
     if (!file || !boardRef.current) return; // Use boardRef.current

     console.log(`File provided: ${file.name}, type: ${file.type}`);

    if (file.type.includes('image/')) {
       console.log('Image file detected via prop/drop. Uploading to Firebase Storage...');
      try {
        const storage = getStorage();
        const filePath = `whiteboardImages/${boardRef.current.whiteboardId || 'general'}/${Date.now()}_${file.name}`; // Use boardRef.current
        const imageRef = storageRef(storage, filePath);

        console.log(`Uploading to: ${filePath}`);
        const snapshot = await uploadBytes(imageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log('Upload successful. Image URL:', downloadURL);

        if (boardRef.current.addImageFromUrl) { // Use boardRef.current
          boardRef.current.addImageFromUrl(downloadURL);
          console.log('board.addImageFromUrl called');
        } else {
           console.error('board.addImageFromUrl method not found! Cannot add image to canvas via CRDT.');
           alert('Image uploaded, but cannot add it to the board currently.');
        }
         onImageUploaded(file, null, boardRef.current.canvas); // Use boardRef.current
      } catch (error) {
        console.error("Error uploading image to Firebase Storage:", error);
        alert(`Failed to upload image: ${error.message}`);
      }
    } else if (file.type.includes('pdf')) {
      console.log('PDF file detected via prop/drop. Processing locally...');
      saveCanvasState();
      boardRef.current.clearCanvas(); // Use boardRef.current
      updateFileReaderInfo({ file: file, currentPageNumber: 1 });
      setDocuments((prev) => new Map(prev.set(file.name, file)));
       onPDFUploaded(file, null, boardRef.current.canvas); // Use boardRef.current
    } else {
       console.warn(`Unsupported file type via prop/drop: ${file.type}`);
    }
  }

  function getPageJSON({ fileName, pageNumber }) {
    if (canvasObjectsPerPage[fileName] && canvasObjectsPerPage[fileName][pageNumber]) {
      return canvasObjectsPerPage[fileName][pageNumber];
    } else {
      return null;
    }
  }

  function updateFileReaderInfo(data) {
    const newFileData = { ...fileReaderInfo, ...data };
    setFileReaderInfo(newFileData);
    onPDFUpdated(newFileData, null, boardRef.current?.canvas);
  }

  const handlePageChange = (page) => {
    if (!boardRef.current) return; // Use boardRef.current
    // Save the current page state first
    saveCanvasState();
    
    // Update the board's fileReaderInfo using the setter method
    const newFileInfo = { ...fileReaderInfo, currentPageNumber: page };
    
    console.log(`Page change: from page ${fileReaderInfo.currentPageNumber} to page ${page}`);
    
    // IMPORTANT: We need to completely clear the canvas when switching pages
    // This ensures proper page separation (each page is treated separately)
    boardRef.current.clearCanvas(); // Use boardRef.current
    
    // Set new file info after clearing
    boardRef.current.setFileReaderInfo(newFileInfo); // Use boardRef.current
    setFileReaderInfo(newFileInfo);
    
    // Check if we have saved objects for the new page
    const hasPageData = canvasObjectsPerPage[fileReaderInfo.file.name]?.[page];
    
    if (hasPageData) {
      console.log(`Found saved data for page ${page}, loading ${hasPageData.objects?.length || 0} objects`);
      // Load the saved state for this page
      const savedPage = canvasObjectsPerPage[fileReaderInfo.file.name][page];
      
      // Load saved JSON state, then ensure properties are correctly set
      boardRef.current.canvas.loadFromJSON(savedPage, () => { // Use boardRef.current
        // After loading, validate every object to ensure proper properties
        const objects = boardRef.current.canvas.getObjects(); // Use boardRef.current
        objects.forEach(obj => {
          // For polygons, ensure material and page info is correct
          if (obj.polyline === true || obj.type === 'polygon') {
            // Ensure polygon has correct page info
            if (obj.pageNumber !== page || obj.documentName !== fileReaderInfo.file.name) {
              obj.set({
                pageNumber: page,
                documentName: fileReaderInfo.file.name
              });
            }
            
            // CRITICAL FIX: Reconstruct material property if missing but fill color exists
            // This ensures material name is preserved even if JSON serialization loses it
            if (!obj.material && obj.fill) {
              // Look up the material by its color
              for (const [material, materialColor] of Object.entries(MATERIAL_COLORS)) {
                if (materialColor === obj.fill) {
                  console.log(`Restoring material '${material}' from fill color ${obj.fill}`);
                  obj.set('material', material);
                  break;
                }
              }
            }
            // Make fill color match material (if material exists)
            else if (obj.material) {
              const materialColor = MATERIAL_COLORS[obj.material];
              if (materialColor && obj.fill !== materialColor) {
                obj.set('fill', materialColor);
              }
            }
            
            // Debug log to confirm material property
            console.log(`Object after restoration: id=${obj.id || 'unknown'}, ` +
                       `material=${obj.material || 'NONE'}, fill=${obj.fill}`);
          }
        });
        
        boardRef.current.canvas.renderAll(); // Use boardRef.current
        console.log(`Loaded and validated ${objects.length} objects for page ${page}`);
      });
    }
    
    // Trigger page change callback last
    onPageChange(newFileInfo, null, boardRef.current.canvas); // Use boardRef.current
  };

  const changeDocument = (name) => {
    if (!boardRef.current) return; // Use boardRef.current
    bringControlTOStartPosition();
    saveCanvasState();
    
    // Update the board's fileReaderInfo using the setter method
    const newFileInfo = { file: documents.get(name), currentPageNumber: 1 };
    
    console.log(`Document change: from ${fileReaderInfo.file?.name} to ${name}`);
    
    // IMPORTANT: Always clear the canvas when switching documents
    // This ensures proper page/document separation
    boardRef.current.clearCanvas(); // Use boardRef.current
    
    // Set new file info after clearing
    boardRef.current.setFileReaderInfo(newFileInfo); // Use boardRef.current
    setFileReaderInfo(newFileInfo);
    
    // Check if we have saved objects for the first page of the new document
    const hasDocumentData = canvasObjectsPerPage[name]?.[1];
    
    if (hasDocumentData) {
      console.log(`Found saved data for document ${name}, page 1`);
      // Load the saved state for the first page
      const savedPage = canvasObjectsPerPage[name][1];
      
      // Load saved JSON state, then validate all objects
      boardRef.current.canvas.loadFromJSON(savedPage, () => { // Use boardRef.current
        // Validate every object to ensure proper properties
        const objects = boardRef.current.canvas.getObjects(); // Use boardRef.current
        objects.forEach(obj => {
          // For polygons, ensure material and page info is correct
          if (obj.polyline === true || obj.type === 'polygon') {
            // Ensure polygon has correct page and document info
            if (obj.pageNumber !== 1 || obj.documentName !== name) {
              obj.set({
                pageNumber: 1,
                documentName: name
              });
            }
            
            // CRITICAL FIX: Reconstruct material property if missing but fill color exists
            // This ensures material name is preserved even if JSON serialization loses it
            if (!obj.material && obj.fill) {
              // Look up the material by its color
              for (const [material, materialColor] of Object.entries(MATERIAL_COLORS)) {
                if (materialColor === obj.fill) {
                  console.log(`Restoring material '${material}' from fill color ${obj.fill}`);
                  obj.set('material', material);
                  break;
                }
              }
            }
            // Make fill color match material (if material exists)
            else if (obj.material) {
              const materialColor = MATERIAL_COLORS[obj.material];
              if (materialColor && obj.fill !== materialColor) {
                obj.set('fill', materialColor);
              }
            }
            
            // Debug log to confirm material property
            console.log(`Object after restoration: id=${obj.id || 'unknown'}, ` +
                       `material=${obj.material || 'NONE'}, fill=${obj.fill}`);
          }
        });
        
        boardRef.current.canvas.renderAll(); // Use boardRef.current
        console.log(`Loaded and validated ${objects.length} objects for document ${name}, page 1`);
      });
    }
    
    // Trigger document change callback
    onDocumentChanged(newFileInfo, null, boardRef.current.canvas); // Use boardRef.current
  };

  const handleZoomIn = () => {
    if (!boardRef.current) return; // Use boardRef.current
    boardRef.current.changeZoom({ scale: 1.1 }); // Assuming changeZoom is method
  };

  const handleZoomOut = () => {
    if (!boardRef.current) return; // Use boardRef.current
    boardRef.current.changeZoom({ scale: 0.9 }); // Assuming changeZoom is method
  };

  const handleResetZoom = () => {
    if (!boardRef.current) return; // Use boardRef.current
    boardRef.current.resetZoom(1); // Assuming resetZoom is method
  };
  
  const handlePropertyChange = (property, value, additionalData) => {
    if (!boardRef.current?.canvas) return; // Use boardRef.current and check canvas
    
    try {
      console.log(`Handling property change: ${property} with value:`, value);
      
      // Get the active object from the canvas
      const activeObject = boardRef.current.canvas.getActiveObject();
      if (!activeObject) {
        console.warn('No active object to apply property changes to');
        return;
      }
      
      switch (property) {
        case 'stroke': // Color from color picker
          if (boardRef.current.changeSelectedObjectColor) {
            boardRef.current.changeSelectedObjectColor(value);
          } else {
            // Direct application if method not available
            if (activeObject.type === 'group') {
              activeObject._objects.forEach(obj => {
                if (obj.stroke) obj.set('stroke', value);
                // Update fill too if it's not transparent
                if (obj.fill && obj.fill !== 'transparent') obj.set('fill', value);
              });
            } else {
              if (activeObject.stroke) activeObject.set('stroke', value);
              // Update fill too if it's not transparent
              if (activeObject.fill && activeObject.fill !== 'transparent') activeObject.set('fill', value);
            }
            boardRef.current.canvas.requestRenderAll();
            boardRef.current.history?.snapshot();
          }
          break;
        case 'strokeWidth': // Width from slider
          if (boardRef.current.changeSelectedObjectWidth) {
            boardRef.current.changeSelectedObjectWidth(value);
          } else {
            // Direct application if method not available
            if (activeObject.type === 'group') {
              activeObject._objects.forEach(obj => {
                if (obj.strokeWidth !== undefined) obj.set('strokeWidth', value);
              });
            } else {
              if (activeObject.strokeWidth !== undefined) activeObject.set('strokeWidth', value);
            }
            boardRef.current.canvas.requestRenderAll();
            boardRef.current.history?.snapshot();
          }
          break;
        case 'opacity': // Opacity from slider
          // The opacity comes as a decimal value (0-1) from ContextPanel
          console.log('Setting opacity to:', value, 'on object type:', activeObject.type);
          
          const safeOpacity = Math.max(0.05, Math.min(1, value));
          
          if (boardRef.current.changeSelectedObjectOpacity) {
            boardRef.current.changeSelectedObjectOpacity(safeOpacity);
          } else {
            // Direct application if method not available
            if (activeObject.type === 'group') {
              activeObject._objects.forEach(obj => {
                obj.set('opacity', safeOpacity);
              });
              activeObject.set('opacity', safeOpacity); // Set group opacity too
            } else {
              activeObject.set('opacity', safeOpacity);
            }
            boardRef.current.canvas.requestRenderAll();
            boardRef.current.history?.snapshot();
          }
          break;
        case 'fill': // Fill toggle
          if (boardRef.current.toggleSelectedObjectFill) {
            boardRef.current.toggleSelectedObjectFill();
          } else {
            // Direct application if method not available
            if (activeObject.type === 'group') {
              activeObject._objects.forEach(obj => {
                if (value === null) {
                  obj.set('fill', 'transparent');
                } else if (obj.stroke) {
                  obj.set('fill', obj.stroke);
                }
              });
            } else {
              if (value === null) {
                activeObject.set('fill', 'transparent');
              } else if (activeObject.stroke) {
                activeObject.set('fill', activeObject.stroke);
              }
            }
            boardRef.current.canvas.requestRenderAll();
            boardRef.current.history?.snapshot();
          }
          break;
        case 'material': // Material selection for polylines
          if (boardRef.current.setSelectedObjectMaterial) {
            boardRef.current.setSelectedObjectMaterial(value, additionalData);
          } else {
            // Direct application if method not available
            if (activeObject.type === 'group') {
              activeObject._objects.forEach(obj => {
                if (obj.polyline) {
                  obj.set({
                    'material': value,
                    'fill': additionalData // Set the fill color based on material
                  });
                }
              });
            } else if (activeObject.polyline) {
              activeObject.set({
                'material': value,
                'fill': additionalData // Set the fill color based on material
              });
            }
            boardRef.current.canvas.requestRenderAll();
            boardRef.current.history?.snapshot();
          }
          break;
        default:
          console.warn('Unknown property change:', property);
      }
      
      // Update the context panel to reflect the changes
      if (boardRef.current.contextMenuCallback) { // check method exists before calling
        const selectedObject = boardRef.current.getSelectedObjectProperties();
        boardRef.current.contextMenuCallback({ // Assuming setContextPanelCallback assigned this
          visible: true,
          x: 0,
          y: 0,
          selectedObject
        });
      }
    } catch (error) {
      console.error('Error in handlePropertyChange:', error);
    }
  };
  
  const handleLayerAction = (action) => {
    if (!boardRef.current) return; // Use boardRef.current
    
    switch (action) {
      case 'bringForward':
        boardRef.current.bringSelectedForward();
        break;
      case 'sendBackward':
        boardRef.current.sendSelectedBackward();
        break;
      case 'bringToFront':
        boardRef.current.bringSelectedToFront();
        break;
      case 'sendToBack':
        boardRef.current.sendSelectedToBack();
        break;
      case 'delete':
        if (boardRef.current.deleteSelectedObjects()) {
          setContextPanel((prev) => ({ ...prev, visible: false }));
          updateUndoRedoState();
        }
        break;
      default:
        console.warn('Unknown layer action:', action);
    }
  };

  const getColorButtons = (colors) => {
    return colors.map((color) => (
      <ToolbarItemS key={color}>
        <ColorButtonS color={color} onClick={(e) => changeCurrentColor(color, e)} />
      </ToolbarItemS>
    ));
  };

  const getControls = () => {
    const modeButtons = {
      [modes.PENCIL]: { icon: PencilIcon, name: 'Pencil' },
      [modes.LINE]: { icon: LineIcon, name: 'Line' },
      [modes.RECTANGLE]: { icon: RectangleIcon, name: 'Rectangle' },
      [modes.ELLIPSE]: { icon: EllipseIcon, name: 'Ellipse' },
      [modes.TRIANGLE]: { icon: TriangleIcon, name: 'Triangle' },
      [modes.TEXT]: { icon: TextIcon, name: 'Text' },
      [modes.SELECT]: { icon: SelectIcon, name: 'Select' },
      [modes.ERASER]: { icon: EraserIcon, name: 'Eraser' },
      [modes.SCALE]: { icon: ScaleIcon, name: 'Scale' },
      [modes.DIMENSION]: { icon: DimensionIcon, name: 'Dimension' },
      [modes.POLYLINE]: { icon: PolylineIcon, name: 'Polyline' },
      [modes.PAN]: { icon: PanIcon, name: 'Pan Tool' },
      [modes.MATERIAL_CALCULATOR]: { icon: MaterialCalculatorIcon, name: 'Material Calculator' },
      [modes.STAMP]: { icon: StampIcon, name: 'Stamp Objects' },
      [modes.OBJECT_CALCULATOR]: { icon: ObjectCalculatorIcon, name: 'Object Calculator' },
    };

    return Object.keys(modeButtons).map((buttonKey) => {
      if (!enabledControls[buttonKey]) return;
      const btn = modeButtons[buttonKey];
      // Special handling for select mode to include deletion info in tooltip
      const buttonTitle = buttonKey === modes.SELECT
        ? 'Select/Move Objects (Delete or Backspace key to remove selected objects)'
        : btn.name;
      
      return (
        <ButtonS
          key={buttonKey}
          type="button"
          className={`${canvasDrawingSettings.currentMode === buttonKey ? 'selected' : ''}`}
          onClick={(e) => {
            if (buttonKey === modes.OBJECT_CALCULATOR) {
              previousModeRef.current = canvasDrawingSettings.currentMode;
            }
            changeMode(buttonKey, e);
          }}
          title={buttonTitle}
        >
          <img src={btn.icon} alt={btn.name} />
        </ButtonS>
      );
    });
  };

  // Save the entire scene (including plants and boxes) to a JSON file
  const handleSaveScene = () => {
    if (!boardRef.current) return; // Use boardRef.current
    
    try {
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      boardRef.current.saveSceneAsJson(`teempla-scene-${timestamp}`);
    } catch (error) {
      console.error('Error saving scene:', error);
    }
  };
  
  // Load a scene from a JSON file
  const handleLoadScene = () => {
    if (!boardRef.current) return; // Use boardRef.current
    
    try {
      // Create a file input element and trigger click
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.json';
      
      fileInput.addEventListener('change', async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        // Read the file content
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            const sceneData = JSON.parse(e.target.result);
            
            // Update scene from loaded data
            boardRef.current.updateSceneFromData(sceneData);
            
            console.log('Scene loaded successfully');
          } catch (error) {
            console.error('Error parsing scene data:', error);
          }
        };
        
        reader.readAsText(file);
      });
      
      // Trigger file selection dialog
      fileInput.click();
    } catch (error) {
      console.error('Error loading scene:', error);
    }
  };

  const handleLogin = async () => {
    console.log('Attempting Google Sign-In...');
    try {
      await signInWithGooglePopup();
      // No need to manually set isLoginRequired to false here.
      // The onAuthStateChanged listener in CRDTIntegration should trigger 
      // re-initialization, which will emit 'crdt_init_success' 
      // if successful, and that event callback will set isLoginRequired to false.
      console.log('Google Sign-In Popup initiated.');
    } catch (error) {
      console.error('Google Sign-In failed:', error);
      alert('Login failed. Please try again.'); 
      // Keep the overlay visible if login fails
    }
  };

  const handleContextAction = (action, value) => {
    // ... existing context action logic ...
  };

  const updateHistoryState = () => {
      // ... existing history update logic ...
  };
  
  // ... (other handlers like handleClear, handleSaveAsImage, handleZoomChange, etc.) ...

  return (
    <WrapperS ref={whiteboardRef}>
      {!!enabledControls.TABS && (
        <TabsS>
          <LogoWrapperS>
            <img src="/teempla/build/img/intro-logo.png" alt="Teempla Logo" />
          </LogoWrapperS>
          {/* Add Button to toggle SceneControls */}  
          {enableSceneIntegration && (
            <ButtonS 
              onClick={() => setShowSceneControls(prev => !prev)} 
              title={showSceneControls ? "Hide Scene Controls" : "Show Scene Controls"}
              style={{ 
                  marginLeft: '10px', 
                  backgroundColor: showSceneControls ? 'rgba(0,0,0,0.1)' : 'transparent' 
              }}
            >
              {/* Use a proper icon if available, otherwise text */}  
              {/* <img src={SceneIcon} alt="Scene" /> */}
              Scene
            </ButtonS>
          )}
          {Array.from(documents.keys()).map((document, index) => (
            <TabS
              key={index} // Using index as a key if document is not unique
              onClick={() => changeDocument(document)}
              style={
                document === fileReaderInfo.file.name
                  ? {
                      backgroundColor: 'rgba(0,0,0,0.1)',
                    }
                  : {}
              }
            >
              {document}
            </TabS>
          ))}
        </TabsS>
      )}
      {/* Conditionally render SceneControls based on state */}  
      {showSceneControls && enableSceneIntegration && boardRef.current && <SceneControls board={boardRef.current} />}
      <WhiteBoardS>
        {/* Context Panel - positioned at a stable location */}
        {contextPanel.visible && (
          <ContextPanel
            visible={true}
            selectedObject={contextPanel.selectedObject}
            onLayerAction={handleLayerAction}
            onPropertyChange={handlePropertyChange}
            onClose={() => setContextPanel(prev => ({ ...prev, visible: false }))}
            board={boardRef.current} // Pass the board instance
          />
        )}
        {/* Stamp Palette */}
        {canvasDrawingSettings.currentMode === modes.STAMP && (
          <StampPalette
            onSelectStamp={(stampName, customStamp) => {
              setSelectedStamp(stampName);
              if (boardRef.current?.currentTool) {
                boardRef.current.currentTool.setCurrentStamp(stampName, customStamp);
              }
            }}
            selectedStamp={selectedStamp}
            board={boardRef.current}
          />
        )}
        {/* Object Calculator */}
        {canvasDrawingSettings.currentMode === modes.OBJECT_CALCULATOR && (
          <ObjectCalculator
            board={boardRef.current}
            onClose={() => {
              // Instead of changing mode to SELECT, just toggle off calculator mode
              const newSettings = { ...canvasDrawingSettings };
              newSettings.currentMode = previousModeRef.current || modes.SELECT;
              setCanvasDrawingSettings(newSettings);
              if (onOptionsChange) {
                onOptionsChange(newSettings, 'currentMode');
              }
            }}
          />
        )}
        <ToolbarHolderS>
          <ColorBarS>
            {!!enabledControls.COLOR_PICKER && (
              <ToolbarItemS>
                <ColorPicker
                  size={28}
                  color={canvasDrawingSettings.currentColor}
                  onChange={changeCurrentColor}
                ></ColorPicker>
              </ToolbarItemS>
            )}
            {!!enabledControls.BRUSH && (
              <ToolbarItemS>
                <RangeInputS
                  type="range"
                  min={1}
                  max={30}
                  step={1}
                  thumbcolor={canvasDrawingSettings.currentColor}
                  value={canvasDrawingSettings.brushWidth}
                  onChange={changeBrushWidth}
                />
              </ToolbarItemS>
            )}
            {!!enabledControls.DEFAULT_COLORS && (
              <>{getColorButtons(['#6161ff', '#ff4f4f', '#3fd18d', '#ec70ff', '#000000'])}</>
            )}
            {!!enabledControls.FILL && (
              <ButtonS
                type="button"
                className={canvasDrawingSettings.fill ? 'selected' : ''}
                onClick={changeFill}
              >
                <img src={FillIcon} alt="Delete" />
              </ButtonS>
            )}
          </ColorBarS>
          <ToolbarS>
            {getControls()}
            
            {!!enabledControls.UNDO_REDO && (
              <>
                <SeparatorS />
                
                <ToolbarItemS>
                  <ButtonS 
                    type="button" 
                    onClick={handleUndo} 
                    title="Undo"
                    style={{ 
                      opacity: canUndo && !isUndoing ? 1 : 0.5, 
                      cursor: canUndo && !isUndoing ? 'pointer' : 'not-allowed',
                      animation: isUndoing ? 'pulse 1s infinite' : 'none'
                    }}
                    disabled={!canUndo || isUndoing}
                  >
                    <img src={UndoIcon} alt="Undo" />
                  </ButtonS>
                </ToolbarItemS>
                
                <ToolbarItemS>
                  <ButtonS 
                    type="button" 
                    onClick={handleRedo} 
                    title="Redo"
                    style={{ 
                      opacity: canRedo && !isRedoing ? 1 : 0.5, 
                      cursor: canRedo && !isRedoing ? 'pointer' : 'not-allowed',
                      animation: isRedoing ? 'pulse 1s infinite' : 'none'
                    }}
                    disabled={!canRedo || isRedoing}
                  >
                    <img src={RedoIcon} alt="Redo" />
                  </ButtonS>
                </ToolbarItemS>
                

              </>
            )}

            {!!enabledControls.CLEAR && (
              <ButtonS type="button" onClick={() => boardRef.current?.clearCanvas()}>
                <img src={DeleteIcon} alt="Delete" />
              </ButtonS>
            )}

            <SeparatorS />

            {!!enabledControls.FILES && (
              <ToolbarItemS>
                <input
                  ref={uploadPdfRef}
                  hidden
                  accept="image/*,.pdf"
                  type="file"
                  onChange={onFileChange}
                />
                <ButtonS onClick={() => uploadPdfRef.current.click()}>
                  <img src={UploadIcon} alt="Delete" />
                </ButtonS>
              </ToolbarItemS>
            )}

            {!!enabledControls.SAVE_AS_IMAGE && (
              <ToolbarItemS>
                <ButtonS onClick={handleSaveCanvasAsImage}>
                  <img src={DownloadIcon} alt="Download" />
                </ButtonS>
              </ToolbarItemS>
            )}

            {!!enabledControls.GO_TO_START && (
              <ToolbarItemS>
                <ButtonS onClick={bringControlTOStartPosition}>
                  <img src={Recenter} alt="Recenter" />
                </ButtonS>
              </ToolbarItemS>
            )}
            
            {/* Save Scene Button */}
            <ToolbarItemS>
              <ButtonS 
                onClick={handleSaveScene} 
                title="Save Scene"
              >
                <img src={SaveIcon} alt="Save Scene" />
              </ButtonS>
            </ToolbarItemS>
            
            {/* Load Scene Button */}
            <ToolbarItemS>
              <ButtonS 
                onClick={handleLoadScene}
                title="Load Scene"
              >
                <img src={LoadIcon} alt="Load Scene" />
              </ButtonS>
            </ToolbarItemS>

            {!!enabledControls.SAVE_AND_LOAD && (
              <ToolbarItemS>
                <ButtonS type="button" onClick={saveCanvasState}>
                  <img src={SaveIcon} alt="Save" />
                </ButtonS>
              </ToolbarItemS>
            )}

            {!!enabledControls.SAVE_AND_LOAD && canvasSaveData && canvasSaveData.length > 0 && (
              <ToolbarItemS>
                <ButtonS onClick={() => updateFileReaderInfo(canvasSaveData[0])}>
                  <img src={LoadIcon} alt="Load" />
                </ButtonS>
              </ToolbarItemS>
            )}

            {/* CRDT Collaboration Button */}
            <ToolbarItemS>
              <ButtonS
                onClick={async () => {
                  if (!boardRef.current) return;
                  
                  // Check if user is authenticated
                  if (!isAuthenticated()) {
                    alert('Please sign in to enable collaboration');
                    return;
                  }
                  
                  try {
                    // Get whiteboard ID from URL or create new one
                    const urlParams = new URLSearchParams(window.location.search);
                    let whiteboardId = urlParams.get('board');
                    
                    if (!whiteboardId) {
                      // Create a new whiteboard
                      const newWhiteboard = await createWhiteboard({
                        name: fileReaderInfo.file.name,
                        settings: {}
                      });
                      
                      whiteboardId = newWhiteboard.id;
                      
                      // Update URL with whiteboard ID
                      const url = new URL(window.location);
                      url.searchParams.set('board', whiteboardId);
                      window.history.pushState({}, '', url);
                    } else {
                      // Get existing whiteboard data
                      try {
                        await getWhiteboard(whiteboardId);
                      } catch (error) {
                        console.error('Error fetching whiteboard:', error);
                        alert('Could not find whiteboard. Creating a new one.');
                        
                        // Create a new whiteboard
                        const newWhiteboard = await createWhiteboard({
                          name: fileReaderInfo.file.name,
                          settings: {}
                        });
                        
                        whiteboardId = newWhiteboard.id;
                        
                        // Update URL with new whiteboard ID
                        const url = new URL(window.location);
                        url.searchParams.set('board', whiteboardId);
                        window.history.pushState({}, '', url);
                      }
                    }
                    
                    // Enable CRDT synchronization
                    boardRef.current.setWhiteboardId(whiteboardId);
                    
                    // Save snapshot of current state
                    if (boardRef.current.crdt) { // Check crdt exists
                       boardRef.current.crdt.saveSnapshot();
                    } else {
                       console.warn("CRDT Integration not ready for snapshot.");
                    }
                    
                    // Show confirmation
                    alert('Collaboration enabled! Share this URL to collaborate in real-time.');
                  } catch (error) {
                    console.error('Error enabling collaboration:', error);
                    alert('Error enabling collaboration: ' + error.message);
                  }
                }}
                title="Enable Real-time Collaboration"
              >
                <img src={ShareIcon} alt="Share" />
              </ButtonS>
            </ToolbarItemS>
          </ToolbarS>
          <ZoomBarS>
            {!!enabledControls.ZOOM && (
              <ToolbarItemS>
                <ButtonS onClick={handleZoomIn} title="Zoom In">
                  <img src={ZoomInIcon} alt="Zoom In" />
                </ButtonS>
              </ToolbarItemS>
            )}

            {!!enabledControls.ZOOM && (
              <ToolbarItemS>
                <ButtonS onClick={handleResetZoom} title="Reset Zoom">
                  <span style={{ fontSize: '11px' }}>{Math.floor(zoom * 100)}%</span>
                </ButtonS>
              </ToolbarItemS>
            )}

            {!!enabledControls.ZOOM && (
              <ToolbarItemS>
                <ButtonS onClick={handleZoomOut} title="Zoom Out">
                  <img src={ZoomOutIcon} alt="Zoom Out" />
                </ButtonS>
              </ToolbarItemS>
            )}
          </ZoomBarS>
        </ToolbarHolderS>

        <canvas style={{ zIndex: 1 }} ref={canvasRef} id="canvas" />

        {!!fileReaderInfo?.file?.size && (
          <PDFWrapperS>
            <PdfReader
              fileReaderInfo={fileReaderInfo}
              onPageChange={handlePageChange}
              updateFileReaderInfo={updateFileReaderInfo}
            />
          </PDFWrapperS>
        )}
      </WhiteBoardS>
      {/* Login Overlay - Conditionally Rendered */}
      {isLoginRequired && (
        <Overlay>
          <h2>Login Required</h2>
          <p>Please log in to view and edit this whiteboard.</p>
          <LoginButton onClick={handleLogin}>Login with Google</LoginButton>
        </Overlay>
      )}
      {/* Add Save/Load Controls when a whiteboardId is set */}
      {whiteboardId && (
        <SaveLoadControls 
          boardRef={boardRef} 
          isAuthenticated={isAuthenticated()}
        />
      )}
    </WrapperS>
  );
};

WhiteboardComponent.propTypes = {
  aspectRatio: PropTypes.number,
  enableSceneIntegration: PropTypes.bool,
  whiteboardId: PropTypes.string, 
  controls: PropTypes.object, 
  onCanvasRender: PropTypes.func, 
  settings: PropTypes.object,
  drawingSettings: PropTypes.object,
  fileInfo: PropTypes.object,
  imageSlot: PropTypes.object,
  onObjectAdded: PropTypes.func,
  onObjectRemoved: PropTypes.func,
  onObjectModified: PropTypes.func,
  onCanvasChange: PropTypes.func,
  onZoom: PropTypes.func,
  onImageUploaded: PropTypes.func,
  onPDFUploaded: PropTypes.func,
  onPDFUpdated: PropTypes.func,
  onPageChange: PropTypes.func,
  onOptionsChange: PropTypes.func,
  onSaveCanvasAsImage: PropTypes.func,
  onConfigChange: PropTypes.func,
  onSaveCanvasState: PropTypes.func,
  onDocumentChanged: PropTypes.func,
  enableCollaboration: PropTypes.bool,
};

// Wrap the component with React.memo for performance optimization
const Whiteboard = React.memo(WhiteboardComponent); 

export default Whiteboard; // Export the memoized component
