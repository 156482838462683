import React, { useState } from 'react'
import styled from 'styled-components'

const CreatorContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 380px;
  max-height: 90vh;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000;
`

const CreatorHeader = styled.div`
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  
  &:hover {
    color: #333;
  }
`

const FormContainer = styled.div`
  padding: 16px;
  max-height: calc(90vh - 45px); /* Header is ~45px */
  overflow-y: auto; /* Make scrollable if needed */
`

const FormGroup = styled.div`
  margin-bottom: 16px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: 500;
  color: #555;
`

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.3);
  }
`

const SymbolPreview = styled.div`
  margin-top: 16px;
  padding: 16px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PreviewTitle = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
`

const getSizeMultiplier = (size) => {
  switch (size) {
    case 'tiny': return 0.7;
    case 'small': return 0.85;
    case 'large': return 1.2;
    default: return 1; // medium is default
  }
}

const ShapePreview = styled.div`
  width: ${props => 80 * getSizeMultiplier(props.size)}px;
  height: ${props => 80 * getSizeMultiplier(props.size)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: ${props => {
    const baseSize = props.text.length > 3 ? 14 : 16;
    return `${baseSize * getSizeMultiplier(props.size)}px`;
  }};
  color: #fff;
  user-select: none;
  
  ${props => props.shape === 'circle' && `
    border-radius: 50%;
    background-color: ${props.color};
  `}
  
  ${props => props.shape === 'rectangle' && `
    border-radius: 4px;
    background-color: ${props.color};
  `}
`

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 8px;
`

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`

const ColorPicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`

const ColorOption = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${props => props.color};
  cursor: pointer;
  border: 2px solid ${props => props.selected ? '#000' : 'transparent'};
  
  &:hover {
    transform: scale(1.1);
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666;
  
  &:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const CreateButton = styled(Button)`
  background-color: #3b82f6;
  border: none;
  color: white;
  
  &:hover:not(:disabled) {
    background-color: #2563eb;
  }
`

// Predefined colors for stamps
const STAMP_COLORS = [
  '#2E7D32', // Green
  '#1B5E20', // Dark Green
  '#33691E', // Olive Green
  '#558B2F', // Light Green
  '#795548', // Brown
  '#8D6E63', // Light Brown
  '#F57C00', // Orange
  '#EF6C00', // Dark Orange
  '#E64A19', // Red Orange
  '#5D4037', // Dark Brown
  '#1565C0', // Blue
  '#7E57C2', // Purple
]

function CustomStampCreator({ onClose, onCreateStamp }) {
  const [name, setName] = useState('')
  const [symbol, setSymbol] = useState('')
  const [shape, setShape] = useState('circle')
  const [color, setColor] = useState('#2E7D32')
  const [size, setSize] = useState('medium')
  
  const handleNameChange = (e) => {
    setName(e.target.value)
  }
  
  const handleSymbolChange = (e) => {
    // Limit to 4 characters max
    setSymbol(e.target.value.toUpperCase().slice(0, 4))
  }
  
  const generateStampPath = () => {
    if (shape === 'circle') {
      return 'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z'
    } else {
      return 'M4,4H20V20H4V4Z' // Simple rectangle path
    }
  }
  
  // Size map to translate sizes to scale values
  const sizeScaleMap = {
    'tiny': { scale: 0.8, radius: 20 },
    'small': { scale: 1.0, radius: 25 },
    'medium': { scale: 1.5, radius: 30 },
    'large': { scale: 2.0, radius: 40 },
  }

  const handleCreate = () => {
    // Get size values from the map
    const sizeValues = sizeScaleMap[size] || sizeScaleMap.medium
    
    // CRITICAL FIX: Create custom stamp with PATH just like regular stamps
    const newStamp = {
      name,
      text: symbol,        // Text to display in the stamp
      symbol,             // Keep symbol separately
      fill: color,        // Background color
      color: color,       // Add color property for consistency with StampTool
      shape,              // 'circle' or 'rectangle'
      size,               // Size category (tiny, small, medium, large)
      isCustom: true,     // Flag to identify as custom stamp
      customStamp: true,  // Additional flag for compatibility
      objectType: 'stamp',
      stampName: name,    // Ensure stampName is set for compatibility
      path: generateStampPath(), // CRITICAL - add path property similar to regular stamps
      scale: sizeValues.scale, // Scale based on size
      radius: sizeValues.radius // Store radius for reference
    }
    
    // Log the complete stamp for debugging
    console.log('Creating enhanced custom stamp:', newStamp)
    
    // Store in localStorage directly for backup
    try {
      const savedStamps = JSON.parse(localStorage.getItem('customStamps') || '{}')
      savedStamps[name] = newStamp
      localStorage.setItem('customStamps', JSON.stringify(savedStamps))
      console.log('Saved custom stamp to localStorage')
    } catch (error) {
      console.error('Failed to save to localStorage:', error)
    }
    
    onCreateStamp(newStamp)
    onClose()
  }
  
  const isValid = name.trim().length > 0 && symbol.trim().length > 0
  
  return (
    <CreatorContainer>
      <CreatorHeader>
        <span>Create Custom Stamp</span>
        <CloseButton onClick={onClose}>×</CloseButton>
      </CreatorHeader>
      
      <FormContainer>
        <FormGroup>
          <Label htmlFor="stamp-name">Name</Label>
          <Input 
            id="stamp-name" 
            type="text" 
            value={name} 
            onChange={handleNameChange} 
            placeholder="e.g., Magnolia Grandiflora"
          />
        </FormGroup>
        
        <FormGroup>
          <Label htmlFor="stamp-symbol">Symbol Text (2-4 letters)</Label>
          <Input 
            id="stamp-symbol" 
            type="text" 
            value={symbol} 
            onChange={handleSymbolChange} 
            placeholder="e.g., MGF"
            maxLength={4}
          />
        </FormGroup>
        
        <FormGroup>
          <Label>Shape</Label>
          <RadioGroup>
            <RadioOption onClick={() => setShape('circle')}>
              <input 
                type="radio" 
                id="shape-circle" 
                name="shape" 
                checked={shape === 'circle'} 
                onChange={() => {}} 
              />
              <label htmlFor="shape-circle">Circle</label>
            </RadioOption>
            
            <RadioOption onClick={() => setShape('rectangle')}>
              <input 
                type="radio" 
                id="shape-rectangle" 
                name="shape" 
                checked={shape === 'rectangle'} 
                onChange={() => {}} 
              />
              <label htmlFor="shape-rectangle">Rectangle</label>
            </RadioOption>
          </RadioGroup>
        </FormGroup>

        <FormGroup>
          <Label>Object Size</Label>
          <RadioGroup>
            <RadioOption onClick={() => setSize('tiny')} style={{ width: '48%' }}>
              <input 
                type="radio" 
                id="size-tiny" 
                name="size" 
                checked={size === 'tiny'} 
                onChange={() => {}} 
              />
              <label htmlFor="size-tiny">Tiny</label>
            </RadioOption>
            
            <RadioOption onClick={() => setSize('small')} style={{ width: '48%' }}>
              <input 
                type="radio" 
                id="size-small" 
                name="size" 
                checked={size === 'small'} 
                onChange={() => {}} 
              />
              <label htmlFor="size-small">Small</label>
            </RadioOption>

            <RadioOption onClick={() => setSize('medium')} style={{ width: '48%' }}>
              <input 
                type="radio" 
                id="size-medium" 
                name="size" 
                checked={size === 'medium'} 
                onChange={() => {}} 
              />
              <label htmlFor="size-medium">Medium</label>
            </RadioOption>
            
            <RadioOption onClick={() => setSize('large')} style={{ width: '48%' }}>
              <input 
                type="radio" 
                id="size-large" 
                name="size" 
                checked={size === 'large'} 
                onChange={() => {}} 
              />
              <label htmlFor="size-large">Large</label>
            </RadioOption>
          </RadioGroup>
        </FormGroup>
        
        <FormGroup>
          <Label>Color</Label>
          <ColorPicker>
            {STAMP_COLORS.map((stampColor) => (
              <ColorOption 
                key={stampColor} 
                color={stampColor} 
                selected={color === stampColor}
                onClick={() => setColor(stampColor)}
              />
            ))}
          </ColorPicker>
        </FormGroup>
        
        <SymbolPreview>
          <PreviewTitle>Preview</PreviewTitle>
          <ShapePreview 
            shape={shape} 
            text={symbol || 'ABC'}
            color={color}
            size={size}
          >
            {symbol || 'ABC'}
          </ShapePreview>
          <div style={{ marginTop: '8px', fontSize: '12px', color: '#666' }}>
            {size.charAt(0).toUpperCase() + size.slice(1)} Size ({{
              'tiny': 'Succulents',
              'small': 'Perennials',
              'medium': 'Shrubs',
              'large': 'Trees'
            }[size]})
          </div>
        </SymbolPreview>
        
        <ButtonGroup>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <CreateButton 
            onClick={handleCreate} 
            disabled={!isValid}
          >
            Create Stamp
          </CreateButton>
        </ButtonGroup>
      </FormContainer>
    </CreatorContainer>
  )
}

export { CustomStampCreator }
